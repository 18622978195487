import { get, has, keys, merge, set } from 'lodash';
import { nextTick } from 'vue';
import type { Composer, I18n, I18nOptions, Locale } from 'vue-i18n';
import { createI18n } from 'vue-i18n';

let vueI18n: I18n;

export const DEFAULT_LOCALE = import.meta.env.VITE_APP_DEFAULT_LOCALE;
export const SUPPORT_LOCALES = import.meta.env.VITE_APP_SUPPORT_LOCALES.split(
  '|'
);

export function useI18n(): Composer {
  if (vueI18n === undefined) {
    initI18n();
  }
  return vueI18n.global as unknown as Composer;
}

export function _setLocale(i18n: I18n, locale: Locale): void {
  (i18n.global as unknown as Composer).locale.value = locale;
}

export function _setI18nLanguage(i18n: I18n, locale: Locale): void {
  _setLocale(i18n, locale);
  document.querySelector('html')?.setAttribute('lang', locale);
}

export function _setupI18n(options: I18nOptions): I18n {
  const i18n = createI18n(options);
  _setI18nLanguage(i18n, DEFAULT_LOCALE);
  return i18n;
}

export function initI18n(): I18n {
  if (vueI18n === undefined) {
    vueI18n = _setupI18n({
      legacy: false,
      locale: DEFAULT_LOCALE,
      fallbackLocale: DEFAULT_LOCALE,
    });
  }
  return vueI18n;
}

//?: even in use?
export function getLocale(): string {
  return (vueI18n.global as unknown as Composer).locale.value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _getResourceMessages = (r: any) => r.default || r;

export async function loadLocaleMessages(
  i18n: Composer,
  locale: Locale,
  resources: any,
  useBKGLocales = false
): Promise<any> {
  // If unsupported locale, was passed, fallback to default locale
  if (!SUPPORT_LOCALES.includes(locale)) {
    locale = DEFAULT_LOCALE;
  }

  // Get locale messages
  const locales = await import(`./locales/${locale}.json`).then(
    _getResourceMessages
  );

  if (useBKGLocales) {
    // Get BKG Resources
    const BKGLocales = await import(`./locales/BKG/${locale}.json`).then(
      _getResourceMessages
    );
    resources = BKGLocales;
  }

  // Merge locale messages with resources if provided
  let result = locales;
  if (resources) {
    const changedLocales = {};
    const remoteKeys = keys(resources);

    remoteKeys.forEach((key) => {
      if (has(locales, key)) {
        set(changedLocales, key, get(resources, key));
      }
    });

    result = merge({}, locales, changedLocales);
  }

  // Set locale messages
  i18n.setLocaleMessage(locale, result);
  _setI18nLanguage(vueI18n, locale);

  await nextTick();
}
