import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import type {
  ActionPerformed,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { isPlatform, alertController } from '@ionic/vue';
import * as Sentry from '@sentry/capacitor';
import { useEventBus } from '@vueuse/core';
import type { ComponentPublicInstance, ComputedRef } from 'vue';
import { computed } from 'vue';

import {
  PagesBackgroundEnum,
  PostsModeEnum,
  PushType,
  RequirementsEnum,
} from '@/@enums';
import type {
  AuthUser,
  NetworkModel,
  UserCurrentModel,
  PushModel,
} from '@/@types';
import {
  isNativeMobile,
  useWebSockets,
  appThemeHelper,
  HomePageHelper,
  componentNetworkChangeModal,
  updateApp,
  changeFavicon,
  ColorGenerator,
  useRequirementsHelper,
  useAiAssistant,
  useImages,
  useMenu,
} from '@/helpers';
import { busKey, listener, unsubscribe } from '@/helpers/eventBus';
import { useI18n, loadLocaleMessages } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { $api } from '@/services';
import type { AppState } from '@/store';
import {
  useAppStore,
  useChatStore,
  useMessengerStore,
  useNetworkStore,
  useGroupsStore,
  useUserStore,
  useNotificationsStore,
  usePostStore,
  useMenuStore,
  resetAllStores,
  useAiAssistantStore,
} from '@/store';

interface IUserFlow {
  _pageRef: ComponentPublicInstance | null;
  initRef: (pageRef: ComponentPublicInstance | null) => void;
  initPush: () => Promise<void>;
  initLocalNotifications: () => Promise<void>;
  setupApp: (noWS?: boolean) => Promise<void>;
  setNetwork: (network: NetworkModel, data: AuthUser | null) => Promise<void>;
  changeNetwork: (
    userNetworks: NetworkModel[] | NetworkModel | undefined,
    data: AuthUser | null
  ) => Promise<void>;
  logOut: () => Promise<void>;
}

export function useUserFlow(): IUserFlow {
  const isAndroid = isPlatform('android');
  let localUser: UserCurrentModel | null = null;
  let isInitPush = false;
  let _pageRef: ComponentPublicInstance | null = null;

  const initRef = (pageRef: ComponentPublicInstance | null): void => {
    _pageRef = pageRef;
  };

  const _pushNotification = async (notification: PushNotificationSchema) => {
    const data = notification.data as PushModel;
    switch (data.t) {
      case PushType.Note:
        await SplashScreen.show();
        await router?.push({
          name: ROUTES_NAME.POST_BY_ID,
          params: { id: data.id },
        });
        await SplashScreen.hide();
        break;

      case PushType.Message:
        await SplashScreen.show();
        if (await useChatStore().byChainId(+data.id)) {
          await SplashScreen.hide();
          await router?.push({
            name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
            params: { id: data.id },
          });
        } else {
          await SplashScreen.hide();
        }
        break;

      case PushType.AddedToGroup:
        await SplashScreen.show();
        await router?.push({
          name: ROUTES_NAME.GROUP_BY_ID,
          params: { id: data.id },
        });
        await SplashScreen.hide();
        break;

      case PushType.InvitedToGroup:
        await SplashScreen.show();
        await router?.push({
          name: ROUTES_NAME.GROUP_BY_ID,
          params: { id: data.id },
        });
        await SplashScreen.hide();
        break;

      case PushType.NewFollower:
        await SplashScreen.show();
        await router?.push({
          name: ROUTES_NAME.USER_BY_ID,
          params: { id: data.id },
        });
        await SplashScreen.hide();
        break;

      case PushType.ExternalUrl:
        break;

      case PushType.Wiki:
        break;

      case PushType.File:
        break;

      default:
        break;
    }
  };

  const _localNotification = async (chainId: number) => {
    const messengerStore = useMessengerStore();
    if (chainId > 0) {
      await router?.push({
        name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
        params: { id: chainId },
      });
      messengerStore.resetUnreadMessagesCount(chainId);
    }
  };

  const initPush = async (): Promise<void> => {
    isInitPush = true;
    if (isNativeMobile) {
      await PushNotifications.addListener('registration', async (token) => {
        console.info('Registration token: ', token.value);
        const deviceId = await Device.getId();
        const appInfo = await App.getInfo();

        await $api.notification.register({
          pushToken: token.value,
          deviceId: deviceId.identifier,
          platform: isAndroid ? 'android' : 'ios',
          packageId: appInfo.name,
          allNetworks: false,
        });
      });

      await PushNotifications.addListener('registrationError', (err) => {
        console.error('Registration error: ', err.error);
      });

      await PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push notification received: ', notification);
        }
      );

      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (action: ActionPerformed) => {
          _pushNotification(action.notification);
        }
      );

      let permStatus = await PushNotifications.checkPermissions();

      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }

      const { t } = useI18n();
      if (permStatus.receive !== 'granted') {
        alert(t('deniedPermissions'));
      }

      await PushNotifications.register();
    }
  };

  const initLocalNotifications = async (): Promise<void> => {
    if (isNativeMobile) {
      let permStatus = await LocalNotifications.checkPermissions();
      if (permStatus.display === 'prompt') {
        permStatus = await LocalNotifications.requestPermissions();
      }

      const { t } = useI18n();

      if (permStatus.display !== 'granted') {
        alert(t('deniedPermissions'));
      }

      await LocalNotifications.registerActionTypes({
        types: [
          {
            id: 'NEW_CHAT_MESSAGE',
            actions: [
              {
                id: 'view',
                title: t('open'),
              },
            ],
          },
        ],
      });

      await LocalNotifications.addListener(
        'localNotificationActionPerformed',
        (notification: any) => {
          _localNotification(notification.notification.id);
        }
      );
    }
  };

  const _setupNetwork = async (
    networkStore: any,
    appStore: any
  ): Promise<boolean> => {
    if (networkStore.network.id.length === 0) {
      await networkStore.networkForUser(appStore.clientSecret);
    }

    if (networkStore.data.length === 0) {
      await networkStore.networksByUser();
    }

    const settings = await networkStore.getSettings(appStore.clientSecret);

    if (!settings) {
      return false;
    }

    await appThemeHelper.setTheme(
      appThemeHelper.getAppColor(settings?.headBackgroundColor)
    );
    changeFavicon();
    return true;
  };

  const _setupUser = async (
    userStore: any,
    appStore: any
  ): Promise<UserCurrentModel | null> => {
    localUser = await userStore.currentUser();

    if (!localUser) {
      return null;
    }

    appStore.$patch({
      userId: localUser.id,
    });

    return localUser;
  };

  const _showErrorAlert = async (errors: string[]): Promise<void> => {
    const alert = await alertController.create({
      message: errors.join(' '),
    });
    await alert.present();
  };

  const _handleSetupAppError = async (
    error: any,
    networkStore: any,
    userStore: any,
    appStore: any
  ): Promise<void> => {
    console.error(error);
    if (isNativeMobile) {
      await SplashScreen.hide();
    }

    appStore.$patch({
      loading: false,
    });

    await router?.replace(ROUTES_NAME.LOGIN);
    const networkErrors = networkStore.getErrors('default');
    const userErrors = userStore.getErrors('default');
    const errors =
      userErrors.length && !networkErrors.length ? userErrors : networkErrors;
    Sentry ? Sentry.setUser(null) : null;
    _showErrorAlert(errors);
    return;
  };

  const _setupProfileSettings = (
    localUser: UserCurrentModel,
    appStore: any
  ): void => {
    appStore.$patch((state: AppState) => {
      state.loading = true;

      state.theme = Object.hasOwn(localUser, 'themeStyle')
        ? localUser.themeStyle
        : state.theme;

      state.pagesBackground = Object.hasOwn(localUser, 'pagesBackground')
        ? localUser.pagesBackground
          ? PagesBackgroundEnum.CustomImage
          : PagesBackgroundEnum.Default
        : state.pagesBackground;

      state.locale = Object.hasOwn(localUser, 'language')
        ? localUser.language
        : state.locale;

      state.sendKey = Object.hasOwn(localUser, 'sendMode')
        ? localUser.sendMode
        : state.sendKey;

      state.localNotifications = Object.hasOwn(localUser, 'sound')
        ? localUser.sound
        : state.localNotifications;

      state.canPostOnBehalfMe = Object.hasOwn(localUser, 'canPostOnBehalfMe')
        ? localUser.canPostOnBehalfMe
        : state.canPostOnBehalfMe;
    });

    appStore.toggleTheme(appStore.theme);
  };

  const _setupLocales = async (
    localLanguage: string,
    appStore: any
  ): Promise<void> => {
    const networkStore = useNetworkStore();
    const currentNetworkId: ComputedRef<string> = computed(
      () => networkStore.getNetwork.id
    );
    const isBKGNetwork: ComputedRef<boolean> = computed(
      () => useMenu().companiesList.value['BKG'] === currentNetworkId.value
    );
    const companyResources = await appStore.getCompanyResources();
    await loadLocaleMessages(
      useI18n(),
      localLanguage,
      companyResources,
      isBKGNetwork.value
    );
  };

  const _checkRequiredConditions = async (noWS?: boolean): Promise<void> => {
    if (isNativeMobile) {
      await SplashScreen.hide();
    }

    const result = await useRequirementsHelper().check(RequirementsEnum.All);
    if (result) {
      const appStore = useAppStore();
      appStore.$patch({
        loading: false,
      });
      await _toApp(noWS);
    }
  };

  const _toApp = async (noWS?: boolean): Promise<void> => {
    const route = router.currentRoute.value;

    const appStore = useAppStore();
    const userStore = useUserStore();
    const postStore = usePostStore();
    const messengerStore = useMessengerStore();
    const menuStore = useMenuStore();
    const eventBus = useEventBus(busKey);

    const loadCustomMenu = async () => {
      await menuStore.getMenu();
      setInterval(
        async () => {
          await menuStore.getMenu();
        },
        15 * 60 * 1000
      );
    };

    listener(eventBus, loadCustomMenu);

    if (!localUser) {
      if (isNativeMobile) {
        await SplashScreen.hide();
      }

      await router?.push(ROUTES_NAME.LOGIN);
      return;
    }

    if (!isInitPush) {
      await initPush();
    }

    if (!noWS) {
      await useWebSockets().initWebSockets(appStore.getWebSocketModel);
    }

    userStore.$reset();
    userStore.$patch({
      current: localUser,
    });

    appStore.$patch({
      feedType: localUser.defaultFeedType,
    });

    await HomePageHelper.setHomePage();

    if (route?.name === ROUTES_NAME.LOGIN || route?.name === ROUTES_NAME.HOME) {
      await router?.replace(appStore.homePage);
    } else {
      if (HomePageHelper.getHomePage()?.name === ROUTES_NAME.FEED) {
        await router?.replace({ name: ROUTES_NAME.FEED });
        postStore.$patch((state) => {
          state.postsMode = PostsModeEnum.Feed;
          state.postsGroupId = null;
          state.postsUserId = null;
        });
      } else {
        await router?.replace(appStore.homePage);
      }
    }

    if (isNativeMobile) {
      await SplashScreen.hide();
      await updateApp();
    }

    await messengerStore.getChains();
    for (let i = 0; i < 3 && messengerStore.loadMoreUrl; i++) {
      await messengerStore.loadMore(messengerStore.loadMoreUrl);
    }

    //?: even in use
    eventBus.emit('event-bus-event');
    unsubscribe(eventBus, loadCustomMenu);
  };

  const setupApp = async (noWS?: boolean): Promise<void> => {
    const appStore = useAppStore();
    const networkStore = useNetworkStore();
    const userStore = useUserStore();
    const notificationStore = useNotificationsStore();
    const groupsStore = useGroupsStore();

    resetAllStores(false);

    ColorGenerator.generateRandomShades();

    try {
      const _result = await _setupNetwork(networkStore, appStore);
      if (!_result) {
        throw new Error('Network error');
      }

      localUser = await _setupUser(userStore, appStore);
      if (!localUser) {
        throw new Error('Current user error');
      }
    } catch (error) {
      await _handleSetupAppError(error, networkStore, userStore, appStore);
      return;
    }

    //NOTE: Sentry
    if (Sentry) {
      Sentry.setUser({
        email: localUser?.email,
        id: localUser?.id.toString(),
        username: localUser?.fullName,
      });
    }

    //NOTE: Profile settings
    _setupProfileSettings(localUser, appStore);

    //NOTE: Locales
    await _setupLocales(appStore.locale, appStore);

    //NOTE: Check Policy + Password + ForcePosts
    await _checkRequiredConditions(noWS);

    //NOTE: Notifications
    await notificationStore.notifications();

    //NOTE: CanPostGroups
    await groupsStore.canPostGroups();
  };

  const setNetwork = async (
    network: NetworkModel,
    userData: AuthUser | null
  ): Promise<void> => {
    const appStore = useAppStore();
    const networkStore = useNetworkStore();

    networkStore.$patch({
      settingNetwork: true,
    });

    const appCurrent = {
      url: appStore.url,
      companyRowId: appStore.companyRowId,
      loading: false,
    };

    if (network.id !== '') {
      appStore.$patch((state) => {
        state.url = `https://${network.hostWithSubHost}`;
        state.companyRowId = network.id;
        state.loading = true;
        state.webSocket = network.webSocket;
        state.isWaitingForCompleteLogin = true;
      });

      let isAuth = false;
      if (userData && !appStore.isAuth) {
        isAuth = await appStore.homeCode(userData);
      }

      if (!userData && appStore.isAuth) {
        isAuth = true;
      }

      if (isAuth && (await appStore.token(true))) {
        networkStore.$patch((state) => {
          state.network = network;
          state.settingNetwork = false;
        });

        await setupApp();
      } else {
        const errors = appStore.getErrors('default');
        appStore.$patch(appCurrent);
        _showErrorAlert(errors);
      }
    }
    networkStore.$patch({
      settingNetwork: false,
    });
  };

  const changeNetwork = async (
    userNetworks: NetworkModel[] | NetworkModel | undefined,
    data: AuthUser | null
  ): Promise<void> => {
    if (userNetworks !== undefined) {
      if (!Array.isArray(userNetworks)) {
        await setNetwork(userNetworks, data);
      } else {
        const result = await componentNetworkChangeModal(false);
        if (result.data !== undefined) {
          await setNetwork(result.data, data);
        }
      }
    }
  };

  const logOut = async (): Promise<void> => {
    await useWebSockets().stopWebSockets();
    const currentAssistant = useAiAssistantStore().assistant;
    if (currentAssistant) {
      await useAiAssistant().deleteAssistant(currentAssistant);
    }
    if (isNativeMobile) {
      const deviceId = await Device.getId();
      const appInfo = await App.getInfo();
      await $api.notification.unRegister({
        deviceId: deviceId.identifier,
        platform: isAndroid ? 'android' : 'ios',
        packageId: appInfo.name,
        allNetworks: false,
      });
    }

    resetAllStores(true);

    changeFavicon();
    await appThemeHelper.clearTheme();
    useImages().clearImages();
  };

  return {
    _pageRef,
    initRef,
    initPush,
    initLocalNotifications,
    setupApp,
    setNetwork,
    changeNetwork,
    logOut,
  };
}
