import type {
  ResponseErrorModel,
  ResponseNetworkModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkResourcesModel,
  NetworkLocateModel,
  ResponseNetworkLocateModel,
  NetworkUploadLogoResponseModel,
  NetworkSettingsModel,
  NetworkMobileAppsModel,
  NetworkBrandingModel,
  ResponseNetworkMobileAppsModel,
  ResponseNetworkBrandingModel,
  ResponseNetworkDesignModel,
  NetworkDesignModel,
} from '@/@types';
import axios from '@/services/axios';

export class NetworksApiService {
  async getLocate(
    uri: string,
    clientSecret: string
  ): Promise<NetworkLocateModel | undefined> {
    let settings: NetworkLocateModel | undefined = undefined;

    const api = import.meta.env.VITE_APP_URL_API;
    const env = import.meta.env.VITE_APP_ENV; // qa / beta / prod
    const url = `${uri}${api}/networks/locate/?clientSecret=${clientSecret}&env=${env}`;

    await fetch(url, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response
            .json()
            .then((responseToken: ResponseNetworkLocateModel) => {
              settings = responseToken.data;
            });
        } else {
          console.log(response.status);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });

    return settings;
  }

  async settings(
    clientSecret: string
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/?clientSecret=${clientSecret}`);
  }

  async current(
    clientSecret: string
  ): Promise<ResponseNetworkModel | ResponseErrorModel> {
    return axios.get(`/networks/current/?clientSecret=${clientSecret}`);
  }

  async getResources(
    clientSecret: string
  ): Promise<ResponseNetworkResourcesModel | ResponseErrorModel> {
    return axios.get(
      `/networks/resources?clientSecret=${clientSecret}&mobile=true`
    );
  }

  async uploadLogo(
    name: string
  ): Promise<NetworkUploadLogoResponseModel | ResponseErrorModel> {
    return axios.post('/networks/uploadlogo', { name });
  }

  async saveNetworkSettings(
    data: NetworkSettingsModel
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.post('/networks/settings', data);
  }

  async saveBrandingSettings(
    data: NetworkBrandingModel
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.post('/networks/settings/branding', data);
  }

  async saveMobileAppsSettings(
    data: NetworkMobileAppsModel
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.post('/networks/settings/mobile', data);
  }

  async saveDesignSettings(
    data: NetworkDesignModel
  ): Promise<ResponseNetworkDesignModel | ResponseErrorModel> {
    return axios.post('/networks/settings/style', data);
  }

  /* async currentNetworkSettings(): Promise<
    ResponseNetworkFullSettingsModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/all`);
  } */

  async networkSettings(): Promise<
    ResponseNetworkFullSettingsModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/all`);
  }

  async mobileAppsSettings(): Promise<
    ResponseNetworkMobileAppsModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/mobileapps`);
  }

  async brandingSettings(): Promise<
    ResponseNetworkBrandingModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/branding`);
  }

  async designSettings(): Promise<
    ResponseNetworkDesignModel | ResponseErrorModel
  > {
    return axios.get(`/networks/settings/style`);
  }
}
