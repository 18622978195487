import type {
  RequestCallToUserModel,
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseTokenModel,
  RequestAnswerToUserModel,
  ResponseCallToUserModel,
  ResponseParticipantsModel,
  ResponseAnswerModel,
} from '@/@types';
import axios from '@/services/axios';

export class MeetApiService {
  async getToken(
    name: string,
    roomId: string
  ): Promise<ResponseTokenModel | ResponseErrorModel> {
    return axios.post('/meet/token', {
      name,
      room: roomId,
    });
  }
  async getActiveParticipants(
    chainId: number
  ): Promise<ResponseParticipantsModel | ResponseErrorModel> {
    return axios.get(`/meet/participants/${chainId}`);
  }
  async callUser(
    data: RequestCallToUserModel
  ): Promise<ResponseCallToUserModel | ResponseErrorModel> {
    return axios.post('/meet/call', data);
  }
  async answerUser(
    data: RequestAnswerToUserModel
  ): Promise<ResponseAnswerModel | ResponseErrorModel> {
    return axios.post('/meet/result', data);
  }
  async reject(
    chainId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/meet/cancel', {
      chainId,
    });
  }
}
