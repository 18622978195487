import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import type {
  RouteLocationNormalizedLoaded,
  RouteLocationRaw,
} from 'vue-router';

import { HomePageEnum } from '@/@enums';
import type { UserHomePageModel } from '@/@types';
import { ROUTES_NAME } from '@/router';
import { useUserStore, useAppStore } from '@/store';

export class HomePageHelper {
  static homePage: ComputedRef<UserHomePageModel | null> = computed(() => {
    const userStore = useUserStore();
    if (userStore.current) {
      return userStore.current.homePage;
    }
    return null;
  });

  public static getHomePageId = (): number | null => {
    if (this.homePage.value?.unitId) {
      return this.homePage.value.unitId;
    }
    return null;
  };

  public static getHomePage = (): RouteLocationRaw | any => {
    const { value } = this.homePage;
    let location;
    if (value) {
      const { type, unitId } = value;
      if (type === HomePageEnum.Group || type === HomePageEnum.CustomPage) {
        if (unitId !== null) {
          location = {
            name:
              type === HomePageEnum.Group
                ? ROUTES_NAME.GROUP_BY_ID
                : ROUTES_NAME.PAGE_BY_ID,
            params: { id: unitId },
          };
          return location as RouteLocationRaw;
        }
      }
    }
    location = { name: ROUTES_NAME.FEED };
    return location as RouteLocationRaw;
  };

  public static setHomePage = async (): Promise<void> => {
    const appStore = useAppStore();
    const location = this.getHomePage() as RouteLocationRaw;
    appStore.$patch((state) => {
      state.homePage = location as any;
    });
  };

  public static isHomePage = (
    route: RouteLocationNormalizedLoaded,
    id?: number
  ): boolean => {
    const location = this.getHomePage() as any;
    if (id && location.params?.id) {
      if (id === location.params.id && location.name === route.name) {
        return true;
      }
    } else if (location.name === route.name) {
      return true;
    }
    return false;
  };

  public static getHomePageType = (): HomePageEnum | null => {
    if (this.homePage.value) {
      return this.homePage.value.type as HomePageEnum;
    } else {
      return null;
    }
  };
}
