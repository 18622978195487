import type {
  ResponseErrorModel,
  ResponseSuccessModel,
  ResponseMenuModel,
} from '@/@types';
import axios from '@/services/axios';

export class CustomMenuApiService {
  async getCustomMenu(): Promise<ResponseMenuModel | ResponseErrorModel> {
    return axios.get('/customMenu/show');
  }
  //TODO
  // async saveCustomMenu(
  //   data
  // ): Promise<ResponseSuccessModel | ResponseErrorModel> {
  //   return axios.post(`/customMenu/save`, {
  //     isPublic: data.isPublic,
  //     sections: data.sections,
  //   });
  // }
  async setGroupMenuPosition(
    groupId: number,
    index: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`customMenu/position?groupId=${groupId}&index=${index}`, {
      groupId: groupId,
      index: index,
    });
  }
  async pinGroup(
    groupId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`customMenu/pin?groupId=${groupId}`, {
      groupId: groupId,
    });
  }
  async unpinGroup(
    groupId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`customMenu/unpin?groupId=${groupId}`, {
      groupId: groupId,
    });
  }
  async setPublicity(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/customMenu/setPublicity?type=0`);
  }
  async reset(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/customMenu/reset`);
  }
}
