import { alertController, modalController } from '@ionic/vue';
import {
  trashOutline,
  eyeOutline,
  addOutline,
  repeatOutline,
  documentTextOutline,
  saveOutline,
} from 'ionicons/icons';
import { debounce } from 'lodash';
import type { ComputedRef, Ref } from 'vue';
import { computed, ref } from 'vue';

import { wikiActionsMenuSheet } from './actionSheetComponents';
import {
  componentTitleChange,
  componentWikiFollowersModal,
  wikiActionsMenu,
} from './modalComponents';

import {
  DataViewMode,
  DocumentTypeEnum,
  WikiMenuActionEnum,
  WikiEditControlsEnum,
  FileStatusEnum,
  DocumentExtensionEnum,
  WikiDeleteOptionsEnum,
  WikiDownloadOptionsEnum,
  WikiVersionEnum,
  WikiEditOptionsEnum,
  BasicEditControlsEnum,
  WikiSaveModeEnum,
} from '@/@enums';
import type {
  DocEntity,
  WikiModel,
  WikiHistoryModel,
  MediaModel,
  AppActionButton,
  WikiActionsMenuModel,
  EditOptions,
  EditControls,
  WikiDraftModel,
  TreeFolderModel,
  GroupEntity,
  CreateWikiPayload,
  UpdateWikiPayload,
  WikiEditFormModel,
  WhichActionToMakePayload,
} from '@/@types';
import {
  filesHybrid,
  showToast,
  isBlob,
  wikiReplaceActionsMenu,
  componentDocsAttachment,
  componentDocsCreateFile,
  componentDocsMoveFile,
  componentWikiCreate,
  componentWikiRelations,
  componentWikiHistoryModal,
  isAnyMobile,
  formatDateHelper,
  appDebounce,
  useRichText,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import type { WikiState } from '@/store';
import {
  useDocStore,
  useWikiStore,
  useUserStore,
  useNetworkStore,
} from '@/store';

export type IWiki = {
  //* Preview Menu
  getActionsMenuItems: () => WikiActionsMenuModel[];

  //* Edit Header Options
  getEditOptions(): EditOptions[];
  editOptionsAction(action: WikiEditOptionsEnum): Promise<void>;

  //* Edit Header Controls
  getEditControls(): EditControls[];
  editControlsAction(
    action: BasicEditControlsEnum | WikiEditControlsEnum
  ): void;
  openActionsMenu: (wiki: WikiModel) => Promise<void>;

  //* Actions
  whichActionToMake: (payload: WhichActionToMakePayload) => Promise<void>;
  openWiki: (id: number) => Promise<void>;
  createWiki: () => Promise<void>;
  deleteWiki: (wiki?: WikiModel, id?: number) => Promise<void>;

  //* Relations
  //TODO: addRelation
  //TODO: removeRelation

  //* History
  //TODO: getHistoryById
  //TODO: getHistoryByDate
  //TODO: updateHistoricalWiki
  getHistoricalButtons: (
    version: WikiHistoryModel,
    mode: DataViewMode
  ) => {
    left: AppActionButton;
    right: AppActionButton;
  };
  getHistoricalModifiedByAvatar: (wiki: WikiHistoryModel) => MediaModel | null;
  compareHistorical: (id: number) => Promise<void>;

  //* Drafts
  debouncedUpdateDraft: () => void;

  //* Templates
  //TODO: getTemplateById
  //TODO: getTemplates
  //TODO: createFromTemplateById
  //TODO: createTemplate
  //TODO: updateTemplateById
  //TODO: deleteTemplateById

  //* Tags
  //TODO: addTag
  //TODO: removeTag

  //* Lock
  //TODO: lockEdit
  //TODO: unlockEdit
  //TODO: checkLock

  //* Contributions
  //TODO: getContributionsByUserId

  //* Utils
  shouldPreventWikiEdit(id?: number, wiki?: WikiModel | null): Promise<boolean>;
  preventSave: () => string | false;
  leaveEditPage: () => void;
  checkIfOutdated: (id?: number | null | undefined) => Promise<boolean>;
};

export const useWiki = (): IWiki => {
  // Helpers
  const { t } = useI18n();

  // Store
  const wikiStore = useWikiStore();
  const docStore = useDocStore();
  const userStore = useUserStore();
  const networkStore = useNetworkStore();

  // Refs
  const currentSaveMode: Ref<WikiSaveModeEnum> = ref(WikiSaveModeEnum.Major);
  const resetActiveFolderFlag = ref<boolean>(false);

  // Computed - non wikiStore
  const isAdvancedModeOn: ComputedRef<boolean> = computed(
    () => networkStore.settings?.isAdvancedWikiesEditor ?? false
  );
  const selectedFolder: ComputedRef<TreeFolderModel | null> = computed(
    () => docStore.selectedFolder ?? null
  );
  const selectedGroup: ComputedRef<GroupEntity | null> = computed(
    () => docStore.selectedGroup ?? null
  );

  // Computed - wikiStore - existingWiki
  const existingWiki: ComputedRef<WikiModel | null> = computed(
    () => wikiStore.existingWiki || null
  );
  const historicalWiki: ComputedRef<WikiModel | null> = computed(
    () => wikiStore.historicalWiki || null
  );
  const isSimple: ComputedRef<boolean> = computed(() => wikiStore.isSimple);
  const existingId: ComputedRef<number | null> = computed(
    () => wikiStore.existingWiki?.id ?? null
  );

  // Computed - wikiStore - draftWiki
  const draftWiki: ComputedRef<WikiDraftModel | null> = computed(
    () => wikiStore.draftWiki ?? null
  );

  // Computed - wikiStore - editForm
  const editForm: ComputedRef<WikiEditFormModel> = computed(
    () => wikiStore.editForm
  );

  // Computed - wikiStore - general
  const newId: ComputedRef<number | null> = computed(() => wikiStore.newId);
  const isModalMode: ComputedRef<boolean> = computed(
    () => wikiStore.isModalMode
  );
  // const messageFromAi = computed(() => wikiStore.messageFromAi || '');

  const icons = {
    eye: eyeOutline, // Go to the current version
    plus: addOutline, // Save as template
    arrows: repeatOutline, // Update template
    save: saveOutline, // Update draft
    doc: documentTextOutline, // Delete draft
    trash: trashOutline, // Delete note
  };

  //* Private methods
  const _confirmDelete = async (
    wikiId: number | undefined,
    versionId: number | undefined
  ): Promise<void> => {
    const id = wikiId ?? versionId;

    if (!id) {
      console.error('Wiki id is not defined');
      return;
    }

    const alert = await alertController.create({
      message: `${t('documents.popup.deleteWiki')} <strong>${id}</strong>?`,
      buttons: [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert_buttons',
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert_buttons',
          handler: async () => {
            try {
              const result = await wikiStore.delete(id);
              if (!result) return;

              await showToast(
                t('documents.popup.deleteSuccess'),
                true,
                false,
                undefined,
                'delete_wiki_success'
              );
              await modalController.dismiss();
              router.back();
            } catch (error) {
              await showToast(
                t('documents.popup.deleteError'),
                false,
                false,
                undefined,
                'delete_wiki_error'
              );
            }
          },
        },
      ],
    });

    await alert.present();
  };

  const _replaceFromDocBrowser = async (wiki: WikiModel): Promise<boolean> => {
    try {
      const result = await componentDocsAttachment(null);

      if (result.data !== undefined) {
        const data = result.data as DocEntity[];

        data.forEach(async (element) => {
          if (element.documentType === DocumentTypeEnum.Wiki) {
            await wikiStore.deleteWithReplace(wiki.id, element.data.id, null);
          } else {
            await wikiStore.deleteWithReplace(wiki.id, null, element.data.id);
          }
        });
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const _replaceWithNewWiki = async (wiki: WikiModel): Promise<boolean> => {
    const result = await componentWikiCreate(
      wiki.group?.id,
      wiki?.parentFolderId ?? undefined,
      false
    );

    if (result) {
      await wikiStore.deleteWithReplace(wiki.id, result.id, null);
    }

    return false;
  };

  const _replaceWithNewFile = async (wiki: WikiModel): Promise<boolean> => {
    const result = await componentDocsCreateFile();

    if (result !== null) {
      await wikiStore.deleteWithReplace(wiki.id, null, result.id);
    }

    return false;
  };

  const _replace = async (wiki: WikiModel): Promise<boolean> => {
    const result = await wikiReplaceActionsMenu();

    if (result.data !== undefined) {
      switch (result.data) {
        case WikiDeleteOptionsEnum.ReplaceFromDocBrowser:
          await _replaceFromDocBrowser(wiki);
          break;
        case WikiDeleteOptionsEnum.ReplaceWithNewWiki:
          await _replaceWithNewWiki(wiki);
          break;
        case WikiDeleteOptionsEnum.ReplaceWithNewFile:
          await _replaceWithNewFile(wiki);
          break;
      }
    }

    return false;
  };

  const _editTitle = async (): Promise<void> => {
    const result = await componentTitleChange(
      null,
      draftWiki.value?.name || existingWiki.value?.name || ''
    );
    if (result.data?.title) {
      wikiStore.$patch((state) => {
        state.hasChanged = true;
        state.editForm.name = result.data?.title ?? '';
      });
    }
  };

  const _showGroup = async () => {
    console.log('≥≥≥showGroup'); //TODO
  };

  const _showFolder = async () => {
    console.log('≥≥≥showFolder'); //TODO
  };

  const _showAuthor = async () => {
    console.log('≥≥≥showAuthor'); //TODO
  };

  const _showLastEditor = async () => {
    console.log('≥≥≥showLastEditor'); //TODO
  };

  const _goToCurrentVersion = async () => {
    if (!existingWiki.value?.id || !historicalWiki.value?.id) {
      console.error('Error during going to current version: no wiki id');
      return;
    }

    wikiStore.$patch((state: WikiState) => {
      state.historicalWiki = null;
    });
  };

  const _saveAsTemplate = async () => {
    console.log('≥≥≥saveAsTemplate'); //TODO
  };

  const _updateTemplate = async () => {
    console.log('≥≥≥updateTemplate'); //TODO
  };

  const _deleteDraft = async (): Promise<void> => {
    const draftId =
      draftWiki.value?.id ?? existingWiki.value?.tempWikiId ?? null;
    if (!draftId) {
      console.error('Error during deleting temp wiki: no temp wiki id');
      return;
    }

    const isDeleted = await wikiStore.deleteDraftById(draftId);

    if (!isDeleted) {
      console.error('Error during deleting temp wiki');
      return;
    }

    wikiStore.resetEditForm();
    wikiStore.setSimpleWiki('name', existingWiki.value?.name || '');

    if (isSimple.value) {
      const preprocessedBody = await useRichText().preprocessBody(
        existingWiki.value?.wikiText ?? ''
      );
      wikiStore.setSimpleWiki('text', preprocessedBody);
      wikiStore.setSimpleContentToBody(preprocessedBody);
    } else {
      if (existingWiki.value?.wikiContent) {
        wikiStore.setAdvancedWiki(existingWiki.value);
      }
    }

    await showToast(
      t('wiki.editMessages.draftDeletedSuccess'),
      true,
      false,
      undefined,
      'delete_draft_success'
    );
    wikiStore.$patch((state) => {
      state.isDraftDeleted = false;
    });
  };

  const _updateExistingWiki = async (
    payload: UpdateWikiPayload
  ): Promise<boolean> => {
    const result = await wikiStore.update({
      wikiId: wikiStore.existingWiki?.id || 0,
      mentionedUserIds: wikiStore.editForm.mentionedUserIds ?? [], //?: Is this correct?
      ...payload,
    });

    if (!result) {
      await showToast(
        t('wiki.editMessages.updateError'),
        false,
        false,
        undefined,
        'update_wiki_error'
      );
      return false;
    }

    await showToast(
      t('wiki.editMessages.updatedSuccess'),
      true,
      false,
      undefined,
      'update_wiki_success'
    );
    wikiStore.$patch((state) => {
      state.draftWiki = null;
    });

    await leaveEditPage();
    return true;
  };

  const _onNewWikiCreate = async (
    payload: CreateWikiPayload
  ): Promise<boolean> => {
    const createdWikiId = await wikiStore.create(payload);

    if (!createdWikiId) {
      console.error('Error during creating wiki');
      await showToast(
        t('wiki.editMessages.createError'),
        false,
        false,
        undefined,
        'create_wiki_error'
      );
      return false;
    }

    await showToast(
      t('wiki.editMessages.createSuccess'),
      true,
      false,
      undefined,
      'create_wiki_success'
    );
    await leaveEditPage();
    return true;
  };

  const _saveWiki = async (saveOption: WikiSaveModeEnum): Promise<boolean> => {
    if (saveOption === WikiSaveModeEnum.Minor) {
      currentSaveMode.value = WikiSaveModeEnum.Minor;
    }

    wikiStore.$patch((state) => {
      state.saveLoading = true;
    });

    try {
      const reason = preventSave();
      if (reason) {
        await showToast(reason, false, false, undefined, 'prevent_wiki_save');
        return false;
      }

      const content = {
        head: editForm.value.wikiContent.head,
        content: editForm.value.wikiContent.content,
        body: editForm.value.wikiContent.body,
        participants: {
          name: editForm.value.wikiContent.participants.name,
          participantsIds: editForm.value.participantsIds,
          settings: {
            allUsers: false,
            isVisible: true,
            isDeleted: false,
          },
        },
      };

      const payload: CreateWikiPayload = {
        name: editForm.value.name,
        groupId: selectedGroup.value?.id || editForm.value.groupId,
        folderId: resetActiveFolderFlag.value
          ? selectedFolder.value?.id || null
          : selectedFolder.value?.id || editForm.value.folderId,
        isMajor: currentSaveMode.value !== WikiSaveModeEnum.Minor,
        text: !isAdvancedModeOn.value ? editForm.value.wikiText : '',
        content: content,
        participantsIds: editForm.value.participantsIds,
        accessOnlyForGroupId: null,
        // accessOnlyForGroupId: selectedGroup.value?.id || groupId.value, //TODO: Add real functionality
        mentionedUserIds: wikiStore.editForm.mentionedUserIds ?? [], //?: Is this correct?
      };

      if (existingId.value) {
        return await _updateExistingWiki(payload);
      }

      return await _onNewWikiCreate(payload);
    } catch (error) {
      console.error('Failed to save wiki', error);
      return false;
    } finally {
      wikiStore.$patch((state) => {
        state.saveLoading = false;
      });
    }
  };

  const _edit = async (wiki: WikiModel | undefined): Promise<void> => {
    if (!wiki) {
      console.error('Wiki is not defined');
      return;
    }

    if (await shouldPreventWikiEdit()) {
      return;
    }

    await shouldPreventWikiEdit();
    wikiStore.$patch((state: WikiState) => {
      state.editForm.groupId = wiki.group?.id ?? null;
      state.editForm.folderId = wiki.parentFolderId ?? null;
    });

    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      params: { id: wiki.id },
    });
  };

  const _move = async (id: number): Promise<void> => {
    try {
      const result = await componentDocsMoveFile(null);
      if (!result.data) {
        console.error('No folder selected');
        return;
      }

      await docStore.moveWiki(result.data.folderId, result.data.groupId, id);

      await showToast(
        t('wiki.menuActions.move.success'),
        true,
        false,
        undefined,
        'move_wiki_success'
      );
      await wikiStore.getWikiById(id);
    } catch (error) {
      console.error('Failed to move wiki', error);
      await showToast(
        t('wiki.menuActions.move.error'),
        false,
        false,
        undefined,
        'move_wiki_error'
      );
    }
  };

  const _markOfficial = async (id: number): Promise<void> => {
    await wikiStore.markOfficial(id);
  };

  const _showRelations = async (id: number): Promise<void> => {
    await wikiStore.getRelations(id);
    await componentWikiRelations();
  };

  const _follow = async (id: number): Promise<void> => {
    await wikiStore.follow(id);
  };

  const _unfollow = async (id: number): Promise<void> => {
    await wikiStore.unfollow(id);
  };

  const _showFollowers = async (id: number): Promise<void> => {
    await wikiStore.getFollowers(id);
    await componentWikiFollowersModal();
  };

  const _showHistory = async (id: number): Promise<void> => {
    const result = await wikiStore.getHistory(id);
    if (!result) {
      console.error('Failed to get history');
      //TODO: await showToast(t('wiki.history.error'), false, false, undefined, 'get_wiki_history_error');
      await showToast('Failed to get history', false);
      return;
    }

    if (!existingWiki.value) {
      const latest = await wikiStore.getHistoricalWikiByDate(
        id,
        new Date().toISOString(),
        'All'
      );
      if (!latest) {
        console.error('Failed to get latest historical wiki');
        //TODO: await showToast(t('wiki.history.error'), false, false, undefined, 'get_wiki_history_error');
        return;
      }
    }
    await componentWikiHistoryModal();
  };

  const _rollbackVersion = async (
    id: number | undefined,
    versionId: number | undefined
  ): Promise<void> => {
    if (!id || !versionId) {
      console.error('Failed to rollback wiki: id or versionId is not defined');
      return;
    }

    try {
      await wikiStore.rollback(id, versionId);
      await showToast(
        t('wiki.menuActions.rollback.success'),
        true,
        false,
        undefined,
        'restore_wiki_success'
      );
    } catch (error) {
      console.error('Failed to rollback wiki', error);
      await showToast(
        t('wiki.menuActions.rollback.error'),
        false,
        false,
        undefined,
        'restore_wiki_error'
      );
    }
  };

  const _download = async (
    wiki: WikiModel | undefined,
    documentExtension: DocumentExtensionEnum
  ): Promise<void> => {
    if (!wiki) {
      console.error('Wiki is not defined');
      return;
    }

    const response = await wikiStore.download(wiki.id, documentExtension);
    if (isBlob(response)) {
      const result = await filesHybrid.downloadWiki(wiki, response as Blob);
      await showToast(
        t('files.successDownloaded'),
        result === FileStatusEnum.Success,
        false,
        undefined,
        'download_wiki_success'
      );
    } else {
      console.error('Error response:', response);
    }
  };

  const _updateDraft = async (): Promise<boolean> => {
    if (!wikiStore.hasChanged) {
      return false;
    }

    // We should not block the user from saving the draft with uncompleted fields
    // Only saving the actual wiki should be blocked
    // const reason = preventSave();
    // if (reason) {
    //   // await showToast(reason, false, false, undefined, 'prevent_wiki_save'); //?: Should we show this message?
    //   return false;
    // }

    const content = {
      head: editForm.value.wikiContent.head,
      content: editForm.value.wikiContent.content,
      body: editForm.value.wikiContent.body,
      participants: {
        name: editForm.value.wikiContent.participants.name,
        participantsIds: editForm.value.participantsIds,
        settings: {
          allUsers: false,
          isVisible: true,
          isDeleted: false,
        },
      },
    };

    const payload: UpdateWikiPayload = {
      wikiId: wikiStore.existingWiki?.id || null,
      tempWikiId: wikiStore.draftWiki?.id || null,
      // revisionId: wikiStore.revisionId || null, //TODO: Add real functionality
      name: editForm.value.name,
      groupId: selectedGroup.value?.id || editForm.value.groupId,
      folderId: resetActiveFolderFlag.value
        ? selectedFolder.value?.id || null
        : selectedFolder.value?.id || editForm.value.folderId,
      isMajor: currentSaveMode.value !== WikiSaveModeEnum.Minor,
      text: !isAdvancedModeOn.value ? editForm.value.wikiText : '',
      content: content,
      participantsIds: editForm.value.participantsIds,
      accessOnlyForGroupId: null,
      // accessOnlyForGroupId: selectedGroup.value?.id || editForm.value.groupId, //TODO: Add real functionality
      mentionedUserIds: wikiStore.editForm.participantsIds, //?: Is this correct?
    };

    const result = await wikiStore.updateCurrentDraft(payload);
    if (!result) {
      await showToast(
        t('wiki.editMessages.updateError'),
        false,
        false,
        undefined,
        'update_wiki_error'
      );
    }
    return result;
  };

  //* Public methods
  const getActionsMenuItems = (): WikiActionsMenuModel[] => {
    const menuItems = [
      {
        title: t(`wiki.menuActions.edit`),
        value: WikiMenuActionEnum.Edit,
        icon: 'edit',
        disabled: false,
      },
      {
        title: t(`files.menu.downloadAsPDF`),
        value: WikiDownloadOptionsEnum.DownloadAsPDF,
        icon: 'download',
        disabled: false,
      },
      {
        title: t(`files.menu.downloadAsDOCX`),
        value: WikiDownloadOptionsEnum.DownloadAsDOCX,
        icon: 'download',
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.markOfficial`),
        value: WikiMenuActionEnum.MarkOfficial,
        icon: existingWiki.value?.isOfficial ? 'star' : 'goldenStar',
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.showRelations`),
        value: WikiMenuActionEnum.ShowRelations,
        // icon: 'link-on-off',
        // icon: 'link',
        icon: 'gitlab_link',
        disabled: true, //TODO: Temporary disabled
      },
      {
        title: existingWiki.value?.isFollowed
          ? t(`subscribe.unfollow`)
          : t(`subscribe.follow`),
        value: existingWiki.value?.isFollowed
          ? WikiMenuActionEnum.Unfollow
          : WikiMenuActionEnum.Follow,
        icon: existingWiki.value?.isFollowed
          ? 'gitlab_unfollow'
          : 'gitlab_follow',
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.showFollowers`),
        value: WikiMenuActionEnum.ShowFollowers,
        icon: 'users',
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.compare`),
        value: WikiMenuActionEnum.CompareHistorical,
        icon: 'wiki-compare',
        disabled: true, //TODO: Temporary disabled
      },
      {
        title: t(`wiki.menuActions.move.title`),
        value: WikiMenuActionEnum.Move,
        icon: 'move',
        disabled: false,
      },
      {
        title: t(`wiki.menuActions.showHistory`),
        value: WikiMenuActionEnum.ShowHistory,
        icon: 'wiki-history',
        disabled: false, //TODO: Should be disabled if existingWiki has no history wikiStore.hasHistory
      },
      {
        title: t(`wiki.menuActions.rollback.title`),
        value: WikiMenuActionEnum.RollbackVersion,
        icon: 'refresh',
        // disabled: !wikiStore.historicalWiki && !wikiStore.isOutdated,
        disabled: true, //TODO: Temporary disabled
      },
      {
        title: t(`wiki.menuActions.delete`),
        value: WikiMenuActionEnum.Delete,
        icon: 'trash',
        disabled: false,
      },
    ];
    return menuItems.filter(({ disabled }) => !disabled);
  };

  const getEditOptions = (): EditOptions[] => {
    const options = [
      {
        title: t('wiki.editOptions.title'),
        action: WikiEditOptionsEnum.EditTitle,
        value: editForm.value.name || t('titleChangeModal.placeholder.title'),
      },
      {
        title: t('wiki.editOptions.group'),
        // action: WikiEditOptionsEnum.EditGroup, //TODO: Implement group change
        action: WikiEditOptionsEnum.None,
        value: wikiStore.getGroupTitle, //TODO: Implement group change
      },
      {
        title: t('wiki.editOptions.folder'),
        // action: WikiEditOptionsEnum.EditFolder, //TODO: Implement folder change
        action: WikiEditOptionsEnum.None,
        value: wikiStore.getFolderTitle,
      },
      {
        title: t('wiki.editOptions.author'),
        // action: WikiEditOptionsEnum.ShowAuthor, //TODO: Implement author navigation
        action: WikiEditOptionsEnum.None,
        value: wikiStore.getCreatedBy?.fullName ?? '',
      },
      {
        title: t('wiki.editOptions.createdAt'),
        action: WikiEditOptionsEnum.None,
        value: formatDateHelper(wikiStore.getCreationDate, 'long'),
      },
      {
        title: t('wiki.editOptions.lastEditedBy'),
        // action: WikiEditOptionsEnum.ShowLastEditor, //TODO: Implement last editor navigation
        action: WikiEditOptionsEnum.None,
        value: wikiStore.getLastEditor?.fullName ?? '',
      },
      {
        title: t('wiki.editOptions.lastEditedAt'),
        action: WikiEditOptionsEnum.None,
        value: formatDateHelper(wikiStore.getLastEditDate, 'long'),
      },
      {
        title: t('wiki.editOptions.outdated'),
        action: WikiEditOptionsEnum.Outdated,
        // value: wikiStore.isOutdated ? t('wiki.editOptions.outdated') : '',
        value: '',
      },
    ];

    return options.filter(
      ({ action, value }) =>
        action === WikiEditOptionsEnum.EditTitle || value !== ''
    );
  };

  const editOptionsAction = async (
    action: WikiEditOptionsEnum
  ): Promise<void> => {
    switch (action) {
      case WikiEditOptionsEnum.EditTitle:
        await _editTitle();
        break;
      case WikiEditOptionsEnum.EditGroup:
        await _showGroup();
        break;
      case WikiEditOptionsEnum.EditFolder:
        await _showFolder();
        break;
      case WikiEditOptionsEnum.ShowAuthor:
        await _showAuthor();
        break;
      case WikiEditOptionsEnum.ShowLastEditor:
        await _showLastEditor();
        break;
      case WikiEditOptionsEnum.None:
        break;
    }
  };

  const getEditControls = (): EditControls[] => {
    const goToCurrentVersionCase =
      !wikiStore.isOutdated ||
      !draftWiki.value?.realWikiId ||
      (!!draftWiki.value?.realWikiId &&
        draftWiki.value?.realWikiId !== existingWiki.value?.id); //?: In theory we can be o wiki edit page with draftWiki but no existingWiki

    const controls = [
      {
        title: t('wiki.editControls.goToCurrentVersion'),
        icon: icons.eye,
        // disabled: !goToCurrentVersionCase,
        disabled: true, //TODO: Temporary disabled
        value: WikiEditControlsEnum.GoToCurrentVersion,
      },
      {
        title: t('wiki.editControls.saveAsTemplate'),
        icon: icons.plus,
        disabled: true, //TODO: Temporary disabled - if existingWiki has a template
        value: WikiEditControlsEnum.SaveAsTemplate,
      },
      {
        title: t('wiki.editControls.updateTemplate'),
        icon: icons.arrows,
        disabled: true, //TODO: Temporary disabled
        value: WikiEditControlsEnum.UpdateTemplate,
      },
      {
        title: t('wiki.editControls.updateDraft'),
        icon: icons.save,
        disabled: !draftWiki.value,
        value: WikiEditControlsEnum.UpdateDraft,
      },
      {
        title: t('wiki.editControls.deleteDraft'),
        icon: icons.doc,
        disabled: !draftWiki.value,
        value: WikiEditControlsEnum.DeleteDraft,
      },
      {
        title: t('wiki.editControls.deleteNote'),
        icon: icons.trash,
        disabled: !existingId.value,
        value: WikiEditControlsEnum.Delete,
      },
      //TODO: lockEdit
      //TODO: unlockEdit
    ];

    return controls.filter(({ disabled }) => !disabled);
  };

  const editControlsAction = async (
    action: BasicEditControlsEnum | WikiEditControlsEnum
  ): Promise<void> => {
    switch (action) {
      case BasicEditControlsEnum.Cancel:
        await leaveEditPage();
        break;
      case BasicEditControlsEnum.Default:
        await _saveWiki(WikiSaveModeEnum.Major);
        break;
      case BasicEditControlsEnum.Optional:
        await _saveWiki(WikiSaveModeEnum.Minor);
        break;
      case WikiEditControlsEnum.GoToCurrentVersion:
        await _goToCurrentVersion();
        break;
      case WikiEditControlsEnum.SaveAsTemplate:
        await _saveAsTemplate();
        break;
      case WikiEditControlsEnum.UpdateTemplate:
        await _updateTemplate();
        break;
      case WikiEditControlsEnum.UpdateDraft:
        debouncedUpdateDraft();
        break;
      case WikiEditControlsEnum.DeleteDraft:
        await _deleteDraft();
        break;
      case WikiEditControlsEnum.Delete:
        await deleteWiki(existingWiki.value ?? undefined);
        break;
    }
  };

  const openWiki = async (id: number): Promise<void> => {
    await shouldPreventWikiEdit();
    await router.push({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id: id },
    });
  };

  const compareHistorical = async (id: number): Promise<void> => {
    wikiStore.$patch((state) => {
      const sIndex = state.history.findIndex((awv) => awv.id === id);
      const tIndex = sIndex + 1;
      state.sourceVersion = state.history[sIndex];
      state.targetVersion = state.history[tIndex];
    });

    if (!wikiStore.sourceVersion || !wikiStore.targetVersion) {
      console.error(
        'Failed to compare historical: source or target is not defined'
      );
      return;
    }

    await router.push({
      name: ROUTES_NAME.WIKI_COMPARE,
      query: {
        id: id,
      },
    });
  };

  //TODO: Make a modal with checkboxes for delete options
  const deleteWiki = async (wiki?: WikiModel, id?: number): Promise<void> => {
    if (!wiki?.id && !id) {
      console.error('Wiki id is not defined');
      return;
    }

    try {
      if (!wiki?.id && id) {
        await _confirmDelete(undefined, id);
      } else if (wiki?.id && !id) {
        const optionsAlert = await alertController.create({
          message: `${t('documents.popup.deleteWiki')} <strong>${wiki?.name ?? id}</strong>?`,
          buttons: [
            {
              text: t('documents.popup.deleteWithAllRelations'),
              cssClass: 'custom-alert_buttons',
              handler: async () => {
                await _confirmDelete(wiki.id, undefined);
              },
            },
            {
              text: t('documents.popup.deleteWithReplace'),
              cssClass: 'custom-alert_buttons',
              handler: async () => {
                try {
                  await _replace(wiki);
                  await showToast(
                    t('documents.popup.deleteSuccess'),
                    true,
                    false,
                    undefined,
                    'delete_wiki_success'
                  );
                  await modalController.dismiss();
                } catch (error) {
                  await showToast(
                    t('documents.popup.deleteError'),
                    false,
                    false,
                    undefined,
                    'delete_wiki_error'
                  );
                }
              },
            },
          ],
        });

        await optionsAlert.present();
      }
    } catch (error) {
      console.error('Failed to delete wiki', error);
    }
  };

  const whichActionToMake = async (
    payload: WhichActionToMakePayload
  ): Promise<void> => {
    console.log('Action:', payload); //! DEBUG

    const finalId = payload.id || payload.wiki?.id || payload.versionId;
    if (!finalId) {
      console.error('Action cannot be performed: finalId resolved to null');
      return;
    }
    console.log('Final id:', finalId); //! DEBUG

    if (payload.action !== WikiMenuActionEnum.MarkOfficial) {
      try {
        const topModal = await modalController.getTop();
        if (topModal && topModal.id === 'wiki_actions') {
          await modalController.dismiss(null, 'end', 'wiki_actions');
        }
      } catch (error) {
        console.error('Error dismissing modal:', error);
      }
    }

    try {
      switch (payload.action) {
        case WikiMenuActionEnum.Open:
          await openWiki(finalId);
          break;
        case WikiMenuActionEnum.ShowHistory:
          await _showHistory(finalId);
          break;
        case WikiMenuActionEnum.RollbackVersion:
          await _rollbackVersion(payload.wiki?.id, payload.versionId);
          break;
        case WikiMenuActionEnum.CompareHistorical:
          await compareHistorical(finalId);
          break;
        case WikiMenuActionEnum.Edit:
          await _edit(payload.wiki);
          break;
        case WikiMenuActionEnum.Move:
          await _move(finalId);
          break;
        case WikiMenuActionEnum.MarkOfficial:
          await _markOfficial(finalId);
          break;
        case WikiMenuActionEnum.ShowRelations:
          await _showRelations(finalId);
          break;
        case WikiMenuActionEnum.LockEdit:
          //TODO: await _lockEdit(existingWiki.value);
          break;
        case WikiMenuActionEnum.UnlockEdit:
          //TODO: await _unlockEdit(existingWiki.value);
          break;
        case WikiDownloadOptionsEnum.DownloadAsPDF:
          await _download(payload.wiki, DocumentExtensionEnum.PDF);
          break;
        case WikiDownloadOptionsEnum.DownloadAsDOCX:
          await _download(payload.wiki, DocumentExtensionEnum.DOCX);
          break;
        case WikiMenuActionEnum.Delete:
          await deleteWiki(payload.wiki, finalId);
          break;
        case WikiMenuActionEnum.ShowFollowers:
          await _showFollowers(finalId);
          break;
        case WikiMenuActionEnum.Follow:
          await _follow(finalId);
          break;
        case WikiMenuActionEnum.Unfollow:
          await _unfollow(finalId);
          break;
        default:
          console.error('Unknown action:', payload.action);
          return;
      }
    } catch (error) {
      console.error('Failed to perform action:', payload.action, error);
    }
  };

  const openActionsMenu = async (wiki: WikiModel): Promise<void> => {
    if (isAnyMobile) {
      const result = await wikiActionsMenuSheet();
      if (result.data !== undefined) {
        await whichActionToMake({
          action: result.data,
          wiki: wiki,
        });
      }
    } else {
      await wikiActionsMenu(wiki);
    }
  };

  const createWiki = async (): Promise<void> => {
    try {
      await shouldPreventWikiEdit();

      await router.push({
        name: ROUTES_NAME.WIKI_CREATE,
      });
    } catch (error) {
      console.error('Failed to go to wiki edit page', error);
    }
  };

  //! addRelation

  //! removeRelation

  //! getHistoryById

  //! getHistoryByDate

  //! updateHistoricalWiki

  //TODO: Move to separate file
  interface HistoricalButtons {
    left: AppActionButton;
    right: AppActionButton;
  }
  const getHistoricalButtons = (
    version: WikiHistoryModel,
    mode: DataViewMode
  ): HistoricalButtons => {
    return {
      left: {
        title: t('wiki.menuActions.rollback.title'),
        action: WikiMenuActionEnum.RollbackVersion,
        type: 'main',
        icon: 'refresh',
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
      right: {
        title: t('wiki.menuActions.compare'),
        action: WikiMenuActionEnum.CompareHistorical,
        type: 'main',
        icon: 'wiki-compare',
        showIcon: mode === DataViewMode.List,
        showTooltip: mode === DataViewMode.List,
        showTitle: false,
      },
    };
  };

  const getHistoricalModifiedByAvatar = (
    wiki: WikiHistoryModel
  ): MediaModel | null => {
    if (wiki.modifyUserId) {
      const uId = wiki.modifyUserId;
      const user = userStore.getUserProfile(uId);
      return user?.avatar;
    }
    return null;
  };

  async function shouldPreventWikiEdit(): Promise<boolean> {
    // if (!existingWiki.value && !existingId.value) {
    //   console.log('No wiki found. Proceeding...');
    //   return false;
    // }

    if (!existingWiki.value && existingId.value) {
      await wikiStore.getWikiById(existingId.value);
    }

    const chosenWikiIsAdvanced =
      existingWiki.value?.version === WikiVersionEnum.V2;
    if (!isAdvancedModeOn.value && chosenWikiIsAdvanced) {
      const isAdvanced = await alertController.create({
        header: t('wiki.preventAlert.title'),
        message: t('wiki.preventAlert.message'),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert_buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await isAdvanced.present();

      await isAdvanced.onDidDismiss();
      return true;
    }

    return false;
  }

  function preventSave(): string | false {
    const simpleFields = [editForm.value.name, editForm.value.wikiText];
    const simpleWikiErrorCase =
      isSimple.value && simpleFields.some((f) => !f) && !isAdvancedModeOn.value;

    const advancedFields = [
      editForm.value.wikiContent.head?.name,
      editForm.value.wikiContent.head?.text,
      editForm.value.wikiContent.content?.name,
      // editForm.value.advancedWikiModel.content?.text,
      ...editForm.value.wikiContent.body.map((item) => item.name || item.text),
      editForm.value.wikiContent.participants?.name,
    ];

    const advancedWikiErrorCase =
      advancedFields.some((f) => !f) && isAdvancedModeOn.value;

    const advancedObj = {
      headName: editForm.value.wikiContent.head?.name,
      headText: editForm.value.wikiContent.head?.text,
      contentName: editForm.value.wikiContent.content?.name,
      // contentText: editForm.value.advancedWikiModel.content?.text,
      bodyNames: editForm.value.wikiContent.body.map((item) => item.name),
      bodyTexts: editForm.value.wikiContent.body.map((item) => item.text),
      participantsName: editForm.value.wikiContent.participants?.name,
    };

    if (simpleWikiErrorCase) {
      console.warn(`
        Error during saving simple wiki:\n
        name: ${editForm.value.name.length > 0 ? '✅' : '❌'}
        text: ${editForm.value.wikiText.length > 0 ? '✅' : '❌'}
        `);
    } else if (advancedWikiErrorCase) {
      console.warn(`
        Error during saving advanced wiki:\n
        headName: ${advancedObj.headName.length > 0 ? '✅' : '❌'}
        headText: ${advancedObj.headText.length > 0 ? '✅' : '❌'}
        contentName: ${advancedObj.contentName.length > 0 ? '✅' : '❌'}
        bodyNames: ${advancedObj.bodyNames.every((n) => n.length > 0) ? '✅' : '❌'}
        bodyTexts: ${advancedObj.bodyTexts.every((t) => t.length > 0) ? '✅' : '❌'}
        participantsName: ${advancedObj.participantsName.length > 0 ? '✅' : '❌'}
        `);
      // contentText: ${advancedObj.contentText.length > 0 ? '✅' : '❌'} //TODO: It should be checked later
    }

    //TODO: i18n
    if (!editForm.value.name) {
      return `Name is required: ${editForm.value.name}`;
    }

    if (simpleWikiErrorCase) {
      return `Text is required: ${editForm.value.wikiText}`;
    }

    switch (false) {
      case advancedObj.headName.length > 0:
        return 'Head title is required';
      case advancedObj.headText.length > 0:
        return 'Head text is required';
      case advancedObj.contentName.length > 0:
        return 'Content title is required';
      // case advancedObj.contentText.length > 0:
      //   return 'Content text is required: ${advancedObj.contentText}`;
      case advancedObj.bodyNames.every((n) => n.length > 0):
        return 'Body title is required';
      case advancedObj.bodyTexts.every((t) => t.length > 0):
        return 'Body text is required';
      case advancedObj.participantsName.length > 0:
        return 'Participants title is required';
    }

    return false;
  }

  async function leaveEditPage(): Promise<void> {
    if (isModalMode.value) {
      // await modalController.dismiss(null, 'end', 'wiki-create');
      await modalController.dismiss();
      wikiStore.$patch((state) => {
        state.isModalMode = false;
      });
      return;
    }

    if (!existingId.value && !newId.value) {
      router.back();
    } else {
      await router.push({
        name: ROUTES_NAME.WIKI_BY_ID,
        params: { id: existingId.value ?? newId.value },
      });
    }
  }

  const debouncedUpdateDraft = debounce(async (): Promise<boolean> => {
    return _updateDraft();
  }, appDebounce * 5);

  async function checkIfOutdated(
    id?: number | null | undefined
  ): Promise<boolean> {
    console.log('≥≥≥Cecking if outdated - 1:', id); //! DEBUG

    const finalId = id || existingWiki.value?.id || null;
    if (!finalId) {
      console.error('Error during checking if outdated: no id');
      return false;
    }

    let result = false;
    try {
      let wikiToCheck = historicalWiki.value || existingWiki.value || null;
      if (!wikiToCheck) {
        await wikiStore.getWikiById(finalId);
        wikiToCheck = existingWiki.value;
      }
      if (!wikiToCheck) {
        console.error('Error during checking if outdated: no wiki to check');
        return result;
      }
      console.log('≥≥≥Cecking if outdated - 2:', wikiToCheck); //! DEBUG

      const latest = await wikiStore.getHistoricalWikiByDate(
        finalId,
        new Date().toISOString(),
        'All'
      );
      if (!latest) {
        console.error('Error during checking if outdated: no latest wiki');
        return result;
      }

      result =
        new Date(wikiToCheck.editedAt).getTime() <
        new Date(latest?.editedAt).getTime();

      //NOTE: If we are checking historical wiki or existing wiki, we should update the store
      //NOTE: If we are checking outside of wiki (feed) we don't have neither historical nor existing wiki so we don't need to update the store
      if (historicalWiki.value || existingWiki.value) {
        wikiStore.$patch((state) => {
          state.isOutdated = result;
        });
      }
    } catch (error) {
      console.error('Error during checking if outdated:', error);
    }

    console.log('≥≥≥Cecking if outdated - 3:', result); //! DEBUG
    return result;
  }

  //TODO: compareHistorical;

  //TODO: getTemplateById

  //TODO: getTemplates

  //TODO: createFromTemplateById

  //TODO: createTemplate

  //TODO: updateTemplateById

  //TODO: deleteTemplateById

  //TODO: addTag

  //TODO: removeTag

  //TODO: lockEdit

  //TODO: unlockEdit

  //TODO: checkLock

  //TODO: getContributionsByUserId

  return {
    getActionsMenuItems,
    openActionsMenu,
    getEditOptions,
    editOptionsAction,
    getEditControls,
    editControlsAction,
    whichActionToMake,
    openWiki,
    createWiki,
    deleteWiki,
    //TODO: addRelation
    //TODO: removeRelation
    //TODO: getHistoryById
    //TODO: getHistoryByDate
    //TODO: updateHistoricalWiki
    getHistoricalButtons,
    getHistoricalModifiedByAvatar,
    compareHistorical,
    //TODO: getTemplateById
    //TODO: getTemplates
    //TODO: createFromTemplateById
    //TODO: createTemplate
    //TODO: updateTemplateById
    //TODO: deleteTemplateById
    //TODO: addTag
    //TODO: removeTag
    //TODO: lockEdit
    //TODO: unlockEdit
    //TODO: checkLock
    //TODO: getContributionsByUserId
    shouldPreventWikiEdit,
    preventSave,
    leaveEditPage,
    debouncedUpdateDraft,
    checkIfOutdated,
  };
};
