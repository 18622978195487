<template>
  <div ref="announcementRef" class="announcement">
    <!-- We have an image -->
    <div
      v-if="
        announcementData.image !== null && announcementData.image.url !== null
      "
      class="announcement-image"
      :class="{ contain: imageIsContain }"
    >
      <media-image
        :internal="getUniqueKey(announcementData.image?.url)"
        :is-announcement="true"
        :image="announcementData.image"
        :name="announcementData.title"
        :with-text="true"
        center
        @onView="imageView(announcementData.image?.url)"
      />
    </div>

    <!-- We don't have an image -->
    <div v-else class="announcement-placeholder">
      <div class="placeholder-text">
        {{ $t('conversations.announcement.title') }}
      </div>
    </div>

    <div class="announcement-content">
      <slot />
      <div class="announcement-text">
        <div>
          <ion-label>
            <strong>{{ announcementData.title }}</strong>
          </ion-label>
        </div>
        <conversations-text
          :text-data="announcementData.bodyHtml"
          :feed-type="feedType"
          :conversations-flag="conversationsFlag"
        />
      </div>
    </div>

    <div
      v-if="
        conversationsFlag === ConversationsFlagEnum.FeedPage &&
        feedType === FeedTypeEnum.Announcement
      "
      class="announcement-button"
    >
      <ion-button mode="md" @click.stop="readAnnouncement()">
        <ion-icon slot="icon-only" :icon="icons.checkmark" />
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel, IonButton, IonIcon } from '@ionic/vue';
import { useWindowSize } from '@vueuse/core';
import { checkmarkOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { onMounted, ref, watch, computed } from 'vue';

import { FeedTypeEnum, ConversationsFlagEnum } from '@/@enums';
import type { PostEntity } from '@/@types';
import { MediaImage, ConversationsText } from '@/components';
import { componentImagesViewer, getUniqueKey } from '@/helpers';
import { usePostStore } from '@/store';

const props = defineProps({
  announcementData: {
    type: Object as PropType<PostEntity>,
    required: true,
  },
  feedType: {
    type: String as PropType<FeedTypeEnum>,
    default: () => '',
  },
  conversationsFlag: {
    type: String as PropType<ConversationsFlagEnum>,
    default: () => '',
  },
});

const icons = {
  checkmark: checkmarkOutline,
};

const announcementData: ComputedRef<PostEntity> = computed(
  () => props.announcementData
);

const imageView = async (url: string | undefined) => {
  if (url !== undefined) {
    await componentImagesViewer(0, [
      {
        file: announcementData?.value?.image ?? null,
        text: '',
      },
    ]);
  }
};

const postStore = usePostStore();
const readAnnouncement = async () => {
  await postStore.announcementRead(props.announcementData.id);
};

const announcementRef = ref<HTMLElement | null>(null);
const { width: innerWidth } = useWindowSize();
const imageIsContain = ref<boolean>(true);

const checkWidth = () => {
  if (announcementRef.value !== null) {
    imageIsContain.value =
      /* announcementRef.value?.clientWidth <= 450 ? true : false; */ false;
  }
};

onMounted(() => {
  setTimeout(() => {
    checkWidth();
  }, 0);
});

watch(innerWidth, () => {
  checkWidth();
});
</script>
<style lang="scss">
.announcement {
  &-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 12rem;

    ion-img::part(image) {
      object-fit: cover;
      position: relative !important;
      height: 12rem;
      border-radius: app-radius(md) app-radius(md) 0 0 !important;
    }

    /* Не используется сейчас */
    &.contain {
      height: 10rem;
      ion-img::part(image) {
        object-fit: contain;
        max-height: 10rem;
        width: 100%;
      }
      /* --- */
    }
  }
  &-placeholder {
    height: 12rem;
    background: color-mix(
      in srgb,
      rgba(var(--ion-color-intra-rgb), 0.1),
      var(--ion-color-light) 10%
    );
    border-radius: app-radius(md) app-radius(md) 0 0 !important;
    position: relative;
    .placeholder-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: clamp(1.2rem, 10vw, 2.6rem);
      color: color-mix(
        in srgb,
        var(--ion-color-intra),
        var(--ion-color-light) 20%
      );
      letter-spacing: 0.1rem;
      font-weight: bold;
    }
  }

  &-content {
    padding: app-padding(lg) app-padding(lg) 0 app-padding(lg);
  }

  &-text {
    background: transparent;
    border-radius: 0 0 4px 4px;
    margin-top: app-padding(md);
    ion-label {
      user-select: text;
      -webkit-user-select: text;
      -ms-user-select: text;
    }
  }

  &-button {
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: app-padding(md);
    right: app-padding(md);

    ion-button {
      --background: var(--ion-color-light-background-contrast-custom);
      --color: var(--ion-color-custom-gray-darker);
      --box-shadow: none;
      --border-radius: #{app-radius(md)};
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

@include respond-to-max-width(md) {
  .announcement-image ion-img::part(image) {
    border-radius: 0;
  }
}
</style>
