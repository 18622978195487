<template>
  <div
    class="app-header-menu"
    :style="{
      'grid-template-columns': `repeat(${menuItems.length}, minmax(min-content, 7rem))`,
    }"
  >
    <div
      v-for="(item, index) in menuItems"
      v-show="item.enabled"
      :key="`menu-item_${index}`"
      class="item"
      :class="{
        active: checkForActive(item),
      }"
      @click="menuItemClick($event, item)"
    >
      <!-- @mouseup.middle="menuItemClick($event, item)" -->
      <icons-provider
        class="icon"
        :icon-props="{
          width: '24',
          height: '24',
          fill: 'var(--ion-color-custom)',
        }"
        :name="item.icon"
      />
      <ion-label>{{ item.title }}</ion-label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel } from '@ionic/vue';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { AppMenuEnum } from '@/@enums';
import type { AppMenuItems } from '@/@types';
import IconsProvider from '@/components/Common/IconsProvider.vue';
import {
  useMenu,
  componentAppSubMenuPopover,
  componentLoginModal,
} from '@/helpers';

const menuHelper = useMenu();
const menuItems: ComputedRef<AppMenuItems[]> = computed(() =>
  menuHelper.getHeaderMenu()
);

const router = useRouter();
const route = useRoute();

const checkForActive = (item: AppMenuItems) =>
  menuHelper.checkForActive(item, route);

const menuItemClick = async (
  ev: MouseEvent,
  item: AppMenuItems
): Promise<void> => {
  if (item.submenu?.length) {
    await componentAppSubMenuPopover(ev, item.submenu, 'bottom');
    return;
  }

  if (item.href && (await menuHelper.validateCustomLink(item.href))) {
    window.open(new URL(item.href), '_blank');
    return;
  }

  if (item.link) {
    // https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
    await router.push({
      name: item.link.name,
      params: item.link.params,
      query: ev.button === 1 ? { newTab: 'true' } : {},
    });
    return;
  }

  if (item.name === AppMenuEnum.Login) {
    await componentLoginModal();
    return;
  }
};
</script>

<style scoped lang="scss">
.app-header-menu {
  display: grid;
  gap: 0;
  height: calc((var(--ion-safe-area-bottom) / 2) + 58px);
  width: 100%;
  z-index: 1;
  padding-bottom: calc(var(--ion-safe-area-bottom) / 2);
  .item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    opacity: 0.8;
    color: var(--ion-color-custom);
    padding-top: 8px;
    padding-bottom: 4px;
    overflow: hidden;
    justify-content: center;
    transition: all 0.15s ease-in-out;
    &:hover {
      cursor: pointer;
      background: rgba(var(--ion-color-light-rgb), 0.1);
    }
    &.active {
      opacity: 1;
      position: relative;
      &::after {
        display: block;
        content: '';
        width: 100%;
        height: 5px;
        background: var(--ion-color-custom);
        position: absolute;
        bottom: 0;
        border-radius: 1.5px;
      }
    }
    ion-label {
      font-size: 0.9rem;
      white-space: nowrap;
    }
    .icon {
      margin-bottom: 4px;
    }
  }
}
</style>
