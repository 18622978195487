<template>
  <div class="accordion-item">
    <ion-item
      mode="md"
      :class="['header', 'ion-text-wrap', { bordered: withBorderedTitle }]"
      lines="none"
      @click.prevent.stop="openSection()"
    >
      <ion-text
        :class="['ion-text-wrap', { 'small-header': smallHeaderTitle }]"
      >
        <slot name="header" />
      </ion-text>

      <ion-icon
        :slot="smallHeaderTitle ? 'start' : 'end'"
        :style="{ transform: `rotate(${arrowDegrees}deg)` }"
        :icon="icons.chevron"
      />
    </ion-item>

    <transition name="slide-fade">
      <div v-show="isOpen">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { IonIcon, IonItem, IonText } from '@ionic/vue';
import { chevronUp } from 'ionicons/icons';
import { ref } from 'vue';

const props = defineProps({
  withBorderedTitle: {
    type: Boolean,
    default: true,
  },
  smallHeaderTitle: {
    type: Boolean,
    default: false,
  },
  closedIsDefault: {
    type: Boolean,
    default: false,
  },
});

const icons = {
  chevron: chevronUp,
};
const arrowDegrees = ref<number>(180);
const isOpen = ref<boolean>(props.closedIsDefault ? false : true);

const openSection = () => {
  isOpen.value = !isOpen.value;
  arrowDegrees.value = isOpen.value ? 180 : 0;
};
</script>

<style scoped lang="scss">
.accordion-item .header {
  --padding-start: 0;
  --inner-padding-start: 0;
  --background: transparent;
}
// .accordion-item .header.bordered {
//   border-bottom: 1px solid var(--ion-color-custom-element-lighter);
// }
.accordion-item .header ion-text {
  padding: app-padding(lg) 0 app-padding(lg) 0;
  font-size: 1.2rem;
}
.accordion-item .header ion-text.small-header {
  font-size: 1rem;
}
.accordion-item .header:hover {
  cursor: pointer;
  opacity: 0.7;
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.05s ease-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
.accordion-item .header ion-icon {
  transition: all 0.2s ease-out;
  margin-inline-end: 0.5rem;
}
</style>
