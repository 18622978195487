import { Browser } from '@capacitor/browser';
import type { Route } from '@sentry/vue/types/router';
import useVuelidate from '@vuelidate/core';
import type { ComputedRef } from 'vue';
import { computed, reactive } from 'vue';

import { componentCampusModal } from './modalComponents';
import { useAiAssistant } from './useAiAssistantHelper';
import { useTaskManagementHelper } from './useTaskManagementHelper';

import { AppMenuEnum, UserRoleEnum } from '@/@enums';
import type { AppCustomMenuItems, AppMenuItems, ProjectEntity } from '@/@types';
import { extentedUrl } from '@/helpers/i18nValidators';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useAppStore, useProjectsStore, useUserStore } from '@/store';

interface IMenu {
  getHeaderMenu(): AppMenuItems[];
  getRightMenu(): AppMenuItems[];
  getFooterMenu(): AppMenuItems[];
  isCampusEnabled(): boolean;
  isQuickSearchEnabled(): boolean;
  getCampusLink(): string;
  openCampusModal(): Promise<void>;
  companiesList: ComputedRef<{ [key: string]: string }>;
  checkForActive(element: any, route: Route): boolean;
  validateCustomLink(href: string): Promise<boolean>;
}

export function useMenu(): IMenu {
  const { t } = useI18n();
  const appStore = useAppStore();
  const userStore = useUserStore();

  const maxItemsInBottomMenu = 4;
  const maxItemsInHeaderMenu: ComputedRef<number> = computed(
    () => appStore.maxItemsInHeaderMenu
  );

  const isCustomMenuManagedByAdminPanel = false;

  // Specifically for Klinikum-Erding - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1309
  const currentUserIsKEDummy: ComputedRef<boolean> = computed(
    () => userStore.current?.id == import.meta.env.VITE_KLINIKUM_ERDING_ID
  );

  const currentCompanyId: ComputedRef<string> = computed(
    () => appStore.companyRowId
  );

  const companiesList: ComputedRef<{ [key: string]: string }> = computed(() => {
    if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS) {
      console.warn(
        'The env variable VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS is not defined'
      );
      return {};
    }

    try {
      return JSON.parse(
        import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS
      );
    } catch (error) {
      console.error(
        'Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS env variable:',
        error
      );
      return {};
    }
  });

  const customMenuItems: ComputedRef<AppCustomMenuItems[]> = computed(() => {
    if (currentUserIsKEDummy.value) {
      return [
        {
          name: AppMenuEnum.HomePage,
          title: t('appBottomMenu.home'),
          hidden: false,
          order: 0,
        },
        {
          name: AppMenuEnum.Feed,
          title: t('appMenu.feed'),
          hidden: false,
          order: 1,
        },
        {
          name: AppMenuEnum.Messenger,
          title: t('appMenu.messenger'),
          hidden: true,
          order: 2,
        },
        {
          name: AppMenuEnum.People,
          title: t('appMenu.people'),
          hidden: true,
          order: 3,
        },
        {
          name: AppMenuEnum.Groups,
          title: t('appMenu.groups'),
          hidden: false,
          order: 4,
        },
        {
          name: AppMenuEnum.AiAssistant,
          title: t('appMenu.aiAssistant'),
          hidden: true,
          order: 5,
        },
        {
          name: AppMenuEnum.Projects,
          title: t('appMenu.projects'),
          hidden: true,
          order: 6,
        },
        {
          name: AppMenuEnum.Docs,
          title: t('appMenu.docs'),
          hidden: true,
          order: 7,
        },
        {
          name: AppMenuEnum.Calendar,
          title: t('appMenu.calendar'),
          hidden: true,
          order: 8,
        },
        {
          name: AppMenuEnum.Pages,
          title: t('appMenu.pages'),
          hidden: false,
          order: 9,
        },
        {
          name: AppMenuEnum.Topics,
          title: t('appMenu.topics'),
          hidden: true,
          order: 10,
        },
        {
          name: AppMenuEnum.Ideas,
          title: t('appMenu.ideas'),
          hidden: false,
          order: 11,
        },
        {
          name: AppMenuEnum.UsageRules,
          title: t('appMenu.usageRules'),
          hidden: false,
          order: 12,
        },
        {
          name: AppMenuEnum.Login,
          title: t('appMenu.login'),
          hidden: false,
          order: 14,
        },
      ];
    }
    if (isCustomMenuManagedByAdminPanel) {
      /** parse menu items order, titles and enabled properties from admin store*/
    } else {
      for (const key in companiesList.value) {
        if (companiesList.value[key] === currentCompanyId.value) {
          switch (key) {
            case 'SHGT': {
              return [
                {
                  name: AppMenuEnum.HomePage,
                  title: t('appBottomMenu.home'),
                  hidden: false,
                  order: 0,
                },
                {
                  name: AppMenuEnum.Feed,
                  title: t('appMenu.feed'),
                  hidden: true,
                  order: 1,
                },
                {
                  name: AppMenuEnum.Messenger,
                  title: t('appMenu.messenger'),
                  hidden: false,
                  order: 2,
                },
                {
                  name: AppMenuEnum.People,
                  title: t('appMenu.people'),
                  hidden: true,
                  order: 3,
                },
                {
                  name: AppMenuEnum.Groups,
                  title: t('appMenu.groups'),
                  hidden: false,
                  order: 4,
                },
                {
                  name: AppMenuEnum.AiAssistant,
                  title: t('appMenu.aiAssistant'),
                  hidden: false,
                  order: 5,
                },
                {
                  name: AppMenuEnum.Projects,
                  title: t('appMenu.projects'),
                  hidden: false,
                  order: 6,
                },
                {
                  name: AppMenuEnum.Docs,
                  title: t('appMenu.docs'),
                  hidden: true,
                  order: 7,
                },
                {
                  name: AppMenuEnum.Calendar,
                  title: t('appMenu.calendar'),
                  hidden: true,
                  order: 8,
                },
                {
                  name: AppMenuEnum.Pages,
                  title: t('appMenu.pages'),
                  hidden: true,
                  order: 9,
                },
                {
                  name: AppMenuEnum.Topics,
                  title: t('appMenu.topics'),
                  hidden: true,
                  order: 10,
                },
                {
                  name: AppMenuEnum.Ideas,
                  title: t('appMenu.ideas'),
                  hidden: true,
                  order: 11,
                },
                {
                  name: AppMenuEnum.UsageRules,
                  title: t('appMenu.usageRules'),
                  hidden: true,
                  order: 12,
                },
              ];
            }
            case 'CAMPUS': {
              return [
                {
                  name: AppMenuEnum.HomePage,
                  title: t('appBottomMenu.home'),
                  hidden: false,
                  order: 0,
                },
                {
                  name: AppMenuEnum.Feed,
                  title: t('appMenu.feed'),
                  hidden: true,
                  order: 1,
                },
                {
                  name: AppMenuEnum.Messenger,
                  title: t('appMenu.messenger'),
                  hidden: true,
                  order: 2,
                },
                {
                  name: AppMenuEnum.People,
                  title: t('appMenu.people'),
                  hidden: true,
                  order: 3,
                },
                {
                  name: AppMenuEnum.Groups,
                  title: t('appMenu.groups'),
                  hidden: false,
                  order: 4,
                },
                {
                  name: AppMenuEnum.AiAssistant,
                  title: t('appMenu.aiAssistant'),
                  hidden: false,
                  order: 5,
                },
                {
                  name: AppMenuEnum.Projects,
                  title: t('appMenu.projects'),
                  hidden: false,
                  order: 6,
                },
                {
                  name: AppMenuEnum.Docs,
                  title: t('appMenu.docs'),
                  hidden: false,
                  order: 7,
                },
                {
                  name: AppMenuEnum.Calendar,
                  title: t('appMenu.calendar'),
                  hidden: false,
                  order: 8,
                },
                {
                  name: AppMenuEnum.Pages,
                  title: t('appMenu.pages'),
                  hidden: true,
                  order: 9,
                },
                {
                  name: AppMenuEnum.Topics,
                  title: t('appMenu.topics'),
                  hidden: false,
                  order: 10,
                },
                {
                  name: AppMenuEnum.Ideas,
                  title: t('appMenu.ideas'),
                  hidden: false,
                  order: 11,
                },
                {
                  name: AppMenuEnum.UsageRules,
                  title: t('appMenu.usageRules'),
                  hidden: false,
                  order: 12,
                },
              ];
            }
            case 'ANDERSEN': {
              return [
                {
                  name: AppMenuEnum.HomePage,
                  title: t('appBottomMenu.home'),
                  hidden: false,
                  order: 0,
                },
                {
                  name: AppMenuEnum.Feed,
                  title: t('appMenu.feed'),
                  hidden: false,
                  order: 1,
                },
                {
                  name: AppMenuEnum.Messenger,
                  title: t('appMenu.messenger'),
                  hidden: true,
                  order: 2,
                },
                {
                  name: AppMenuEnum.People,
                  title: t('appMenu.people'),
                  hidden: false,
                  order: 3,
                },
                {
                  name: AppMenuEnum.Groups,
                  title: t('appMenu.groups'),
                  hidden: false,
                  order: 4,
                },
                {
                  name: AppMenuEnum.AiAssistant,
                  title: t('appMenu.aiAssistant'),
                  hidden: false,
                  order: 5,
                },
                {
                  name: AppMenuEnum.Projects,
                  title: t('appMenu.projects'),
                  hidden: false,
                  order: 6,
                },
                {
                  name: AppMenuEnum.Docs,
                  title: t('appMenu.docs'),
                  hidden: false,
                  order: 7,
                },
                {
                  name: AppMenuEnum.Calendar,
                  title: t('appMenu.calendar'),
                  hidden: false,
                  order: 8,
                },
                {
                  name: AppMenuEnum.Pages,
                  title: t('appMenu.pages'),
                  hidden: true,
                  order: 9,
                },
                {
                  name: AppMenuEnum.Topics,
                  title: t('appMenu.topics'),
                  hidden: false,
                  order: 10,
                },
                {
                  name: AppMenuEnum.Ideas,
                  title: t('appMenu.ideas'),
                  hidden: false,
                  order: 11,
                },
                {
                  name: AppMenuEnum.UsageRules,
                  title: t('appMenu.usageRules'),
                  hidden: false,
                  order: 12,
                },
              ];
            }
            case 'VOEB': {
              return [
                {
                  name: AppMenuEnum.HomePage,
                  title: t('appBottomMenu.home'),
                  hidden: false,
                  order: 0,
                },
                {
                  name: AppMenuEnum.Feed,
                  title: t('appMenu.feed'),
                  hidden: true,
                  order: 1,
                },
                {
                  name: AppMenuEnum.Messenger,
                  title: t('appMenu.messenger'),
                  hidden: true,
                  order: 2,
                },
                {
                  name: AppMenuEnum.People,
                  title: t('appMenu.people'),
                  hidden: true,
                  order: 3,
                },
                {
                  name: AppMenuEnum.Groups,
                  title: t('appMenu.groups'),
                  hidden: false,
                  order: 4,
                },
                {
                  name: AppMenuEnum.AiAssistant,
                  title: t('appMenu.aiAssistant'),
                  hidden: false,
                  order: 5,
                },
                {
                  name: AppMenuEnum.Projects,
                  title: t('appMenu.projects'),
                  hidden: true,
                  order: 6,
                },
                {
                  name: AppMenuEnum.Docs,
                  title: t('appMenu.docs'),
                  hidden: false,
                  order: 7,
                },
                {
                  name: AppMenuEnum.Calendar,
                  title: t('appMenu.calendar'),
                  hidden: true,
                  order: 8,
                },
                {
                  name: AppMenuEnum.Pages,
                  title: t('appMenu.pages'),
                  hidden: true,
                  order: 9,
                },
                {
                  name: AppMenuEnum.Topics,
                  title: t('appMenu.topics'),
                  hidden: true,
                  order: 10,
                },
                {
                  name: AppMenuEnum.Ideas,
                  title: t('appMenu.ideas'),
                  hidden: true,
                  order: 11,
                },
                {
                  name: AppMenuEnum.UsageRules,
                  title: t('appMenu.usageRules'),
                  hidden: true,
                  order: 12,
                },
              ];
            }
            case 'BKG': {
              return [
                {
                  name: AppMenuEnum.HomePage,
                  title: 'Startseite',
                  hidden: false,
                  order: 0,
                },
                {
                  name: AppMenuEnum.CustomLink,
                  title: 'LinkedIn',
                  href: 'https://www.linkedin.com/company/krankenhausgesellschaft/',
                  hidden: false,
                  order: 1,
                },
                {
                  name: AppMenuEnum.CustomLink,
                  title: 'BKG-Presseportal',
                  href: 'https://www.bkg-online.de/aktuelles/news',
                  hidden: false,
                  order: 2,
                },
                {
                  name: AppMenuEnum.CustomPage,
                  title: 'Anleitung',
                  link: {
                    name: ROUTES_NAME.PAGE_BY_ID,
                    params: { id: 7973 },
                  },
                  hidden: false,
                  order: 3,
                },
                {
                  name: AppMenuEnum.Feed,
                  title: t('appMenu.feed'),
                  hidden: true,
                  order: 4,
                },
                {
                  name: AppMenuEnum.Messenger,
                  title: t('appMenu.messenger'),
                  hidden: true,
                  order: 5,
                },
                {
                  name: AppMenuEnum.People,
                  title: t('appMenu.people'),
                  hidden: true,
                  order: 6,
                },
                {
                  name: AppMenuEnum.Groups,
                  title: 'Fachthemen',
                  hidden: true,
                  order: 7,
                },
                {
                  name: AppMenuEnum.AiAssistant,
                  title: t('appMenu.aiAssistant'),
                  hidden: true,
                  order: 8,
                },
                {
                  name: AppMenuEnum.Projects,
                  title: t('appMenu.projects'),
                  hidden: true,
                  order: 9,
                },
                {
                  name: AppMenuEnum.Docs,
                  title: t('appMenu.docs'),
                  hidden: true,
                  order: 10,
                },
                {
                  name: AppMenuEnum.Calendar,
                  title: t('appMenu.calendar'),
                  hidden: true,
                  order: 11,
                },
                {
                  name: AppMenuEnum.Pages,
                  title: t('appMenu.pages'),
                  hidden: true,
                  order: 12,
                },
                {
                  name: AppMenuEnum.Topics,
                  title: t('appMenu.topics'),
                  hidden: true,
                  order: 13,
                },
                {
                  name: AppMenuEnum.Ideas,
                  title: t('appMenu.ideas'),
                  hidden: true,
                  order: 14,
                },
                {
                  name: AppMenuEnum.UsageRules,
                  title: t('appMenu.usageRules'),
                  hidden: true,
                  order: 15,
                },
              ];
            }

            case 'DLT': {
              return [
                {
                  name: AppMenuEnum.HomePage,
                  title: t('appBottomMenu.home'),
                  hidden: false,
                  order: 0,
                },
                {
                  name: AppMenuEnum.Groups,
                  title: t('appMenu.groups'),
                  hidden: false,
                  order: 1,
                },
                {
                  name: AppMenuEnum.Search,
                  title: t('appMenu.search'),
                  hidden: false,
                  order: 2,
                },
                {
                  name: AppMenuEnum.Topics,
                  title: t('appMenu.topics'),
                  hidden: false,
                  order: 3,
                },
                {
                  name: AppMenuEnum.UsageRules,
                  title: t('appMenu.usageRules'),
                  hidden: false,
                  order: 4,
                },
              ];
            }

            default:
              return [];
          }
        }
      }
    }
    return [];
  });

  const mapWithCustomItems = (data: AppMenuItems[]): AppMenuItems[] => {
    return customMenuItems.value
      .map((customItem) => {
        const menuItem = data.find((item) => customItem.name === item.name);
        let resultItem = {} as AppMenuItems;
        if (menuItem) {
          resultItem = { ...menuItem };
          resultItem.title = customItem.title;
          resultItem.enabled = customItem.hidden ? false : menuItem.enabled;
          resultItem.href = customItem.href || '';
          resultItem.link = customItem.link || menuItem.link;
          resultItem.order = customItem.order;
        }
        return resultItem;
      })
      .sort((item1, item2) => item1.order - item2.order)
      .filter((n) => n.enabled);
  };

  const _getMenuItems = (): AppMenuItems[] => {
    const { t } = useI18n();

    const projectsStore = useProjectsStore();
    const aiAssistantHelper = useAiAssistant();
    const taskManagementHelper = useTaskManagementHelper();

    const currentProject: ComputedRef<ProjectEntity> = computed(
      () => projectsStore.getCurrentProject
    );
    const currentUserRoleId: ComputedRef<number> = computed(
      () => userStore.current?.roleId ?? 0
    );

    const aiAssistantEnabled: ComputedRef<boolean> = computed(() =>
      aiAssistantHelper.getAccessToAiAssistant()
    );
    const taskManagementEnabled: ComputedRef<boolean> = computed(() =>
      taskManagementHelper.getAccessToTaskManagement()
    );

    const homePage: ComputedRef<any> = computed(() => appStore.homePage);

    const data = [
      {
        name: AppMenuEnum.HomePage,
        title: t('appBottomMenu.home'),
        link: homePage.value,
        icon: 'home',
        enabled: homePage.value.name !== ROUTES_NAME.FEED,
        submenu: null,
        order: 0,
      },
      {
        name: AppMenuEnum.Feed,
        title: t('appMenu.feed'),
        link: { name: ROUTES_NAME.FEED },
        icon: 'feed',
        enabled: true,
        submenu: null,
        order: 1,
      },
      {
        name: AppMenuEnum.Messenger,
        title: t('appMenu.messenger'),
        link: { name: ROUTES_NAME.MESSENGER_ACTIVE },
        icon: 'chats',
        enabled: true,
        submenu: null,
        order: 2,
      },
      {
        name: AppMenuEnum.People,
        title: t('appMenu.people'),
        link: { name: ROUTES_NAME.USERS },
        icon: 'people',
        activeIcon: '',
        enabled: true,
        submenu: null,
        order: 3,
      },
      {
        name: AppMenuEnum.Groups,
        title: t('appMenu.groups'),
        link: { name: ROUTES_NAME.GROUPS },
        icon: 'spaces',
        enabled: true,
        submenu: null,
        order: 4,
      },

      {
        name: AppMenuEnum.Search,
        title: t('appMenu.search'),
        link: { name: ROUTES_NAME.SEARCH },
        icon: 'zoom',
        enabled: !currentUserIsKEDummy.value && !isQuickSearchEnabled(),
        submenu: null,
        order: 5,
      },

      {
        name: AppMenuEnum.AiAssistant,
        title: t('appMenu.aiAssistant'),
        link: { name: ROUTES_NAME.AI_ASSISTANT },
        icon: 'ai-assistant',
        enabled: aiAssistantEnabled.value,
        submenu: null,
        order: 6,
      },
      {
        name: AppMenuEnum.Projects,
        title: t('appMenu.projects'),
        link: { name: ROUTES_NAME.PROJECTS },
        icon: 'projects',
        enabled: taskManagementEnabled.value,
        order: 7,
        submenu: [
          {
            name: AppMenuEnum.Projects,
            title: t('taskManagement.projects.myProjects'),
            link: { name: ROUTES_NAME.PROJECTS },
            icon: 'layers',
            order: 7,
            enabled: true,
            submenu: null,
          },
          {
            name: AppMenuEnum.Tasks,
            title: t('taskManagement.tasks.myTasks'),
            link: { name: ROUTES_NAME.TASKS },
            icon: 'copy',
            enabled: true,
            order: 7,
            submenu: null,
          },
          {
            name: AppMenuEnum.Milestones,
            title: t('taskManagement.milestones.all'),
            link: {
              name: ROUTES_NAME.MILESTONES,
              params: { projectId: currentProject.value.id },
            },
            icon: 'milestones',
            enabled: currentProject.value.id > 0,
            order: 7,
            submenu: null,
          },
          /* {
            name: AppMenuEnum.ProjectsStatistics,
            title: t('statistics.title'),
            link: { name: ROUTES_NAME.PROJECTS_STATISTICS },
            defaultIcon: icons.layersOutline,
            activeIcon: icons.layers,
            enabled: true,
            submenu: null,
          }, */
        ],
      },

      {
        name: AppMenuEnum.Docs,
        title: t('appMenu.docs'),
        link: { name: ROUTES_NAME.DOCS },
        icon: 'documents',
        enabled: true,
        submenu: null,
        order: 8,
      },

      /*
      {
        name: AppMenuEnum.Wiki,
        title: t('appMenu.docs'),
        link: { name: ROUTES_NAME.WIKIS },
        defaultIcon: icons.folderOpenOutline,
        activeIcon: icons.folderOpen,
        enabled: !currentUserIsKEDummy.value,
        submenu: null,
      },
      */

      {
        name: AppMenuEnum.Calendar,
        title: t('appMenu.calendar'),
        link: { name: ROUTES_NAME.CALENDAR },
        icon: 'calendar',
        enabled: true,
        submenu: null,
        order: 9,
      },
      {
        name: AppMenuEnum.Pages,
        title: t('appMenu.pages'),
        link: { name: ROUTES_NAME.PAGES },
        icon: 'pages',
        enabled: currentUserRoleId.value >= UserRoleEnum.User,
        submenu: null,
        order: 10,
      },
      {
        name: AppMenuEnum.Topics,
        title: t('appMenu.topics'),
        link: { name: ROUTES_NAME.TOPICS },
        icon: 'tags',
        enabled: true,
        submenu: null,
        order: 11,
      },
      {
        name: AppMenuEnum.Ideas,
        title: t('appMenu.ideas'),
        link: { name: ROUTES_NAME.IDEAS },
        icon: 'idea',
        enabled: true,
        submenu: null,
        order: 12,
      },
      {
        name: AppMenuEnum.UsageRules,
        title: t('appMenu.usageRules'),
        link: { name: ROUTES_NAME.USAGE_RULES },
        icon: 'usageRules',
        enabled: true,
        submenu: null,
        order: 13,
      },
      {
        name: AppMenuEnum.Login,
        title: t('appMenu.login'),
        link: null,
        icon: 'login',
        enabled:
          appStore.companyRowId ===
            import.meta.env.VITE_KLINIKUM_ERDING_COMPANY_ID &&
          currentUserIsKEDummy.value,
        submenu: null,
        order: 14,
      },
      {
        name: AppMenuEnum.CustomLink,
        title: '',
        link: null,
        href: '',
        icon: 'link-on-off',
        enabled: companiesList.value['BKG'] === currentCompanyId.value,
        submenu: null,
        order: 15,
      },
      {
        name: AppMenuEnum.CustomPage,
        title: '',
        link: null,
        href: '',
        icon: 'pages',
        enabled: companiesList.value['BKG'] === currentCompanyId.value,
        submenu: null,
        order: 16,
      },
    ] as AppMenuItems[];

    return customMenuItems.value.length
      ? mapWithCustomItems(data)
      : data.filter((n) => n.enabled);
  };

  const _getHeaderSubmenuItems = (): AppMenuItems[] => {
    const data = [
      ..._getMenuItems().slice(maxItemsInHeaderMenu.value - 1),
    ] as AppMenuItems[];
    return data.filter((n) => n.enabled);
  };

  const getHeaderMenu = (): AppMenuItems[] => {
    const { t } = useI18n();
    const items = _getMenuItems();

    if (items.length <= maxItemsInHeaderMenu.value) {
      return items;
    } else {
      const splicedItems = items.splice(0, maxItemsInHeaderMenu.value - 1);
      return [
        ...splicedItems,
        {
          name: AppMenuEnum.More,
          title: t('appMenu.more'),
          link: null,
          icon: 'more',
          enabled: true,
          submenu: _getHeaderSubmenuItems(),
          order: splicedItems.length,
        },
      ];
    }
  };

  const getFooterMenu = (): AppMenuItems[] => {
    const { t } = useI18n();
    const appStore = useAppStore();
    const isMDWidth: ComputedRef<boolean> = computed(() => appStore.isMDWidth);
    let data = _getMenuItems();

    if (!isMDWidth.value) {
      const slicedItems = data.slice(0, maxItemsInBottomMenu);
      data =
        data.length > maxItemsInBottomMenu
          ? ([
              ...slicedItems,
              {
                name: AppMenuEnum.More,
                title: t('appMenu.more'),
                link: null,
                icon: 'more',
                enabled: true,
                submenu: _getFooterAdditionalMenu(),
                order: slicedItems.length,
              },
            ] as AppMenuItems[])
          : data;
    } else {
      const slicedItems = data.slice(0, maxItemsInHeaderMenu.value);
      data =
        data.length > maxItemsInHeaderMenu.value
          ? ([
              ...slicedItems,
              {
                name: AppMenuEnum.More,
                title: t('appMenu.more'),
                link: null,
                icon: 'more',
                enabled: true,
                submenu: _getHeaderSubmenuItems(),
                order: slicedItems.length,
              },
            ] as AppMenuItems[])
          : data;
    }
    return data.filter((n) => n.enabled);
  };

  const _getFooterAdditionalMenu = (): AppMenuItems[] => {
    const data = [..._getMenuItems().slice(4)];
    return data.filter((n) => n.enabled);
  };

  const getRightMenu = (): AppMenuItems[] => {
    const { t } = useI18n();

    const currentUserId: ComputedRef<number> = computed(
      () => userStore.current?.id ?? 0
    );
    const currentUserRoleId: ComputedRef<number> = computed(
      () => userStore.current?.roleId ?? 0
    );

    const data = [
      {
        name: AppMenuEnum.Profile,
        title: t('account.title'),
        link: {
          name: ROUTES_NAME.USER_BY_ID,
          params: { id: currentUserId.value },
        },
        icon: 'person-circle',
        enabled: true,
        submenu: null,
        order: 0,
      },

      {
        name: AppMenuEnum.Settings,
        title: t('appMenu.settings'),
        link: { name: ROUTES_NAME.SETTINGS },
        icon: 'settings',
        enabled: true,
        submenu: null,
        order: 1,
      },

      {
        name: AppMenuEnum.Admin,
        title: t('appMenu.administration'),
        link: { name: ROUTES_NAME.ADMIN_DESIGN },
        icon: 'settings-2',
        enabled: currentUserRoleId.value >= UserRoleEnum.Moderator,
        submenu: null,
      },
    ] as AppMenuItems[];

    return data.filter((n) => n.enabled);
  };

  const isCampusEnabled = (): boolean => {
    const appStore = useAppStore();

    const companiesList: ComputedRef<{ [key: string]: string }> = computed(
      () => {
        if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS) {
          console.warn(
            'The env variable VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS is not defined'
          );
          return {};
        }

        try {
          return JSON.parse(
            import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS
          );
        } catch (error) {
          console.error(
            'Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS env variable:',
            error
          );
          return {};
        }
      }
    );

    const currentCompanyId: ComputedRef<string> = computed(
      () => appStore.companyRowId
    );

    if (Object.values(companiesList.value).includes(currentCompanyId.value))
      return false;

    return true;
  };

  const isQuickSearchEnabled = (): boolean => {
    const appStore = useAppStore();

    const companiesList: ComputedRef<{ [key: string]: string }> = computed(
      () => {
        if (!import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH) {
          console.warn(
            'The env variable VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH is not defined'
          );
          return {};
        }

        try {
          return JSON.parse(
            import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH
          );
        } catch (error) {
          console.error(
            'Error while parsing the VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH env variable:',
            error
          );
          return {};
        }
      }
    );

    const currentCompanyId: ComputedRef<string> = computed(
      () => appStore.companyRowId
    );

    if (Object.values(companiesList.value).includes(currentCompanyId.value))
      return false;

    return true;
  };

  const getCampusLink = (): string => {
    const companiesList: ComputedRef<{
      [key: string]: { id: string; url: string };
    }> = computed(() => {
      if (!import.meta.env.VITE_CAMPUS_LINK_COMPANIES_LIST) {
        console.warn(
          'The env variable VITE_CAMPUS_LINK_COMPANIES_LIST is not defined'
        );
        return {};
      }

      try {
        return JSON.parse(import.meta.env.VITE_CAMPUS_LINK_COMPANIES_LIST);
      } catch (error) {
        console.error(
          'Error while parsing the VITE_CAMPUS_LINK_COMPANIES_LIST env variable:',
          error
        );
        return {};
      }
    });

    const currentCompanyId: ComputedRef<string> = computed(
      () => appStore.companyRowId
    );

    for (const key in companiesList.value) {
      if (companiesList.value[key].id === currentCompanyId.value) {
        return companiesList.value[key].url;
      }
    }

    //NOTE: Default url
    return import.meta.env.VITE_CAMPUS_LINK;
  };

  const openCampusModal = async (): Promise<void> => {
    const currentCompanyId: ComputedRef<string> = computed(
      () => appStore.companyRowId
    );
    //NOTE: Hardcode for BLAUESKREUZ
    if (currentCompanyId.value === '0cb21e25-a5f3-4e30-8366-6c5787718a12') {
      Browser.open({ url: getCampusLink() });
    } else {
      await componentCampusModal();
    }
  };

  const checkForActive = (element: any, route: Route): boolean => {
    return (
      (!route.params?.id && element.link?.name === route.name) ||
      (!!route.params?.id &&
        element.link?.name === route.name &&
        element.link?.params.id === +route.params.id)
    );
  };

  const validateCustomLink = async (href: string): Promise<boolean> => {
    const rules = {
      href: {
        extentedUrl,
      },
    };
    const state = reactive<{ href: string }>({
      href: href,
    });
    const v$ = useVuelidate(rules, state);
    return await v$.value.$validate();
  };

  return {
    getHeaderMenu,
    getRightMenu,
    getFooterMenu,
    isCampusEnabled,
    isQuickSearchEnabled,
    companiesList,
    getCampusLink,
    openCampusModal,
    checkForActive,
    validateCustomLink,
  };
}
