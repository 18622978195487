import {
  DeletionUserKindEnum,
  type FeedTypeEnum,
  type IdeaTypeEnum,
  type UserRoleEnum,
} from '@/@enums';
import type {
  ResponseUserModel,
  ResponseErrorModel,
  ResponseUsersModel,
  ResponseRulesModel,
  ResponseNetworksModel,
  UserProfileUpdateModel,
  PhoneMobileVisibleUpdateModel,
  ResponseUserAvatarModel,
  ResponseSuccessModel,
  ResponseFileModel,
  ResponseInviteUserModel,
  ResponseShortUsersModel,
} from '@/@types';
import { $api, axios } from '@/services';

export class UserApiService {
  async list(): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get('/users/all');
  }
  async loadMore(
    url: string
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async autocomplete(
    text: string,
    canPostOnBehalf = false
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.post('/users/autocomplete', {
      searchText: text,
      canPostOnBehalf,
    });
  }
  async current(): Promise<ResponseUserModel | ResponseErrorModel> {
    return axios.get('/users/current');
  }
  async networks(): Promise<ResponseNetworksModel | ResponseErrorModel> {
    return axios.get('/users/networks');
  }
  async getUsageRules(): Promise<ResponseRulesModel | ResponseErrorModel> {
    return axios.get('/networks/usageRules');
  }
  async acceptRules(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/usageRules');
  }
  async follow(
    userId: number
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.post(`/users/follow/${userId}`);
  }
  async unFollow(
    userId: number
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.post(`/users/unfollow/${userId}`);
  }
  async followers(
    userId: number,
    search: string
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/followers/${userId}?search=${search}`);
  }
  async following(
    userId: number,
    search: string
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return search.length
      ? axios.get(`/users/following/${userId}?search=${search}`)
      : axios.get(`/users/following/${userId}`);
  }
  async getUserById(
    userId: number
  ): Promise<ResponseUserModel | ResponseErrorModel> {
    return axios.get(`/users/byId/${userId}`);
  }
  async userProfileUpdate(
    data: UserProfileUpdateModel | PhoneMobileVisibleUpdateModel
  ): Promise<ResponseUserModel | ResponseErrorModel> {
    return axios.post('/users/update', data);
  }
  async updateAvatar(
    image: File
  ): Promise<ResponseUserAvatarModel | ResponseErrorModel> {
    const response = await $api.file.upload(image);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post('/users/uploadAvatar', {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }
  async deleteAvatar(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete('/users/deleteAvatar');
  }
  async deleteCover(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete('/users/deleteCover');
  }
  async emailChange(
    email: string
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/emailChange', { email: email });
  }
  async emailConfirm(
    hash: string
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/emailConfirm', {
      hash: hash,
    });
  }
  async usersByGroup(
    id: number
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/byGroup/${id}`);
  }
  async usersByGroupAutocomplete(
    id: number,
    text: string
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/byGroup/${id}?query=${text}`);
  }
  async updateCover(
    image: File
  ): Promise<ResponseUserAvatarModel | ResponseErrorModel> {
    const response = await $api.file.upload(image);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post('/users/uploadCover', {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }
  async accountDelete(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete('/users/selfDelete');
  }
  async updateDefaultFeedType(
    feedType: FeedTypeEnum
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/updateDefaultFeedType', { feedType });
  }
  async inviteUser(
    userEmails: string[]
  ): Promise<ResponseInviteUserModel | ResponseErrorModel> {
    return axios.post(`/users/invite`, { emails: userEmails });
  }
  async updateDefaultIdeaType(
    ideaType: IdeaTypeEnum
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/updateDefaultIdeaType', { ideaType });
  }
  async getPostViewers(
    postId: number
  ): Promise<ResponseShortUsersModel | ResponseErrorModel> {
    return axios.get(`/users/showPostViewers/${postId}`);
  }
  async updateUserRole(
    userId: number,
    roleId: UserRoleEnum
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/users/update/${userId}/role`, { targetRoleId: roleId });
  }
  async deactivateUser(
    userId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/users/deactivate/${userId}`);
  }
  async activateUser(
    userId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/users/activate/${userId}`);
  }
  async blockUser(
    userId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/users/block/${userId}`);
  }
  async unblockUser(
    userId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/users/unblock/${userId}`);
  }
  async deleteUser(
    userId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(
      `/users/delete/${DeletionUserKindEnum.Anonymize}/${userId}`
    );
  }
  async allForAdmin(
    IncludeInactive: boolean,
    search: string
  ): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(
      `/users/allForAdmin?includeInactive=${IncludeInactive}&search=${search}&sortDirection=Asc&usersSort=Name&pageSize=50`
    );
  }
}
