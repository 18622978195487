<template>
  <div class="images-grid">
    <app-grid
      v-if="!isCommentFlag && imagesGrid !== null"
      :style="{ 'max-width': maxGridWidth ? `${maxGridWidth}px` : '100%' }"
    >
      <app-row
        v-for="row in imagesGrid"
        :key="row.id"
        class="post-row"
        :class="{ 'has-bottom-margin': row.hasBottomMargin }"
      >
        <template v-for="item in row.data" :key="item.image?.key">
          <app-col
            :size="item.colSize"
            :class="{ 'has-right-padding': item.hasRightPadding }"
            :style="{
              'min-height': item.colMinHeight + 'px',
              'max-height': item.colMaxHeight + 'px',
            }"
          >
            <div v-if="item?.isMultiple === true" class="multiple-item">
              <div
                class="placeholder"
                @click.stop="viewImages(item.image?.key)"
              >
                <span
                  :style="{
                    'font-size': maxGridWidth
                      ? `${maxGridWidth / 12}px`
                      : '3rem',
                  }"
                  >{{ `+` + (images.length - 5) }}</span
                >
              </div>
              <!-- Media Image #1 -->

              <media-image
                v-if="item.image !== null"
                :key="item.image.key"
                :name="item.image.name"
                :internal="item.image.key"
                :image="item.image.image"
                :url="item.image.image?.url || item.image.apiUrl"
                center
                :with-text="true"
              />
            </div>
            <!-- Media Image #2 -->

            <media-image
              v-else-if="item.image !== null"
              :key="item.image.key"
              :name="item.image.name"
              :internal="item.image.key"
              :image="item.image.image"
              :url="item.image.image?.url || item.image.apiUrl"
              :with-text="true"
              center
              @onView="viewImages(item.image?.key)"
            />

            <template v-if="item.childRows !== null">
              <app-row
                v-for="childRow in item.childRows"
                :key="childRow.id"
                :class="{ 'has-bottom-margin': childRow.hasBottomMargin }"
              >
                <template
                  v-for="childItem in childRow.data"
                  :key="childItem.image?.key"
                >
                  <app-col
                    :size="childItem.colSize"
                    :class="{
                      'has-bottom-padding': childItem.hasBottomPadding,
                    }"
                    :style="{
                      'min-height': childItem.colMinHeight + 'px',
                      'max-height': childItem.colMaxHeight + 'px',
                    }"
                  >
                    <!-- Media Image #3 -->

                    <media-image
                      v-if="childItem.image !== null"
                      :key="childItem.image.key"
                      :name="childItem.image.name"
                      :internal="childItem.image.key"
                      :image="childItem.image.image"
                      :url="
                        childItem.image.image?.url || childItem.image.apiUrl
                      "
                      :with-text="true"
                      center
                      @onView="viewImages(childItem.image?.key)"
                    />
                  </app-col>
                </template>
              </app-row>
            </template>
          </app-col>
        </template>
      </app-row>
    </app-grid>

    <app-grid v-if="isCommentFlag">
      <app-row class="comment-row">
        <app-col
          v-for="item in commentImages"
          :key="item.key"
          size-sm="3"
          size-xs="6"
          class="has-right-padding has-bottom-padding comment"
        >
          <!-- Media Image #4 -->

          <media-image
            :key="item.key"
            :name="item.name"
            :internal="item.key"
            :image="item.image"
            :with-text="true"
            center
            @onView="viewImages(item.key)"
          />
        </app-col>
        <app-col
          v-if="images.length > commentImagesMaxCount"
          size-sm="3"
          size-xs="6"
          class="has-right-padding has-bottom-padding comment"
        >
          <div class="multiple-item">
            <div
              class="placeholder"
              @click.stop="viewImages(images[commentImagesMaxCount - 1].key)"
            >
              <span>{{ `+` + (images.length - commentImagesMaxCount) }}</span>
            </div>
            <!-- Media Image #5 -->

            <media-image
              v-if="images[commentImagesMaxCount - 1].image !== null"
              :key="images[commentImagesMaxCount - 1].key"
              :name="images[commentImagesMaxCount - 1].name"
              :internal="images[commentImagesMaxCount - 1].key"
              :image="images[commentImagesMaxCount - 1].image"
              :with-text="true"
              center
            />
          </div>
        </app-col>
      </app-row>
    </app-grid>
  </div>
</template>

<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core';
import { ref, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';

import 'swiper/css';
import type {
  FileModel,
  ImageViewerItemModel,
  PostImagesRowModel,
} from '@/@types';
import { AppGrid, AppRow, AppCol, MediaImage } from '@/components';
import { componentImagesViewer } from '@/helpers';

const props = defineProps({
  images: {
    type: Object as PropType<FileModel[]>,
    required: true,
  },
  baseGridHeight: {
    type: Number,
    required: true,
  },
  baseMobileGridHeight: {
    type: Number,
    required: true,
  },
  isCommentFlag: {
    type: Boolean,
    default: () => false,
  },
  maxGridWidth: {
    type: Number,
    default: () => null,
  },
  isMessageFlag: {
    type: Boolean,
    default: () => false,
  },
});

const { width: innerWidth } = useWindowSize();

const baseGridHeight = ref<number>(props.baseGridHeight);
const baseMobileGridHeight = ref<number>(props.baseMobileGridHeight);
const commentImagesMaxCount = ref<number>(4);

const largeColHeight: ComputedRef<number> = computed(() =>
  innerWidth.value < 900 ? baseMobileGridHeight.value : baseGridHeight.value
);
const mediumColHeight: ComputedRef<number> = computed(() =>
  innerWidth.value < 900
    ? baseMobileGridHeight.value / 2
    : baseGridHeight.value / 2
);
const smallColHeight: ComputedRef<number> = computed(() =>
  innerWidth.value < 900
    ? baseMobileGridHeight.value / 3
    : baseGridHeight.value / 3
);

const commentImages: ComputedRef<FileModel[]> = computed(() =>
  props.images.length > commentImagesMaxCount.value
    ? props.images.slice(0, commentImagesMaxCount.value - 1)
    : props.images
);

const imagesGrid: ComputedRef<PostImagesRowModel[] | null> = computed(() => {
  if (props.images.length > 5) {
    return [
      {
        id: 0,
        hasBottomMargin: true,
        data: [
          {
            colSize: '6',
            //TODO: LQIP - Reactor from https://gitlab.united-grid.com/intra/core/-/issues/533 + https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1220
            colMinHeight: mediumColHeight.value,
            colMaxHeight: mediumColHeight.value,
            image: props.images[0],
            childRows: null,
            hasRightPadding: true,
            hasBottomPadding: false,
          },
          {
            colSize: '6',
            //TODO: LQIP - Reactor from https://gitlab.united-grid.com/intra/core/-/issues/533 + https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1220
            colMinHeight: mediumColHeight.value,
            colMaxHeight: mediumColHeight.value,
            image: props.images[1],
            childRows: null,
            hasRightPadding: false,
            hasBottomPadding: false,
          },
        ],
      },
      {
        id: 1,
        hasBottomMargin: false,
        data: [
          {
            colSize: '4',
            //TODO: LQIP - Reactor from https://gitlab.united-grid.com/intra/core/-/issues/533 + https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1220
            colMinHeight: smallColHeight.value / 1.5,
            colMaxHeight: smallColHeight.value / 1.5,
            image: props.images[2],
            childRows: null,
            hasRightPadding: true,
            hasBottomPadding: false,
          },
          {
            colSize: '4',
            //TODO: LQIP - Reactor from https://gitlab.united-grid.com/intra/core/-/issues/533 + https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1220
            colMinHeight: smallColHeight.value / 1.5,
            colMaxHeight: smallColHeight.value / 1.5,
            image: props.images[3],
            childRows: null,
            hasRightPadding: true,
            hasBottomPadding: false,
          },
          {
            colSize: '4',
            //TODO: LQIP - Reactor from https://gitlab.united-grid.com/intra/core/-/issues/533 + https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1220
            colMinHeight: smallColHeight.value / 1.5,
            colMaxHeight: smallColHeight.value / 1.5,
            image: props.images[4],
            childRows: null,
            hasRightPadding: false,
            hasBottomPadding: false,
            isMultiple: true,
          },
        ],
      },
    ] as PostImagesRowModel[];
  } else {
    switch (props.images.length) {
      case 1:
        return [
          {
            id: 0,
            hasBottomMargin: false,
            data: [
              {
                colSize: '12',
                colMinHeight: calculateCustomColHeight(
                  props.images[0].image?.height
                ),
                colMaxHeight: calculateCustomColHeight(
                  props.images[0].image?.height
                ),
                image: props.images[0],
                childRows: null,
                hasRightPadding: false,
                hasBottomPadding: false,
              },
            ],
          },
        ] as PostImagesRowModel[];

      case 2:
        return [
          {
            id: 0,
            hasBottomMargin: false,
            data: [
              {
                colSize: '6',

                colMinHeight: largeColHeight.value,
                colMaxHeight: largeColHeight.value,
                image: props.images[0],
                childRows: null,
                hasRightPadding: true,
                hasBottomPadding: false,
              },
              {
                colSize: '6',

                colMinHeight: largeColHeight.value,
                colMaxHeight: largeColHeight.value,
                image: props.images[1],
                childRows: null,
                hasRightPadding: false,
                hasBottomPadding: false,
              },
            ],
          },
        ] as PostImagesRowModel[];

      case 3:
        return [
          {
            id: 0,
            hasBottomMargin: false,
            data: [
              {
                colSize: '7',

                colMinHeight: largeColHeight.value,
                colMaxHeight: largeColHeight.value,
                image: props.images[0],
                childRows: null,
                hasRightPadding: true,
                hasBottomPadding: false,
              },
              {
                colSize: '5',

                colMinHeight: largeColHeight.value,
                colMaxHeight: largeColHeight.value,
                image: null,
                childRows: [
                  {
                    id: 1,
                    hasBottomMargin: false,
                    data: [
                      {
                        colSize: '12',

                        colMinHeight: mediumColHeight.value,
                        colMaxHeight: mediumColHeight.value,
                        image: props.images[1],
                        childRows: null,
                        hasRightPadding: false,
                        hasBottomPadding: true,
                      },
                      {
                        colSize: '12',

                        colMinHeight: mediumColHeight.value,
                        colMaxHeight: mediumColHeight.value,
                        image: props.images[2],
                        childRows: null,
                        hasRightPadding: false,
                        hasBottomPadding: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ] as PostImagesRowModel[];

      case 4:
        return [
          {
            id: 0,
            hasBottomMargin: false,
            data: [
              {
                colSize: '7',

                colMinHeight: largeColHeight.value,
                colMaxHeight: largeColHeight.value,
                image: props.images[0],
                childRows: null,
                hasRightPadding: true,
                hasBottomPadding: false,
              },
              {
                colSize: '5',

                colMinHeight: largeColHeight.value,
                colMaxHeight: largeColHeight.value,
                image: null,
                childRows: [
                  {
                    id: 1,
                    data: [
                      {
                        colSize: '12',

                        colMinHeight: smallColHeight.value,
                        colMaxHeight: smallColHeight.value,
                        image: props.images[1],
                        childRows: null,
                        hasRightPadding: false,
                        hasBottomPadding: true,
                      },
                      {
                        colSize: '12',

                        colMinHeight: smallColHeight.value,
                        colMaxHeight: smallColHeight.value,
                        image: props.images[2],
                        childRows: null,
                        hasRightPadding: false,
                        hasBottomPadding: true,
                      },
                      {
                        colSize: '12',

                        colMinHeight: smallColHeight.value,
                        colMaxHeight: smallColHeight.value,
                        image: props.images[3],
                        childRows: null,
                        hasRightPadding: false,
                        hasBottomPadding: false,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ] as PostImagesRowModel[];

      case 5:
        return [
          {
            id: 0,
            hasBottomMargin: true,
            data: [
              {
                colSize: '6',

                colMinHeight: mediumColHeight.value,
                colMaxHeight: mediumColHeight.value,
                image: props.images[0],
                childRows: null,
                hasRightPadding: true,
                hasBottomPadding: false,
              },
              {
                colSize: '6',

                colMinHeight: mediumColHeight.value,
                colMaxHeight: mediumColHeight.value,
                image: props.images[1],
                childRows: null,
                hasRightPadding: false,
                hasBottomPadding: false,
              },
            ],
          },
          {
            id: 1,
            hasBottomMargin: false,
            data: [
              {
                colSize: '4',

                colMinHeight: smallColHeight.value / 1.5,
                colMaxHeight: smallColHeight.value / 1.5,
                image: props.images[2],
                childRows: null,
                hasRightPadding: true,
                hasBottomPadding: false,
              },
              {
                colSize: '4',

                colMinHeight: smallColHeight.value / 1.5,
                colMaxHeight: smallColHeight.value / 1.5,
                image: props.images[3],
                childRows: null,
                hasRightPadding: true,
                hasBottomPadding: false,
              },
              {
                colSize: '4',

                colMinHeight: smallColHeight.value / 1.5,
                colMaxHeight: smallColHeight.value / 1.5,
                image: props.images[4],
                childRows: null,
                hasRightPadding: false,
                hasBottomPadding: false,
              },
            ],
          },
        ] as PostImagesRowModel[];
    }
  }
  return null;
});

const viewImages = async (imageKey: string | undefined) => {
  if (imageKey !== undefined) {
    const index = props.images.findIndex((n) => n.key === imageKey);
    const items: ImageViewerItemModel[] = props.images.map((n) => ({
      file: n ?? null,
      text: '',
    }));
    await componentImagesViewer(index, items);
  }
};

const calculateCustomColHeight = (
  colHeight: number | null | undefined
): number => {
  if (!colHeight) {
    return largeColHeight.value;
  }
  // console.log(
  //   '≥≥≥AppImagesGrid - calculateCustomColHeight - largeColHeight: ',
  //   largeColHeight.value
  // ); // !DEBUG
  // console.log(
  //   '≥≥≥AppImagesGrid - calculateCustomColHeight - colHeight: ',
  //   colHeight
  // ); // !DEBUG
  return colHeight > largeColHeight.value ? largeColHeight.value : colHeight;
};
</script>

<style scoped lang="scss">
.images-grid {
  overflow: hidden;
}
.app-grid {
  --ion-grid-columns: 12;
}
.app-col {
  --ion-grid-column-padding: 0;
  min-height: 4rem;
}
.app-col.comment {
  height: 7rem;
}
.app-row.post-row {
  justify-content: center;
}
.has-right-padding {
  padding-right: 0.2rem;
}
.has-bottom-padding {
  padding-bottom: 0.2rem;
}
.has-bottom-margin {
  margin-bottom: 0.2rem;
}
.has-border {
  border: 1px solid red;
  border-radius: app-radius(md);
}
.multiple-item {
  position: relative;
  border-radius: app-radius(md);
  width: 100%;
  height: 100%;
}
.multiple-item:hover {
  cursor: pointer;
}
.multiple-item .placeholder {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(var(--ion-color-dark-rgb), 0.3);
  border-radius: app-radius(md);
}
.multiple-item:hover .placeholder {
  cursor: pointer;
  opacity: 0.7;
}
.multiple-item .placeholder span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: rgba(var(--ion-color-dark-contrast-rgb), 0.7);
  word-break: keep-all;
}
</style>
