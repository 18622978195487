<template>
  <div class="description">
    <div class="description-header">
      <ion-label>{{ $t('description.title') }}</ion-label>
    </div>
    <div class="description-editor">
      <div
        v-if="(description === null || description?.length === 0) && !isEditing"
        :class="['plug', { 'post-creation': taskId === 0 }]"
        @click.stop="isEditing = true"
      >
        {{ $t('description.detailed') }}
      </div>
      <div
        v-if="description && description?.length > 0 && !isEditing"
        :class="['text', { 'post-creation': taskId === 0 }]"
        v-html="descriptionToShow"
      />
      <template v-if="isEditing">
        <app-rich-text-editor
          :value="description"
          :height="isPost ? 500 : 200"
          :group-id="groupId"
          @update:value="onChangeRichText"
        />
      </template>
      <div v-if="withControls" class="description--button-container">
        <ion-button
          v-if="!isEditing && descriptionToShow.length > 0"
          size="small"
          mode="md"
          fill="solid"
          class="edit-btn"
          @click.stop="isEditing = !isEditing"
        >
          <span>{{ $t('description.edit') }}</span>
        </ion-button>
        <ion-button
          v-if="isEditing"
          mode="md"
          fill="solid"
          color="success"
          @click.stop="updateTaskDescription()"
        >
          {{ $t('save') }}
        </ion-button>
        <ion-button
          v-if="isEditing"
          mode="md"
          fill="solid"
          class="cancel-btn"
          @click.stop="isEditing = false"
        >
          {{ $t('cancel') }}
        </ion-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonButton, IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, watch, onMounted, ref } from 'vue';

import { AppRichTextEditor } from '@/components';
import { useRichText } from '@/helpers';
import { useProjectsStore } from '@/store';

// Props
const props = defineProps({
  taskId: {
    type: Number,
    default: 0,
  },
  description: {
    type: null as unknown as PropType<string | null>,
    required: true,
  },
  isPost: {
    type: Boolean,
    required: true,
  },
  withControls: {
    type: Boolean,
    default: true,
  },
});

// Store
const projectsStore = useProjectsStore();

// Helpers
const richTextHelper = useRichText();

// Refs
const isEditing = ref<boolean>(props.withControls ? false : true);
const description = ref<string>(props.description ? props.description : '');
const descriptionToShow = ref<string>('');
const descriptionProp: ComputedRef<string> = computed(() =>
  props.description ? props.description : ''
);

// Computed
const groupId: ComputedRef<number | null> = computed(() =>
  projectsStore.getGroupIdByTaskId(props.taskId)
);

// Actions
const onChangeRichText = async (val: string) => {
  description.value = val;

  if (!props.withControls) await updateTaskDescription();
};

const updateTaskDescription = async () => {
  if (props.taskId > 0) {
    await projectsStore.updateTaskDescription(
      props.taskId,
      richTextHelper.preSubmit(description.value)
    );
  } else {
    emit('onDescriptionChange', richTextHelper.preSubmit(description.value));
  }

  descriptionToShow.value = await richTextHelper.preprocessBody(
    description.value
  );

  if (props.withControls) isEditing.value = false;
};

const getDescription = async () => {
  description.value = props.description
    ? await richTextHelper.preprocessBody(props.description)
    : '';
  descriptionToShow.value = description.value;
};

// Watchers
watch(descriptionProp, async () => {
  await getDescription();
});

// Lifecycle
onMounted(async () => {
  await getDescription();
});

// Emits
const emit = defineEmits(['onDescriptionChange']);
</script>

<style scoped lang="scss">
.description {
  &-header {
    margin-top: 2rem;
    margin-bottom: app-padding(md);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--button-container {
    margin-top: app-padding(md);
    .edit-btn,
    .cancel-btn {
      --background: var(--ion-color-custom-element-lighter);
      --color: var(--ion-color-medium);
    }
  }
}
.description-editor {
  color: var(--ion-color-dark);

  .plug {
    box-sizing: border-box;
    margin-top: app-padding(md);
    width: 100%;
    background: var(--ion-color-light);
    color: var(--ion-color-medium);
    height: 4rem;
    padding: app-padding(md);
    border-radius: app-radius(md);
    font-size: 0.9rem;
    &.post-creation {
      background: var(--ion-color-custom-element-lighter);
      color: var(--ion-color-medium);
    }
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }

  .text {
    &.post-creation {
      margin-top: app-padding(md);
      border: 2px solid var(--ion-color-custom-element-lighter);
      border-radius: app-radius(md);
      padding: app-padding(md);
    }
  }

  ion-button {
    margin: 0;
    margin-top: app-padding(md);
    --box-shadow: none;
    text-transform: unset;
    --border-radius: #{app-radius(md)};
  }

  ion-button:not(:first-child) {
    margin-left: app-padding(md);
  }
}
</style>
