<template>
  <div class="status">
    <ion-label :class="{ danger: props.isClosed && coloredClosed }">{{
      text
    }}</ion-label>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel } from '@ionic/vue';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { useI18n } from '@/i18n';

const props = defineProps({
  isClosed: {
    type: Boolean,
    required: true,
  },
  coloredClosed: {
    type: Boolean,
    default: false,
  },
});
const { t } = useI18n();
const text: ComputedRef<string> = computed(() => {
  return props.isClosed
    ? t('taskManagement.status.closed')
    : t('taskManagement.status.opened');
});
</script>
<style scoped lang="scss">
.status ion-label.danger {
  color: var(--ion-color-danger);
}
</style>
