<template>
  <ion-avatar>
    <ion-skeleton-text
      v-if="isImageAvatar === false && !isTextAvatar"
      :animated="true"
    />

    <template v-if="avatar">
      <img
        :src="avatar"
        :title="withTitle ? fio : ''"
        :alt="isImageAvatar ? fio : ''"
      />
    </template>

    <template v-else-if="isTextAvatar">
      <div
        class="u-text-avatar"
        :class="{ 'user-online': isOnline, 'dark-text': darkText }"
        :style="{ background: randomColor }"
      >
        {{ letters }}
      </div>
    </template>

    <div v-if="isOnline" class="online-status" />
  </ion-avatar>
</template>

<script lang="ts" setup>
import { IonAvatar, IonSkeletonText } from '@ionic/vue';
import type { ComputedRef } from 'vue';
import { computed, ref, watch, onMounted } from 'vue';

import { ColorGenerator, getLetters, filesHybrid } from '@/helpers';
import { useAppStore, useUserStore } from '@/store';

// Props
const props = defineProps({
  userId: {
    type: Number,
    default: () => 0,
  },
  fio: {
    type: String,
    default: () => '',
  },
  url: {
    type: String,
    default: () => '',
  },
  withOnlineStatus: {
    type: Boolean,
    default: () => true,
  },
  withTitle: {
    type: Boolean,
    default: () => true,
  },
  darkText: {
    type: Boolean,
    default: () => false,
  },
});

// Store
const appStore = useAppStore();
const userStore = useUserStore();

// Refs
const avatar = ref<string>('');
const isImageAvatar = ref<boolean>(false);
const isTextAvatar = ref<boolean>(false);

// Computed
const image: ComputedRef<string | null> = computed(() => props.url);
const letters: ComputedRef<string> = computed(() => getLetters(props.fio));
const randomColor: ComputedRef<string> = computed(() => {
  return ColorGenerator.getColor(letters.value);
});
const isOnline: ComputedRef<boolean> = computed(
  () =>
    appStore.onlineUsers.includes(props.userId) &&
    props.withOnlineStatus &&
    props.userId !== userStore.current?.id
);

// Actions
const updateImage = async (): Promise<void> => {
  if (!props.url) {
    avatar.value = '';
    isTextAvatar.value = true;
    return;
  }

  try {
    const file = await filesHybrid.readFile(props.url, undefined, false);
    if (!file) {
      avatar.value = '';
      isTextAvatar.value = true;
      console.error(`Failed to get user avatar from url: ${props.url}`);
      return;
    }

    avatar.value = file;
    isImageAvatar.value = true;
  } catch (error) {
    avatar.value = '';
    isTextAvatar.value = true;
    console.error(`Failed to get user avatar from url: ${props.url}`, error);
  }
};

// Watchers
watch(image, () => {
  updateImage();
});

// Lifecycle
onMounted(async () => {
  await updateImage();
});
</script>

<style scoped lang="scss">
ion-avatar {
  position: relative;
  .u-text-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: var(--ion-color-dark-contrast);
  }
  .u-text-avatar.dark-text {
    color: var(--ion-color-warning-contrast);
  }
  ion-skeleton-text {
    position: absolute;
    z-index: 1;
  }
  .online-status {
    display: inline-block;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    background-color: #4caf50;
    position: absolute;
    right: -2px;
    bottom: 0;
    z-index: 3;
    border: 2px solid var(--ion-color-light-background-contrast);
  }
}
</style>
