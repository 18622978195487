<template>
  <ion-avatar
    :style="
      customBackgroundColor !== null
        ? `background-color:${customBackgroundColor}`
        : ''
    "
    :class="{ 'network-logo': imageType === AppImageType.NetworkLogo && image }"
  >
    <template v-if="image">
      <img :src="image" :title="text" :alt="text" />
    </template>
    <template v-else>
      <!-- TODO: replace ion-img with icons-provider -->
      <img
        v-if="type === 0"
        src="/images/network.svg"
        :title="text"
        :alt="text"
      />
      <!-- TODO: waiting for network_component from designer -->
      <!-- <icons-provider
        :name="'network'"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      /> -->
      <div v-else class="u-text-image" :style="{ background: randomColor }">
        {{ letters }}
      </div>
    </template>
    <ion-icon
      v-if="withStatus && networkStatus"
      class="avatar-icon"
      :icon="networkStatus"
    />

    <ion-badge
      v-if="imageType === AppImageType.NetworkLogo && notificationsCount"
      class="notifications"
      mode="ios"
    >
      {{ notificationsCount }}
    </ion-badge>
  </ion-avatar>
</template>

<script lang="ts" setup>
import { IonAvatar, IonIcon, IonBadge } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { watch, computed, ref } from 'vue';

import { AppImageType } from '@/@enums';
import type { ResponseErrorModel } from '@/@types';
import { ColorGenerator, getLetters, isBlob } from '@/helpers';
//TODO: uncomment when icons-provider will be ready
// import IconsProvider;
import { $api } from '@/services';

const props = defineProps({
  type: {
    type: Number,
    required: true,
    default: () => 0,
  },
  text: {
    type: String,
    required: true,
    default: () => '',
  },
  url: {
    type: null as unknown as PropType<string | null>,
    required: true,
    validator: (v: any) => typeof v === 'string' || v === null,
    default: () => null,
  },
  withStatus: {
    type: Boolean,
    default: () => false,
  },
  networkStatus: {
    type: String,
    default: () => '',
  },
  imageType: {
    type: Number as PropType<AppImageType>,
    default: () => undefined,
  },
  notificationsCount: {
    type: null as unknown as PropType<string | number | null>,
    default: () => null,
  },
});

const image = ref('');
const url: ComputedRef<string | null> = computed(() => props.url);

const updateImage = () => {
  if (props.url) {
    const urlCreator = window.URL || window.webkitURL;
    $api.file.media(props.url).then((file: Blob | ResponseErrorModel) => {
      if (isBlob(file)) {
        if ((file as Blob).type.startsWith('image')) {
          image.value = urlCreator.createObjectURL(file as Blob);
          setImageCustomBackground(image.value);
        }
      }
    });
  } else {
    image.value = '';
    customBackgroundColor.value = null;
  }
};

// Устанавливаем цвет для заполнения незанятого пространства исходя из крайней точки логотипа (0, 0)
const customBackgroundColor = ref<string | null>(null);
const setImageCustomBackground = (path: any) => {
  const img = new Image();
  img.src = path;
  const canvas = document.createElement('canvas');
  canvas.width = 1;
  canvas.height = 1;
  const context = canvas.getContext('2d');
  img.onload = () => {
    if (context !== null) {
      context.drawImage(img, 0, 0);
      const pixelData = context.getImageData(0, 0, 1, 1).data;
      const color = `rgb(${pixelData.slice(0, 3).join(',')})`;
      if (color === `rgb(0,0,0)`) {
        customBackgroundColor.value = 'rgb(255,255,255)';
      } else {
        customBackgroundColor.value = color;
      }
    }
  };
};

const letters: ComputedRef<string> = computed(() => getLetters(props.text));

const randomColor: ComputedRef<string> = computed(() => {
  return ColorGenerator.getColor(letters.value);
});

watch(url, () => {
  updateImage();
});

updateImage();
</script>

<style lang="scss" scoped>
.u-text-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: var(--ion-color-dark-contrast);
}
ion-avatar {
  position: relative;
}
ion-avatar.network-logo {
  padding: 6px;
}
ion-avatar.network-logo img {
  object-fit: contain;
}
.avatar-icon {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  bottom: -4px;
  z-index: 3;
  padding: 2px;
  background-color: rgba(var(--ion-color-light-rgb-custom), 1);
  border: 2px solid var(--ion-color-light-background-contrast);
}
ion-icon.avatar-icon {
  color: var(--ion-color-warning);
}
ion-badge.notifications {
  position: absolute;
  right: -3px;
  top: 0;
  font-size: 0.7rem;
  --padding-top: 2px;
  --padding-end: 4px;
  --padding-bottom: 2px;
  --padding-start: 4px;
  --background: var(--ion-color-danger);
  -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
}
</style>
