import type {
  ValidationRuleWithoutParams,
  ValidationRuleWithParams,
} from '@vuelidate/core';
import * as validators from '@vuelidate/validators';

import { useI18n } from '@/i18n';

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const { t } = useI18n();

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t });

const numbers$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /\d/.test(value);
  },
};
export const numbers = withI18nMessage(numbers$);

const upperCase$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /[A-Z]/.test(value);
  },
};
export const upperCase = withI18nMessage(upperCase$);

const lowerCase$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /[a-z]/.test(value);
  },
};
export const lowerCase = withI18nMessage(lowerCase$);

const specials$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return /[#?!@$%^&*-]/.test(value);
  },
};
export const specials = withI18nMessage(specials$);

let _uniqueDetails: string | null = null;
/**
 * Validation rule that checks if a string contains only unique characters.
 *
 * This rule ensures that the input string is not empty and that no character
 * repeats within the string. The validation is performed using a regular
 * expression which detects any repeated characters.
 *
 * @type {ValidationRuleWithoutParams<string>}
 * @property {Function} $validator - The validator function that checks if the
 * string contains unique characters.
 * @param {string} value - The string to be validated.
 * @returns {boolean} - Returns `true` if the string contains only unique
 * characters, otherwise returns `false`.
 *
 * @example
 * unique$.$validator('abcdefg'); // true
 * unique$.$validator('abcadefg'); // false (character 'a' is repeated)
 */
const unique$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    const match = value.match(/(.).*\1/);
    if (match) {
      _uniqueDetails = match[1];
      return false;
    }
    _uniqueDetails = null;
    return true;
  },
};

export const unique = withI18nMessage(unique$);

/**
 * Gets the details of the unique validation, specifically the repeated character if any.
 *
 * @returns {string | null} - The repeated character if found, otherwise null.
 */
export const getUniqueDetails = (): string | null => _uniqueDetails;

const easyCombinations: string[] = [
  '1234',
  '12345',
  '123456',
  '1234567',
  '12345678',
  '123456789',
  '1234567890',
  'qwer',
  'qwert',
  'qwerty',
  'qwertyu',
  'qwertyui',
  'qwertyuio',
  'qwertyuiop',
  'asdf',
  'asdfg',
  'asdfgh',
  'asdfghj',
  'asdfghjk',
  'asdfghjkl',
  'zxcv',
  'zxcvb',
  'zxcvbn',
  'zxcvbnm',
  'йцук',
  'йцуке',
  'йцукен',
  'йцукенг',
  'йцукенгш',
  'йцукенгшщ',
  'йцукенгшщз',
  'йцукенгшщзх',
  'фыва',
  'фывап',
  'фывапр',
  'фывапро',
  'фывапрол',
  'фывапролд',
  'фывапролдж',
  'фывапролджэ',
  'ячсм',
  'ячсми',
  'ячсмит',
  'ячсмить',
  'ячсмитьб',
  'ячсмитьбю',
  'эвм',
  'лвс',
  'user',
  'пароль',
  'привет',
  'gfhjkm' /*"пароль" в англ. раслкадке*/,
  'ghbdtn' /*"привет" в англ. раслкадке*/,
  'password',
  'hello',
];

const notSimple$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    return value.length > 0 && !easyCombinations.includes(value);
  },
};
export const notSimple = withI18nMessage(notSimple$);

const minLength$: (min: number) => ValidationRuleWithParams<{ min: number }> = (
  min: number
) => {
  return {
    $validator(value: string): boolean {
      return value.length >= min;
    },
    $message: ({ $params }) =>
      `This field should be at least ${$params.min} long`,
    $params: {
      min,
      type: 'minLength',
    },
  };
};

const fixedLength$: (
  val: number
) => ValidationRuleWithParams<{ val: number }> = (val: number) => {
  return {
    $validator(value: string): boolean {
      return value.length === val;
    },
    $message: ({ $params }) =>
      /* `This field should be at least ${$params.val} long`, */ /* t('validations.fixedLength', {n: $params.val}), */
      `Value must be ${$params.val} characters long`,
    $params: {
      val,
      type: 'fixedLength',
    },
  };
};

const minValue$: (min: number) => ValidationRuleWithParams<{ min: number }> = (
  min: number
) => {
  return {
    $validator(value): boolean {
      return value >= min;
    },
    $message: ({ $params }) => `Value should be at least ${$params.min} `,
    $params: {
      min,
      type: 'minValue',
    },
  };
};

const maxValue$: (max: number) => ValidationRuleWithParams<{ max: number }> = (
  max: number
) => {
  return {
    $validator(value): boolean {
      return value <= max;
    },
    $message: ({ $params }) => `Value should not exceed ${$params.max} `,
    $params: {
      max,
      type: 'maxValue',
    },
  };
};

const confirm$: (
  confirm: string
) => ValidationRuleWithParams<{ confirm: string }> = (confirm: string) => {
  return {
    $validator(value: string): boolean {
      return value.length > 0 && value === confirm;
    },
    $message: () => 'The password and confirmation password do not match.',
    $params: {
      confirm,
      type: 'confirm',
    },
  };
};

export const confirm = withI18nMessage(confirm$, {
  withArguments: true,
});

const isEmailOrPhone$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    if (
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) === true
    ) {
      return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
    } else if (/^\+?\d{11,14}$/.test(value) === true) {
      return /^\+?\d{11,14}$/.test(value);
    }
    return false;
  },
};

const isLink$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    const linkRegex =
      /^https?:\/\/[-A-Za-z0-9+&@#/%?=~|!:,.;]*[-A-Za-z0-9+&@#/%=~|]/;

    return linkRegex.test(value);
  },
};

const authLink$: ValidationRuleWithoutParams<string> = {
  $validator(value: string): boolean {
    const linkRegex =
      /^https?:\/\/([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\/net_home$/;

    return linkRegex.test(value);
  },
};

export const isEmailOrPhone = withI18nMessage(isEmailOrPhone$);

export const minLength = withI18nMessage(minLength$, {
  withArguments: true,
});
export const required = withI18nMessage(validators.required);
export const email = withI18nMessage(validators.email);
export const url = withI18nMessage(validators.url);
export const extentedUrl = withI18nMessage(isLink$);
export const authUrl = withI18nMessage(authLink$);
export const maxValue = withI18nMessage(maxValue$, {
  withArguments: true,
});
export const minValue = withI18nMessage(minValue$, {
  withArguments: true,
});
export const fixedLength = withI18nMessage(fixedLength$, {
  withArguments: true,
});
