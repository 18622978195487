import type {
  ResponseErrorModel,
  ResponseAnnouncementModel,
  ResponseAnnouncementCreateModel,
  RequestAnnouncementCreateModel,
  ResponseSuccessModel,
} from '@/@types';
import axios from '@/services/axios';

export class AnnouncementApiService {
  async getAnnouncementsAll(): Promise<
    ResponseAnnouncementModel | ResponseErrorModel
  > {
    return axios.get('/announcements/all');
  }
  async announcementCreate(
    announcementData: RequestAnnouncementCreateModel
  ): Promise<ResponseAnnouncementCreateModel | ResponseErrorModel> {
    return axios.post('/announcements/create', announcementData);
  }
  async announcementRead(
    postId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/announcements/read/${postId}`);
  }
  async announcementPlannedCreate(
    announcementData: RequestAnnouncementCreateModel
  ): Promise<ResponseAnnouncementCreateModel | ResponseErrorModel> {
    return axios.post('/announcements/planned/create', announcementData);
  }
  async announcementPlannedUpdate(
    postId: number,
    announcementData: RequestAnnouncementCreateModel
  ): Promise<ResponseAnnouncementCreateModel | ResponseErrorModel> {
    return axios.post(
      `/announcements/planned/${postId}/update`,
      announcementData
    );
  }
  async announcementPlannedForce(
    postId: number
  ): Promise<ResponseAnnouncementCreateModel | ResponseErrorModel> {
    return axios.post(`/announcements/planned/${postId}/force`);
  }
  async announcementPlannedDelete(
    postId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/announcements/planned/${postId}/delete`);
  }
}
