import {
  cloneDeep,
  filter,
  find,
  includes,
  indexOf,
  orderBy,
  remove,
  unionBy,
} from 'lodash';
import { defineStore } from 'pinia';

import {
  GroupPageTabEnum,
  GroupsAccessEnum,
  GroupsFilterEnum,
  GroupsTypeEnum,
} from '@/@enums';
import type {
  ErrorMessageModel,
  GroupCreateDataModel,
  GroupEntity,
  GroupsIdsModel,
  MediaModel,
  InviteExternalUsersModel,
  ResponseErrorModel,
  ResponseGroupModel,
  ResponseGroupsModel,
  ResponseInviteExternalUsersModel,
  ResponseUserAvatarModel,
  ResponseUsersModel,
  ShortGroupsModel,
  UserEntity,
} from '@/@types';
import { defaultGroup, defaultGroupsIds } from '@/models';
import { $api } from '@/services';
import {
  useAppStore,
  useMenuStore,
  type EntityState,
  useUserStore,
} from '@/store';

interface GroupState extends EntityState<GroupEntity> {
  loadMoreUrl: string | null;
  /*  canPostGroups: GroupEntity[]; */
  groupsIds: GroupsIdsModel;
  selectedTab: GroupPageTabEnum;
}

export const useGroupsStore = defineStore({
  id: 'groups',
  state: (): GroupState => ({
    data: [],
    errors: [],
    loading: false,
    loadMoreUrl: null,
    selectedTab: GroupPageTabEnum.Dashboard,
    /* canPostGroups: [], */
    groupsIds: cloneDeep(defaultGroupsIds),
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getGroups: (state) => {
      return state.data;
    },
    getGroupById:
      (state) =>
      (id: number): GroupEntity => {
        const index = state.data.findIndex(
          (group: GroupEntity) => group.id === id
        );

        if (~index) {
          return state.data[index];
        }
        return cloneDeep(defaultGroup);
      },
    getGroupByAlias:
      (state) =>
      (alias: string): GroupEntity => {
        const index = state.data.findIndex(
          (group: GroupEntity) => group.mainAlias === alias
        );

        if (~index) {
          return state.data[index];
        }
        return cloneDeep(defaultGroup);
      },
    getGroupsAll: (state) => (): ShortGroupsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortGroupsModel;
      const data = orderBy(state.data, (obj) =>
        indexOf(state.groupsIds.all.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.groupsIds.all.ids, obj.id)
      );
      result.loadMoreUrl = state.groupsIds.all.loadMoreUrl;
      return result;
    },
    getCanPostGroups: (state) => (): ShortGroupsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortGroupsModel;
      const data = orderBy(state.data, (obj) =>
        indexOf(state.groupsIds.canPost.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.groupsIds.canPost.ids, obj.id)
      );
      result.loadMoreUrl = state.groupsIds.canPost.loadMoreUrl;
      return result;
    },
    getAdminGroups: (state) => (): ShortGroupsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortGroupsModel;
      const data = orderBy(state.data, (obj) =>
        indexOf(state.groupsIds.isAdmin.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.groupsIds.isAdmin.ids, obj.id)
      );
      result.loadMoreUrl = state.groupsIds.isAdmin.loadMoreUrl;
      return result;
    },
    getGroupsFromUserId:
      (state) =>
      (userId: number | null): ShortGroupsModel => {
        const index = state.groupsIds.byUser.findIndex(
          (n) => n.userId === userId
        );
        const result = { data: [], loadMoreUrl: null } as ShortGroupsModel;
        if (~index) {
          const data = orderBy(state.data, (obj) =>
            indexOf(state.groupsIds.byUser[index].ids, obj.id)
          );
          result.data = filter(data, (obj) =>
            includes(state.groupsIds.byUser[index].ids, obj.id)
          );
          result.loadMoreUrl = state.groupsIds.byUser[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },
    getSearchedGroups: (state) => (): ShortGroupsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortGroupsModel;
      const data = orderBy(state.data, (obj) =>
        indexOf(state.groupsIds.search.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.groupsIds.search.ids, obj.id)
      );
      result.loadMoreUrl = state.groupsIds.search.loadMoreUrl;
      return result;
    },
    getLoadMoreUrl:
      (state) =>
      (mode: GroupsFilterEnum, userId?: number | null): string | null => {
        let url = null as null | string;

        switch (mode) {
          case GroupsFilterEnum.All:
            {
              url = state.groupsIds.all.loadMoreUrl;
            }
            break;

          case GroupsFilterEnum.Search:
            {
              url = state.groupsIds.search.loadMoreUrl;
            }
            break;

          case GroupsFilterEnum.ByUser:
            {
              const index = state.groupsIds.byUser.findIndex(
                (n) => n.userId === userId
              );
              if (~index) {
                url = state.groupsIds.byUser[index].loadMoreUrl;
              }
            }

            break;
        }

        return url;
      },
  },
  actions: {
    async groupsAll(): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.group.getGroups();

      if (response.statusCode === 200) {
        const model = response as ResponseGroupsModel;
        this.data = mergeById(this.data, model.data);

        this.groupsIds.all.ids = model.data.map((n) => n.id);
        this.groupsIds.all.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },
    async groupsFromUserId(userId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.group.getGroupsByUserId(userId);

      if (response.statusCode === 200) {
        const model = response as ResponseGroupsModel;

        this.data = mergeById(this.data, model.data);

        const index = this.groupsIds.byUser.findIndex(
          (n) => n.userId === userId
        );

        if (~index) {
          this.groupsIds.byUser[index].ids = model.data.map((n) => n.id);
          this.groupsIds.byUser[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.groupsIds.byUser.push({
            userId: userId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async loadMore(
      mode: GroupsFilterEnum,
      userId?: number | null
    ): Promise<void> {
      this.errors = [];
      const url = this.getLoadMoreUrl(mode, userId);
      if (url !== null) {
        const response = await $api.group.loadMore(url);

        if (response.statusCode === 200) {
          const model = response as ResponseGroupsModel;
          this.data = mergeById(this.data, model.data);
          updateGroupsAfterLoadedMore(mode, model, userId);
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
        return;
      }
      return;
    },
    async autocomplete(
      text: string,
      includeAllClosedGroups: boolean
    ): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.group.autocomplete(
        text,
        includeAllClosedGroups
      );

      if (response.statusCode === 200) {
        const model = response as ResponseGroupsModel;
        this.data = mergeById(this.data, model.data);

        this.groupsIds.search.ids = model.data.map((n) => n.id);
        this.groupsIds.search.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
    },
    clearSearchData() {
      this.groupsIds.search.ids = [];
      this.groupsIds.search.loadMoreUrl = null;
    },
    groupsFromSearch(
      groups: GroupEntity[],
      loadMoreUrl: string | null = null
    ): void {
      if (groups.length) {
        this.data = mergeById(this.data, groups);
        this.groupsIds.search.ids = groups.map((n) => n.id);
        this.groupsIds.search.loadMoreUrl = loadMoreUrl;
      }
    },
    async groupById(id: number): Promise<ResponseGroupModel | undefined> {
      this.errors = [];
      this.loading = true;
      const response = await $api.group.getGroupById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseGroupModel;
        this.loading = false;
        this.upsert(model.data);
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return undefined;
    },
    async updateAvatar(groupId: number, image: File): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.updateAvatar(groupId, image);
      if (response.statusCode === 200) {
        const model = response as ResponseUserAvatarModel;
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].avatar = {
          url: model.data.url,
          width: model.data.width,
          height: model.data.height,
        } as MediaModel;
        this.upsert(this.data[index]);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteAvatar(groupId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.deleteAvatar(groupId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].avatar = {
          url: null,
          width: null,
          height: null,
        } as MediaModel;
        this.upsert(this.data[index]);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async onJoin(groupId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.join(groupId);
      if (response.statusCode === 200) {
        const model = response as ResponseGroupModel;
        const index = this.data.findIndex((n) => n.id === groupId);
        if (~index) {
          this.data[index] = model.data;
        }
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async onLeave(groupId: number, lastUser: boolean): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.leave(groupId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        if (~index) {
          //NOTE: Удаляем группу из стора если это последний участник или группа скрытая
          if (
            lastUser ||
            this.data[index].type === GroupsTypeEnum.PrivateHidden
          ) {
            remove(this.data, (n) => n.id === groupId);
          } else {
            this.data[index].accessType = GroupsAccessEnum.None;
            this.data[index].stats.members -= 1;
          }
          removeGroupIdFromUserList(groupId);
        }
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async groupUpdate(
      groupData: GroupCreateDataModel
    ): Promise<GroupEntity | undefined> {
      this.errors = [];
      const response = await $api.group.groupUpdate(groupData);

      if (response.statusCode === 200) {
        const model = response as ResponseGroupModel;
        this.upsert(model.data);
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },
    async groupCreate(
      groupData: GroupCreateDataModel
    ): Promise<GroupEntity | undefined> {
      this.errors = [];
      const menuStore = useMenuStore();

      const response = await $api.group.groupCreate(groupData);

      if (response.statusCode === 200) {
        const model = response as ResponseGroupModel;
        this.data.unshift(model.data);
        this.groupsIds.isAdmin.ids.unshift(model.data.id);
        this.groupsIds.all.ids.unshift(model.data.id);
        this.groupsIds.canPost.ids.unshift(model.data.id);
        const index = this.groupsIds.byUser.findIndex(
          (n) => n.userId === model.data.adminIds[0]
        );
        if (~index) {
          this.groupsIds.byUser[index].ids.unshift(model.data.id);
        }
        menuStore.addNewGroup(model.data);

        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },
    async userRequests(groupId: number): Promise<UserEntity[]> {
      this.errors = [];
      const response = await $api.group.getUserRequests(groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseUsersModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return [];
    },
    async groupApproveUser(
      groupId: number,
      userId: number,
      isApproved: boolean
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.groupApproveUser(
        groupId,
        userId,
        isApproved
      );
      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async updateCoverImage(groupId: number, image: File): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.updateCoverImage(groupId, image);
      if (response.statusCode === 200) {
        const model = response as ResponseUserAvatarModel;
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].cover = {
          url: model.data.url,
          width: model.data.width,
          height: model.data.height,
        } as MediaModel;
        this.upsert(this.data[index]);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteCoverImage(groupId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.deleteCover(groupId);
      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].cover = {
          url: null,
          width: null,
          height: null,
        } as MediaModel;
        this.upsert(this.data[index]);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async groupInviteUsersById(
      groupId: number,
      usersIds: number[]
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.groupInviteUsersById(groupId, usersIds);
      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async groupInviteUsersByEmail(
      groupId: number,
      userEmails: string[]
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.group.groupInviteUsersByEmail(
        groupId,
        userEmails
      );
      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async groupInviteExternalUsers(
      groupId: number,
      userEmails: string[]
    ): Promise<InviteExternalUsersModel[] | undefined> {
      this.errors = [];
      const response = await $api.group.groupInviteExternalUsers(
        groupId,
        userEmails
      );
      if (response.statusCode === 200) {
        const model = response as ResponseInviteExternalUsersModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },
    async canPostGroups(searchText?: string): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.group.getCanPostGroups(searchText);

      if (response.statusCode === 200) {
        const model = response as ResponseGroupsModel;
        this.data = mergeById(this.data, model.data);

        if (!searchText) {
          this.groupsIds.canPost.ids = model.data.map((n) => n.id);
          this.groupsIds.canPost.loadMoreUrl = model.loadMoreUrl;
        } else {
          this.groupsIds.search.ids = model.data.map((n) => n.id);
          this.groupsIds.search.loadMoreUrl = model.loadMoreUrl;
        }
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async adminGroups(searchText?: string): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.group.getAdminGroups(searchText);

      if (response.statusCode === 200) {
        const model = response as ResponseGroupsModel;
        this.data = mergeById(this.data, model.data);

        if (!searchText) {
          this.groupsIds.isAdmin.ids = model.data.map((n) => n.id);
          this.groupsIds.isAdmin.loadMoreUrl = model.loadMoreUrl;
        } else {
          this.groupsIds.search.ids = model.data.map((n) => n.id);
          this.groupsIds.search.loadMoreUrl = model.loadMoreUrl;
        }
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },
    async updateShowMembers(groupId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.updateShowMembers(groupId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].showMembers = !this.data[index].showMembers;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteGroup(groupId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.deleteGroup(groupId);

      if (response.statusCode === 200) {
        remove(this.data, (group) => group.id === groupId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async updateShowInformation(groupId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.updateShowInformation(groupId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].showInformation = !this.data[index].showInformation;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async updateShowDocs(groupId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.updateShowDocs(groupId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].showDocs = !this.data[index].showDocs;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async updateEnableLikes(groupId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.updateEnableLikes(groupId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].enableLikes = !this.data[index].enableLikes;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async updateEnableComments(groupId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.updateEnableComments(groupId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].enableComments = !this.data[index].enableComments;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async updateEnableShared(groupId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.updateEnableShared(groupId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].enableShared = !this.data[index].enableShared;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async addAdmin(groupId: number, userId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.addAdmin(groupId, userId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].adminIds.push(userId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async removeAdmin(groupId: number, userId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.group.removeAdmin(groupId, userId);

      if (response.statusCode === 200) {
        const index = this.data.findIndex((n) => n.id === groupId);
        this.data[index].adminIds = this.data[index].adminIds.filter(
          (n) => n !== userId
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    updateGroupMembersCount(groupId: number, membersCount: number) {
      const index = this.data.findIndex(({ id }) => id === groupId);
      if (~index) {
        this.data[index].stats.members = membersCount;
      }
    },
    upsert(group: GroupEntity) {
      const index = this.data.findIndex(({ id }) => id === group.id);
      if (~index) {
        this.data[index] = cloneDeep(group);
      } else {
        this.data = [...this.data, group];
      }

      this.$patch({
        errors: [],
      });
    },
    async loadGroups(currentUserId: number) {
      const appStore = useAppStore();
      switch (appStore.groupsFilter) {
        case GroupsFilterEnum.All:
          return await this.groupsAll();

        case GroupsFilterEnum.ByUser:
          return await this.groupsFromUserId(currentUserId);

        case GroupsFilterEnum.Admin:
          return await this.adminGroups();
      }
    },
  },

  persist: true,
});

const mergeById = (a: GroupEntity[], b: GroupEntity[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const updateGroupsAfterLoadedMore = (
  mode: GroupsFilterEnum,
  model: ResponseGroupsModel,
  userId?: number | null
) => {
  const groupStore = useGroupsStore();
  switch (mode) {
    case GroupsFilterEnum.All:
      {
        groupStore.groupsIds.all.ids = [
          ...groupStore.groupsIds.all.ids,
          ...model.data.map((n) => n.id),
        ];
        groupStore.groupsIds.all.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case GroupsFilterEnum.Search:
      {
        groupStore.groupsIds.search.ids = [
          ...groupStore.groupsIds.search.ids,
          ...model.data.map((n) => n.id),
        ];
        groupStore.groupsIds.search.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case GroupsFilterEnum.CanPost:
      {
        groupStore.groupsIds.canPost.ids = [
          ...groupStore.groupsIds.canPost.ids,
          ...model.data.map((n) => n.id),
        ];
        groupStore.groupsIds.canPost.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case GroupsFilterEnum.Admin:
      {
        groupStore.groupsIds.isAdmin.ids = [
          ...groupStore.groupsIds.isAdmin.ids,
          ...model.data.map((n) => n.id),
        ];
        groupStore.groupsIds.isAdmin.loadMoreUrl = model.loadMoreUrl;
      }
      break;

    case GroupsFilterEnum.ByUser:
      {
        const index = groupStore.groupsIds.byUser.findIndex(
          (n) => n.userId === userId
        );
        if (~index) {
          groupStore.groupsIds.byUser[index].ids = [
            ...groupStore.groupsIds.byUser[index].ids,
            ...model.data.map((n) => n.id),
          ];
          groupStore.groupsIds.byUser[index].loadMoreUrl = model.loadMoreUrl;
        }
      }
      break;
  }
};

const removeGroupIdFromUserList = (groupId: number) => {
  const userStore = useUserStore();
  const groupStore = useGroupsStore();
  const currentUserId = userStore.current?.id ?? 0;
  const userObject = groupStore.groupsIds.byUser.find(
    (n) => n.userId === currentUserId
  );

  if (userObject) {
    const groupIndex = userObject.ids.findIndex((n) => n === groupId);
    if (~groupIndex) {
      remove(userObject.ids, (n) => n === groupId);
    }
  }
};
