<template>
  <template v-if="type === BadgeDisplayType.Modal && badgeData">
    <div class="modal-badge">
      <div class="image">
        <media-image
          v-if="badgeData.largeIcon !== null"
          :url="badgeData.largeIcon"
        />
      </div>
      <div class="title">
        <ion-label>{{ badgeData.title }}</ion-label>
      </div>
    </div>
  </template>

  <template v-if="type === BadgeDisplayType.Post && badgeData">
    <div class="post-badge">
      <media-image
        v-if="badgeData.largeIcon !== null"
        :url="badgeData.largeIcon"
      />
    </div>
  </template>

  <template v-if="type === BadgeDisplayType.UserName && badgeData">
    <div
      v-tooltip.bottom="{
        content: badgeData.title,
        theme: 'info-tooltip',
        triggers: [isAnyMobile ? 'touch' : 'hover'],
      }"
      class="user-name-badge"
    >
      <media-image
        v-if="badgeData.largeIcon !== null"
        :url="badgeData.largeIcon"
      />
    </div>
  </template>

  <template v-if="type === BadgeDisplayType.Profile && badgeData">
    <div
      v-tooltip.bottom="{
        content: `${badgeData.title}: ${count}`,
        theme: 'info-tooltip',
        triggers: [isAnyMobile ? 'touch' : 'hover'],
      }"
      class="profile-badge"
    >
      <media-image
        v-if="badgeData.largeIcon !== null"
        :url="badgeData.largeIcon"
      />
      <ion-badge mode="md" class="counter">{{ count }}</ion-badge>
    </div>
  </template>
</template>

<script lang="ts" setup>
import { IonLabel, IonBadge } from '@ionic/vue';
import { computed, onMounted, type ComputedRef, type PropType } from 'vue';

import { BadgeDisplayType } from '@/@enums';
import type { BadgeEntity } from '@/@types';
import { MediaImage } from '@/components';
import { isAnyMobile } from '@/helpers';
import { useBadgesStore } from '@/store';

const props = defineProps({
  badgeId: {
    type: Number,
    required: true,
  },
  type: {
    type: Number as PropType<BadgeDisplayType>,
    required: true,
  },
  count: {
    type: Number,
    default: 1,
  },
});

const badgeStore = useBadgesStore();
const badgeData: ComputedRef<BadgeEntity | undefined> = computed(() =>
  badgeStore.getBadgeById(props.badgeId)
);

onMounted(async () => {
  await badgeStore.checkExistBadgesInStore(props.badgeId);
});
</script>

<style scoped lang="scss">
.modal-badge {
  height: 6rem;
  margin-top: 1rem;
  margin-bottom: app-padding(lg);
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .image {
    height: 96px;
    content: none;
  }
  .title {
    text-align: center;
    margin-top: app-padding(md);
    font-weight: bold;
  }
}

.post-badge {
  width: 100%;
  height: 100%;
}

.user-name-badge {
  margin-right: app-padding(sm);
  width: 1rem;
  height: 1rem;
}

.profile-badge {
  position: relative;
  margin-right: app-padding(md);
  width: 32px;
  height: 32px;
  .counter {
    position: absolute;
    bottom: -(app-padding(sm));
    right: -(app-padding(sm));
    color: var(--ion-color-medium);
    --background: var(--ion-color-light-custom);
    font-size: 0.7rem;
    border-radius: app-radius(lg);
    min-width: 1.3rem;
  }
}
</style>
