import type { OverlayEventDetail } from '@ionic/core';
import { actionSheetController } from '@ionic/vue';
import {
  pencilOutline,
  copyOutline,
  eyeOutline,
  calendarOutline,
  timerOutline,
  arrowUndoOutline,
} from 'ionicons/icons';
import { map } from 'lodash';

import { useTaskManagementHelper } from './useTaskManagementHelper';

import type {
  PostTypeActionEnum,
  AppImageChangeMenuEnum,
  TaskManagementTaskModalMenuCategoryEnum,
  TaskManagementTaskModalMenuItemActionEnum,
  FileMenuActionEnum,
  ConversationsFlagEnum,
  ConversationsTypeEnum,
  PostMenuActionEnum,
  MessageActionEnum,
  WikiMenuActionEnum,
  CommentActionEnum,
  PostUploadFileEnum,
  DocsMenuActionEnum,
} from '@/@enums';
import {
  CalendarCellActionEnum,
  CalendarViewModeEnum,
  EventCalendarSourceEnum,
  PlannedPostActionEnum,
  PostTextActionEnum,
} from '@/@enums';
import type {
  AppMenuButtonsModel,
  DocEntity,
  PostEntity,
  MessageEntity,
} from '@/@types';
import {
  calendarShowBackBtn,
  calendarShowEvents,
  docBrowserMenuItems,
  getCommentContextMenuItems,
  getMessengerContextMenuItems,
  getPostContextMenuItems,
  getPostUploadFileMenuItems,
  useFileActions,
  useWiki,
} from '@/helpers';
import { useI18n } from '@/i18n';

export const calendarCellMenuSheet = async (
  activeView: CalendarViewModeEnum,
  isSmall: boolean
): Promise<OverlayEventDetail<CalendarCellActionEnum | undefined>> => {
  const { t } = useI18n();
  const menuItems = [
    {
      title: t('conversations.event.menu.newEvent'),
      value: CalendarCellActionEnum.CreateEvent,
      icon: pencilOutline,
    },
    {
      title: t('conversations.event.menu.showEvents'),
      value: CalendarCellActionEnum.ShowEvents,
      icon: eyeOutline,
      disabled: !calendarShowEvents(isSmall, activeView),
    },
    {
      title: t('conversations.event.menu.toDate'),
      value: CalendarCellActionEnum.ToDate,
      icon: calendarOutline,
      disabled: activeView === CalendarViewModeEnum.Day,
    },
    {
      title: t('back'),
      value: CalendarCellActionEnum.Back,
      icon: arrowUndoOutline,
      disabled: !calendarShowBackBtn(isSmall, activeView),
    },
  ].filter(({ disabled }) => !disabled);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<CalendarCellActionEnum | undefined>
      ) => {
        return result;
      }
    );
};

export const calendarSourceSheet = async (): Promise<
  OverlayEventDetail<EventCalendarSourceEnum | undefined>
> => {
  const { t } = useI18n();
  const menuItems = [
    {
      title: t('conversations.event.allEvents'),
      value: EventCalendarSourceEnum.All,
    },
    {
      title: t('conversations.event.myEvents'),
      value: EventCalendarSourceEnum.My,
    },
    {
      title: t('conversations.event.groupEvents'),
      value: EventCalendarSourceEnum.Groups,
    },
  ];
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, value } = obj;
      return { text: title, data: value };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<EventCalendarSourceEnum | undefined>
      ) => {
        return result;
      }
    );
};

export const postTextMenuSheet = async (): Promise<
  OverlayEventDetail<PostTextActionEnum | undefined>
> => {
  const { t } = useI18n();
  const menuItems = [
    {
      icon: copyOutline,
      disabled: false,
      value: PostTextActionEnum.CopyText,
      title: t('appPopoverMenu.copy.title'),
    },
  ].filter(({ disabled }) => !disabled);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<PostTextActionEnum | undefined>) => {
        return result;
      }
    );
};

export const plannedPostMenuSheet = async (): Promise<
  OverlayEventDetail<PlannedPostActionEnum | undefined>
> => {
  const { t } = useI18n();
  const menuItems = [
    {
      icon: timerOutline,
      disabled: false,
      value: PlannedPostActionEnum.SchedulePost,
      title: t('conversations.schedulePost'),
    },
  ].filter(({ disabled }) => !disabled);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<PlannedPostActionEnum | undefined>) => {
        return result;
      }
    );
};

export const postUploadFileMenuSheet = async (): Promise<
  OverlayEventDetail<PostUploadFileEnum | undefined>
> => {
  const menuItems = getPostUploadFileMenuItems();
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<PostUploadFileEnum | undefined>) => {
        return result;
      }
    );
};

export const commentContextMenuSheet = async (
  authorId: number,
  currentUserId: number,
  isEditable: boolean
): Promise<OverlayEventDetail<CommentActionEnum | undefined>> => {
  const menuItems = getCommentContextMenuItems(
    authorId,
    currentUserId,
    isEditable
  );
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value, handler } = obj;
      return { text: title, data: value, icon: icon, handler };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<CommentActionEnum | undefined>) => {
      return result;
    });
};

export const messengerContextMenuSheet = async (
  ev: Event,
  message: MessageEntity,
  currentId: number | undefined,
  deleteFlag = false
): Promise<OverlayEventDetail<MessageActionEnum | undefined>> => {
  const menuItems = getMessengerContextMenuItems(
    ev,
    message,
    currentId,
    deleteFlag
  );
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(async (result: OverlayEventDetail<MessageActionEnum | undefined>) => {
      return result;
    });
};

export const wikiActionsMenuSheet = async (): Promise<
  OverlayEventDetail<WikiMenuActionEnum | undefined>
> => {
  const menuItems = useWiki().getActionsMenuItems();
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<WikiMenuActionEnum | undefined>) => {
        return result;
      }
    );
};

export const docBrowserContextMenuSheet = async (
  file: DocEntity
): Promise<OverlayEventDetail<FileMenuActionEnum | undefined>> => {
  const menuItems = useFileActions().getDocsFileMenuItems(file);
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<FileMenuActionEnum | undefined>) => {
        return result;
      }
    );
};

export const docBrowserMainMenuSheet = async (): Promise<
  OverlayEventDetail<DocsMenuActionEnum | undefined>
> => {
  const menuItems = docBrowserMenuItems();
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value } = obj;
      return {
        text: title,
        data: value,
        icon: icon,
        cssClass: 'doc-browser-menu-sheet-buttons',
      };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<DocsMenuActionEnum | undefined>) => {
        return result;
      }
    );
};

export const postContextMenuSheet = async (
  postData: PostEntity,
  conversationsFlag: ConversationsFlagEnum,
  conversationsType?: ConversationsTypeEnum | string,
  shareFlag = false
): Promise<OverlayEventDetail<PostMenuActionEnum | undefined>> => {
  const menuItems = getPostContextMenuItems(
    postData,
    conversationsFlag,
    conversationsType,
    shareFlag
  );
  const actionSheet = await actionSheetController.create({
    buttons: map(menuItems, (obj) => {
      const { title, icon, value, handler } = obj;
      return { text: title, data: value, icon: icon, handler };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<PostMenuActionEnum | undefined>) => {
        return result;
      }
    );
};

export const postCreateContextMenuSheet = async (
  categories: { value: string; active: boolean; icon: string; title: string }[]
): Promise<OverlayEventDetail<PostTypeActionEnum | undefined>> => {
  const actionSheet = await actionSheetController.create({
    buttons: map(categories, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (result: OverlayEventDetail<PostTypeActionEnum | undefined>) => {
        return result;
      }
    );
};

export const appImageChangeActionSheet = async (
  actions: AppMenuButtonsModel[]
): Promise<OverlayEventDetail<AppImageChangeMenuEnum | undefined>> => {
  const actionSheet = await actionSheetController.create({
    buttons: map(actions, (obj) => {
      const { action, text, icon, cssClass } = obj;
      return { text: text, data: action, icon: icon, cssClass: cssClass };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<AppImageChangeMenuEnum | undefined>
      ) => {
        return result;
      }
    );
};

export const taskManagementTaskMainMenuSheet = async (): Promise<
  OverlayEventDetail<TaskManagementTaskModalMenuCategoryEnum | undefined>
> => {
  const taskManagementHelper = useTaskManagementHelper();
  const actionSheet = await actionSheetController.create({
    buttons: map(taskManagementHelper.getTaskMainMenu(), (obj) => {
      const { title, value } = obj;
      return { text: title, data: value };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<
          TaskManagementTaskModalMenuCategoryEnum | undefined
        >
      ) => {
        return result;
      }
    );
};

export const taskManagementTaskActionsMenuSheet = async (
  taskIsClosed: boolean,
  taskIsArchived: boolean,
  taskNotify: boolean | null,
  userIsAssigned: boolean,
  projectId: number
): Promise<
  OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>
> => {
  const taskManagementHelper = useTaskManagementHelper();
  const actionSheet = await actionSheetController.create({
    buttons: map(
      taskManagementHelper.getTaskMenuActions(
        true,
        taskIsClosed,
        taskIsArchived,
        taskNotify,
        userIsAssigned,
        projectId
      ).data,
      (obj) => {
        const { title, icon, value } = obj;
        return { text: title, data: value, icon: icon };
      }
    ),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<
          TaskManagementTaskModalMenuItemActionEnum | undefined
        >
      ) => {
        return result;
      }
    );
};

export const taskManagementTaskFeaturesMenuSheet = async (): Promise<
  OverlayEventDetail<TaskManagementTaskModalMenuItemActionEnum | undefined>
> => {
  const taskManagementHelper = useTaskManagementHelper();
  const actionSheet = await actionSheetController.create({
    buttons: map(taskManagementHelper.getTaskMenuFeatures().data, (obj) => {
      const { title, icon, value } = obj;
      return { text: title, data: value, icon: icon };
    }),
  });
  await actionSheet.present();
  return actionSheet
    .onDidDismiss()
    .then(
      async (
        result: OverlayEventDetail<
          TaskManagementTaskModalMenuItemActionEnum | undefined
        >
      ) => {
        return result;
      }
    );
};
