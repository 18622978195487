<template>
  <docs-item-view
    :id="file.id"
    :view-type="viewType"
    :document-type="DocumentTypeEnum.File"
    :name="fileName"
    :created-at="file.createdAt"
    :edited-at="file.editedAt"
    :author="fileAuthor"
    :editor="fileEditor"
    :group="fileGroupTitle"
    :icon="fileIcon"
    :status="status"
    :file="file"
    :size="fileSize"
    @onDownloadStop="stopDownload"
    @onMenuOpen="(ev) => openFileMenu(ev)"
    @onItemClick="openFile"
  />
</template>

<script lang="ts" setup>
import { documentOutline, imageOutline, videocamOutline } from 'ionicons/icons';
import type { ComputedRef, PropType, Ref } from 'vue';
import { computed, ref } from 'vue';

import type { DocsViewTypeEnum } from '@/@enums';
import { DocumentTypeEnum, FileStatusEnum } from '@/@enums';
import type { DocEntity, FileModel } from '@/@types';
import { DocsItemView } from '@/components';
import {
  showToast,
  useFileActions,
  openFileActionsMenu,
  filesHybrid,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { useDocStore } from '@/store';

// Props
const props = defineProps({
  file: {
    type: Object as PropType<FileModel>,
    required: true,
  },
  viewType: {
    type: String as PropType<DocsViewTypeEnum>,
    required: true,
  },
});

// Icons
const icons = {
  video: videocamOutline,
  image: imageOutline,
  document: documentOutline,
};

// Store
const docStore = useDocStore();

// Other
const { t } = useI18n();

// Refs
const status: Ref<FileStatusEnum> = ref<FileStatusEnum>(FileStatusEnum.Init);

// Computed
const isAttachment: ComputedRef<boolean> = computed(
  () => docStore.isAttachmentModal
);

const isImage = computed(() => {
  return (
    props.file.mimeType.startsWith('image') &&
    !filesHybrid.unsupportedImageFormats.includes(props.file.type)
  );
});

const selectedDocs: ComputedRef<DocEntity[]> = computed(
  () => docStore.selectedDocs
);
const fileGroupTitle: ComputedRef<string> = computed(() =>
  props.file.group ? props.file.group.title : t('network.allNetwork')
);
const fileName: ComputedRef<string> = computed(
  () => props.file.name + '.' + props.file.type
);
const fileAuthor: ComputedRef<string> = computed(() =>
  props.file.author ? props.file.author?.fullName : ''
);
const fileEditor: ComputedRef<string> = computed(() =>
  props.file.editedBy ? props.file.editedBy?.fullName : ''
);
const fileSize: ComputedRef<string> = computed(() => {
  if (props.file.size) {
    let size;
    size = props.file.size / 1014;
    if (size >= 1024) {
      size = size * 0.001;
      size = size.toFixed(1);
      return size + ' MB';
    } else {
      size = size.toFixed(1);
      return size + ' KB';
    }
  }
  return '';
});
const fileIcon: ComputedRef<string> = computed(() => {
  if (props.file.mimeType.startsWith('video')) {
    return icons.video;
  } else if (props.file.mimeType.startsWith('image')) {
    return icons.image;
  } else {
    return icons.document;
  }
});

// Actions

const _handleAttachment = (): void => {
  if (!selectedDocs.value.some((n) => n.data.id === props.file.id)) {
    docStore.$patch({
      selectedDocs: [
        ...selectedDocs.value,
        { documentType: DocumentTypeEnum.File, data: props.file },
      ],
    });
  } else {
    docStore.$patch({
      selectedDocs: selectedDocs.value.filter(
        (n) => n.data.id !== props.file.id
      ),
    });
  }
};

const _handleFile = (): void => {
  emit('onFilePreview', props.file);
};

const _handleImage = (): void => {
  emit('onImagePreview', props.file);
};

const stopDownload = async (id: number) => {
  status.value = FileStatusEnum.Success;
  emit('onLoading', false, id);
  await showToast(t('files.downloadStopped'), true);
};

const openFile = async (): Promise<void> => {
  try {
    if (isAttachment.value) {
      _handleAttachment();
      return;
    }

    if (isImage.value) {
      _handleImage();
      return;
    }

    if (await useFileActions(status).isPreviewAvailable(props.file)) {
      _handleFile();
      return;
    }

    // Download
    await useFileActions(status).downloadFile(props.file);
  } catch (error) {
    status.value = FileStatusEnum.Error;
    await showToast(t('errorResponse'), false);
  }
};

const openFileMenu = async (ev: Event) => {
  status.value = await openFileActionsMenu(ev, props.file, status);
};

// Emits
const emit = defineEmits(['onLoading', 'onFilePreview', 'onImagePreview']);
</script>
