import type {
  ResponseAuthCoreTokenModel,
  RequestTokenUserModel,
  RequestAuthNetworkUser,
  RequestAuthHomeUser,
  ResponseAuthUserTokenModel,
  ResponseNetworkLinkModel,
  ResponseErrorModel,
  ResponseNetworksModel,
} from '@/@types';
import axios from '@/services/axios';

export class AuthApiService {
  async oauth(
    clientSecret: string
  ): Promise<ResponseNetworkLinkModel | ResponseErrorModel> {
    return axios.get(`/oauth/link?clientSecret=${clientSecret}&type=ApiCore`);
  }
  async networks(
    params: RequestAuthNetworkUser
  ): Promise<ResponseNetworksModel | ResponseErrorModel> {
    return axios.post('/oauth/networks', params);
  }
  async homeCode(
    params: RequestAuthHomeUser
  ): Promise<ResponseAuthCoreTokenModel | ResponseErrorModel> {
    return axios.post('/oauth/homeCode', params);
  }
  async token(
    params: RequestTokenUserModel
  ): Promise<ResponseAuthUserTokenModel | ResponseErrorModel> {
    return axios.post('/oauth/token', params);
  }
}
