<template>
  <div
    :class="['card-participants', { overlaying: users.length > 1 }]"
    @click.stop="openList($event)"
  >
    <user-avatar
      v-for="user in users.slice(0, maxUsers)"
      :key="user.id"
      :user-id="user.id"
      :fio="user.fullName"
      :url="user.image?.url ?? ''"
      :image="user.image"
      :class="[size, { 'small-font': size === 'small' }]"
      :with-online-status="false"
      :title="user.fullName"
    />
    <div v-if="users.length > maxUsers" class="plug">
      <span>{{ '+' + (users.length - maxUsers) }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import type { UserMessageModel } from '@/@types';
import { UserAvatar } from '@/components';
import { userListPopover } from '@/helpers';
import { ROUTES_NAME } from '@/router';

const props = defineProps({
  users: {
    type: Array as PropType<UserMessageModel[]>,
    required: true,
  },
  size: {
    type: String,
    default: '',
  },
});

const router = useRouter();
const maxUsers = ref<number>(5);
const openList = async (ev: Event) => {
  const result = await userListPopover(ev, props.users, false);
  if (result?.data) {
    await router.push({
      name: ROUTES_NAME.USER_BY_ID,
      params: { id: result?.data.id },
    });
  }
};
</script>

<style scoped lang="scss">
.card-participants {
  display: flex;
  flex-wrap: wrap;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  ion-avatar {
    position: relative;
    height: calc(app-padding(lg) * 2.5);
    width: calc(app-padding(lg) * 2.5);
    margin-right: calc(app-padding(lg) / 4);
    margin-bottom: calc(app-padding(lg) / 4);
    &.small {
      height: app-padding(lg);
      width: app-padding(lg);
      margin-right: 0;
      margin-bottom: 0;
    }
    &.small-font {
      font-size: 8px;
    }
  }
  &.overlaying {
    ion-avatar {
      margin-right: calc(app-padding(lg) * -1.3);
      border: 2px solid var(--ion-color-dark-contrast);
    }
    .plug {
      border: 2px solid var(--ion-color-dark-contrast);
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--ion-color-light);
      border-radius: 50%;
      z-index: 1;
      height: calc(app-padding(lg) * 2.5);
      width: calc(app-padding(lg) * 2.5);
      span {
        color: var(--ion-color-medium);
      }
    }
  }
}
</style>
