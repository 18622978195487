<template>
  <h3 v-if="!isTitleEditing" class="task-title" @click="editTitle()">
    {{ taskData.title }}
  </h3>
  <div v-else class="task-title-edit">
    <form-input
      v-model:value="data.title"
      :placeholder="$t('title')"
      :is-floating="false"
      custom-size="medium"
      is-single-item
      apply-auto-focus
    />

    <div v-if="titleErrors.length > 0">
      <form-error name="titleErrors" :errors-array="titleErrors" />
    </div>

    <ion-button
      mode="md"
      fill="solid"
      color="success"
      :disabled="isLoading"
      @click.stop="updateTaskTitle()"
    >
      <span v-if="!isLoading">{{ $t('save') }}</span>
      <icons-provider
        v-else
        slot="icon-only"
        :icon-props="{ width: '16', height: '16' }"
        name="dotsAnim"
      />
    </ion-button>
    <ion-button
      mode="md"
      fill="clear"
      color="medium"
      :disabled="isLoading"
      @click.stop="cancelEdit()"
    >
      {{ $t('cancel') }}
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import type { ErrorObject } from '@vuelidate/core';
import useVuelidate from '@vuelidate/core';
import { computed, ref, reactive } from 'vue';
import type { ComputedRef, PropType } from 'vue';

import type { TaskManagementTaskModel } from '@/@types';
import { FormInput, FormError, IconsProvider } from '@/components';
import { required } from '@/helpers';
import { useProjectsStore } from '@/store';

const props = defineProps({
  taskData: {
    type: Object as PropType<TaskManagementTaskModel>,
    required: true,
  },
});

const rules = computed(() => ({
  title: {
    required,
  },
}));

const projectsStore = useProjectsStore();
const isLoading = ref<boolean>(false);
const isTitleEditing = ref<boolean>(false);
const data = reactive({
  title: props.taskData.title,
});

const editTitle = () => {
  isTitleEditing.value = true;
};

const cancelEdit = () => {
  isTitleEditing.value = false;
  data.title = props.taskData.title;
  v$.value.$reset();
};

const v$ = useVuelidate(rules, data);
const titleErrors: ComputedRef<string[]> = computed(() => {
  const errors = v$.value.title.$errors.map(
    (m: ErrorObject) => m.$message as string
  );
  return [...errors];
});

const updateTaskTitle = async () => {
  isLoading.value = true;
  const isValid = await v$.value.$validate();

  if (isValid) {
    if (await projectsStore.updateTaskTitle(props.taskData.id, data.title)) {
      isTitleEditing.value = false;
    }
  }

  isLoading.value = false;
};
</script>

<style scoped lang="scss">
h3 {
  padding: 0;
  margin: 0;
}

.task-title {
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &-edit {
    margin-right: app-padding(md);
    margin-bottom: app-padding(md);
    ion-button {
      margin: 0;
      margin-top: app-padding(md);
      --box-shadow: none;
      text-transform: unset;
      --border-radius: #{app-radius(sm)};
    }

    ion-button:last-child {
      margin-left: app-padding(md);
    }
  }
}
</style>
