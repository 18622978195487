import { isPlatform } from '@ionic/core';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

import { useAppStore } from './app.pinia';

import { RegistrationActivateStatusEnum } from '@/@enums';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  RegistrationFlags,
  RegistrationDataModel,
  RegistrationNetworkRules,
  PasswordSettingsModel,
  ResponseUserAvatarModel,
  ResponseShortUsersModel,
  UserMessageModel,
  ResponseGroupsShortModel,
  UserGroupShortModel,
  RegistrationSendCodeToEmailResponseModel,
  RegistrationActivateResponseModel,
  RegistrationActivateDataModel,
  ResponseRegistrationCoreTokenModel,
} from '@/@types';
import {
  defaultRegistrationData,
  defaultRegistrationFlags,
  defaultRegistrationPhone,
} from '@/models/registration';
import { $api } from '@/services';

export type RegistrationState = {
  data: RegistrationDataModel;
  flags: RegistrationFlags;
  loading: boolean;
  host: null | string;
  networkHome?: RegistrationNetworkRules;
  networkRequest?: RegistrationNetworkRules;
  passwordSettings?: PasswordSettingsModel;
  phone: {
    number: string;
    confirmationId: null | number;
    confirmationCode: string | null;
  };
  avatar: string | null;
  usersIdsToFollow: number[];
  groupsIdsToJoin: number[];
  userEmailToInvite: string | null;
  errors: ErrorMessageModel[];
};

export const useRegistrationStore = defineStore({
  id: 'registration',
  state: (): RegistrationState =>
    ({
      data: cloneDeep(defaultRegistrationData),
      flags: cloneDeep(defaultRegistrationFlags),
      loading: false,
      host: null,
      networkHome: undefined,
      networkRequest: undefined,
      passwordSettings: undefined,
      phone: cloneDeep(defaultRegistrationPhone),
      avatar: null,
      usersIdsToFollow: [],
      groupsIdsToJoin: [],
      userEmailToInvite: null,
      errors: [],
    }) as RegistrationState,
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
  },
  actions: {
    async sendCodeToEmail(email: string): Promise<boolean> {
      this.loading = true;
      this.errors = [];
      const response = await $api.account.registrationSendCodeToEmail(email);
      if (response.statusCode === 200) {
        const model = response as RegistrationSendCodeToEmailResponseModel;
        this.loading = false;
        this.flags.codeEmailed = true;
        this.data.id = model.data.inviteId;
        this.host = model.data.host;
        useAppStore().$patch(
          (state) => (state.url = 'https://' + model.data.host)
        );
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);

        this.flags.codeEmailed = false;
        this.data.id = null;
        this.host = null;
      }

      this.loading = false;
      return false;
    },

    async activateRegistration(
      id: number,
      hash: string
    ): Promise<RegistrationActivateDataModel | undefined> {
      this.loading = true;
      this.errors = [];
      const response = await $api.account.activateRegistration(id, hash);
      if (response.statusCode === 200) {
        const model = response as RegistrationActivateResponseModel;
        this.loading = false;

        if (
          model.data.status === RegistrationActivateStatusEnum.UserIsExits ||
          model.data.networkHome === null
        ) {
          // Регистрация недоступна
        } else {
          this.flags.registrationIsActive = true;
          this.passwordSettings = model.data.passwordSettings;
          this.networkHome = model.data?.networkHome
            ? model.data.networkHome
            : undefined;
          this.networkRequest = model.data?.networkRequest
            ? model.data.networkRequest
            : undefined;

          return model.data;
        }
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);

        this.flags.registrationIsActive = false;
      }

      this.loading = false;
      return undefined;
    },

    async registration(): Promise<boolean> {
      this.loading = true;
      this.errors = [];

      const data = {
        id: this.data.id,
        hash: this.data.hash,
        fullName: this.data.fullName,
        password: this.data.password,
        confirmPassword: this.data.confirmPassword,
        position: this.data.position,
        subDivisionTitle: this.data.subDivisionTitle,
        email: this.data.email,
      } as RegistrationDataModel;

      const response = await $api.account.registration(data);
      if (response.statusCode === 200) {
        const model = response as ResponseRegistrationCoreTokenModel;
        this.loading = false;
        this.flags.formEmailed = true;

        const clientSecret = isPlatform('ios')
          ? 'iPhone'
          : ('android' as string);

        useAppStore().$patch((state) => {
          state.grantType = 'HomeAuthorizationOld';
          state.clientSecret = clientSecret;
          state.code = model.data.homeAccessToken;
          state.codeValid = model.data.homeValidUntil;
          state.validUntil = model.data.validUntil;
          state.homeRowId = model.data.companyRowId;
          state.coreId = model.data.coreId;
          state.companyRowId = model.data.companyRowId;
          state.userId = model.data.userId;
          state.userRowId = model.data.userRowId;
          state.accessToken = model.data.accessToken;
        });
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);

        this.flags.formEmailed = false;
      }

      this.loading = false;
      return false;
    },

    async updateAvatarOnRegistration(image: File): Promise<boolean> {
      this.loading = true;
      this.errors = [];
      const response = await $api.account.registrationUpdateAvatar(image);
      if (response.statusCode === 200) {
        const model = response as ResponseUserAvatarModel;
        this.loading = false;
        this.avatar = model.data.url;
        this.flags.avatarIsUploaded = true;
        return true;
      } else {
        this.avatar = null;
        this.flags.avatarIsUploaded = false;

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      }

      this.loading = false;
      return false;
    },
    async getActiveUsersOnRegistration(): Promise<UserMessageModel[]> {
      this.loading = true;
      this.errors = [];
      const response = await $api.account.activeUsers();
      if (response.statusCode === 200) {
        const model = response as ResponseShortUsersModel;
        this.loading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return [];
    },
    async getMainGroupsOnRegistration(): Promise<UserGroupShortModel[]> {
      this.loading = true;
      this.errors = [];
      const response = await $api.account.mainGroups();
      if (response.statusCode === 200) {
        const model = response as ResponseGroupsShortModel;
        this.loading = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return [];
    },
    async inviteUserOnRegistration(email: string): Promise<boolean> {
      this.loading = true;
      this.errors = [];
      const response = await $api.user.inviteUser([email]);
      if (response.statusCode === 200) {
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },
  },
  persist: true,
});
