import type {
  SupportModel,
  ResponseErrorModel,
  ResponseSuccessModel,
} from '@/@types';
import axios from '@/services/axios';

export class ToolApiService {
  async sendToSupport(
    params: SupportModel
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/tools/sendToSupport', params);
  }
}
