export const enum FileStatusEnum {
  Init = 'init',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
  LargeFile = 'largeFile',
}

export const enum LoginTypeEnum {
  Form = 'form',
  Uri = 'uri',
}

export const enum MessageActionEnum {
  Reply = 1,
  Edit = 2,
  Copy = 3,
  Forward = 4,
  DeleteForMe = 5,
  DeleteForAll = 6,
  Delete = 7,
}

export enum DocEditAccessEnum {
  Off = 'Off',
  All = 'All',
  Administrators = 'Administrators',
  AllExceptExternal = 'AllExceptExternal',
}

export const enum MessageDeliveryStatusEnum {
  Draft = 'Draft',
  Error = 'Error',
  Delivered = 'Delivered',
  Read = 'Read',
  ReadAll = 'ReadAll',
  Deleted = 'Deleted',
}

export const enum MessageTypeEnum {
  Message = 'Message',
  CreateGroup = 'CreateGroup',
  AddParticipant = 'AddParticipant',
  RemoveParticipant = 'RemoveParticipant',
  RemoveYou = 'RemoveYou',
  YouNewAdmin = 'YouNewAdmin',
  SelfRemove = 'SelfRemove',
  FileVideo = 'FileVideo',
  FileImage = 'FileImage',
  FileDocument = 'FileDocument',
  Quote = 'Quote',
  Sticker = 'Sticker',
  Init = 'Init',
  Forward = 'Forward',
  ForwardOld = 'ForwardOld',
  /*
  TODO: Waiting for API - https://gitlab.united-grid.com/intra/core/-/issues/668
  InitVideoCall = 'InitVideoCall',
  EndVideoCall = 'EndVideoCall',
  */
}

export const enum ChainTypeEnum {
  Active = 'Active',
  Archive = 'Archive',
}

export const enum ThemeAppEnum {
  System = 'System',
  Light = 'Light',
  Dark = 'Dark',
}

export const enum SendKeyEnum {
  Enter = 'Enter',
  CtrlEnter = 'CtrlEnter',
  CmdEnter = 'CmdEnter',
}

export const enum UserProfileTabEnum {
  Posts = 'posts',
  Additional = 'additional',
}

export const enum UserProfileAdditionalEnum {
  Conversations = 'conversations',
  AboutMe = 'aboutMe',
  ReView = 'review',
  Followers = 'followers',
  Manager = 'manager',
  SubOrdinates = 'subordinates',
  Documents = 'documents',
}

export const enum MessengerChatTypeEnum {
  Chain = 'chain',
  User = 'user',
}

export const enum ConversationsEventAnswerEnum {
  Probably = 2,
  Yes = 1,
  No = 0,
  None = -1,
}

export const enum EventAttendingEnum {
  Yes = 'Attending',
  Probably = 'MaybeAttending',
  No = 'NotAttending',
}

export const enum GroupsAccessEnum {
  None = 0,
  SendRequest = 1,
  Member = 5,
  Admin = 10,
}

export const enum GroupsTypeEnum {
  PrivateVisible = 'PrivateVisible',
  PrivateHidden = 'PrivateHidden',
  Public = 'Public',
}

export const enum InterfaceSizeAppEnum {
  Small = 'small',
  Middle = 'middle',
  Large = 'large',
}

export const enum ConversationsTypeEnum {
  Text = 'Text',
  Announcement = 'Announcement',
  GroupCreated = 'GroupCreated',
  GroupDeleted = 'GroupDeleted',
  Badge = 'Badge',
  Event = 'Event',
  Poll = 'Poll',
  Idea = 'Idea',
  UserCreated = 'UserCreated',
  Task = 'Task',
}

export const enum ConversationsFlagEnum {
  UserProfile = 'UserProfile',
  GroupPage = 'GroupPage',
  FeedPage = 'FeedPage',
  ConversationPage = 'ConversationPage',
  CustomPage = 'CustomPage',
  EventModal = 'EventModal',
  SharedPost = 'SharedPost',
  SearchPage = 'SearchPage',
  IdeasPage = 'IdeasPage',
  ForceToReadModal = 'ForceToReadModal',
}

export const enum ConversationsTypeForButtonsEnum {
  Post = 'post',
  SharedMessage = 'shared',
  Comment = 'comment',
}

export const enum PushType {
  None = '0',
  Note = '1',
  Message = '2',
  AddedToGroup = '3',
  InvitedToGroup = '4',
  NewFollower = '5',
  ExternalUrl = '6',
  Wiki = '7',
  File = '8',
}

export const enum ValidationsEnum {
  Required = 'required',
  Email = 'email',
  Confirm = 'confirm',
  MinLength = 'minLength',
  LowerCase = 'lowerCase',
  UpperCase = 'upperCase',
  Numbers = 'numbers',
  Symbols = 'symbols',
  Unique = 'unique',
  NotSimple = 'notSimple',
}

export const enum UserPageInputLabelsEnum {
  FirstName = 'firstName',
  LastName = 'lastName',
  SubDivision = 'subDivision',
  Position = 'position',
  DateOfBirth = 'dateOfBirth',
  Email = 'email',
  PhoneMobile = 'phoneMobile',
  PhoneWork = 'phoneWork',
  AboutMe = 'aboutMe',
  Hobby = 'hobby',
  Education = 'education',
  WorkExperience = 'workExperience',
  Region = 'region',
  RoomLocation = 'roomLocation',
}

export const enum UserPagePhoneVisibleEnum {
  OnlyMe = 'onlyMe',
  All = 'all',
}

export const enum UserPageCategoriesEnum {
  Conversations = 'conversations',
  AboutMe = 'aboutMe',
  Followers = 'followers',
}

export const enum GroupPageTabEnum {
  Null = 'null',
  Conversations = 'conversations',
  Participants = 'participants',
  Files = 'files',
  Administration = 'administration',
  Projects = 'projects',
  Dashboard = 'dashboard',
}

export const enum AppMenuEnum {
  Feed = 'feed',
  People = 'people',
  Groups = 'groups',
  Docs = 'docs',
  Notifications = 'notifications',
  Messenger = 'messenger',
  Pages = 'pages',
  HomePage = 'homePage',
  Calendar = 'calendar',
  Search = 'search',
  Topics = 'topics',
  UsageRules = 'usageRules',
  Projects = 'projects',
  ProjectsStatistics = 'projectsStatisctics',
  Tasks = 'tasks',
  Milestones = 'milestones',
  AiAssistant = 'aiAssistant',
  Profile = 'profile',
  Ideas = 'ideas',
  More = 'more',
  Login = 'login',
  Networks = 'networks',
  Settings = 'Settings',
  Wiki = 'wiki',
  CustomLink = 'customLink',
  CustomPage = 'customPage',
  File = 'file',

  //NOTE: Admin block
  Admin = 'admin',
  AdminDesign = 'adminDesign',
  AdminNetworkSettings = 'adminNetworkSettings',
  AdminNetworkDomainList = 'adminNetworkDomainList',
  AdminBranding = 'adminBranding',
  AdminMobileApps = 'adminMobileApps',
  AdminUsageRules = 'adminUsageRules',
  AdminPasswordSettings = 'adminPasswordSettings',
  AdminApplications = 'adminApplications',
  AdminStatistics = 'adminStatistics',
  AdminBanner = 'adminBanner',
  AdminTags = 'adminTags',
  AdminUserManagement = 'adminUserManagement',
  AdminRestorePost = 'adminRestorePost',
  AdminBadges = 'adminBadges',
}

export const enum AppMenuLanguagesEnum {
  DE = 'de',
  EN = 'en',
  CS = 'cs',
  ES = 'es',
  FR = 'fr',
  RU = 'ru',
}

export const enum AppLoadingTypeEnum {
  NetworkSetting = 'networkSetting',
  OtherLoading = 'otherLoading',
}

export const enum AppAlertTypeEnum {
  UpdateApp = 'updateApp',
  OtherAlert = 'otherAlert',
}

export const enum MessengerListActionEnum {
  Mute = 1,
  UnMute = 2,
  Archive = 3,
  UnArchive = 4,
  Info = 5,
  Delete = 6,
  Exit = 7,
}

export const enum NotificationPushTypeEnum {
  Group = 'group',
  Message = 'message',
  Post = 'post',
  NewFollower = 'newFollower',
}

export const enum NotificationsTypeEnum {
  All = 'All',
  Unread = 'Unread',
  //Пользователя упомянули в сообщении
  MentionedInMessage = 'MentionedInMessage',
  //Пользователя добавили в группу
  AddedToGroup = 'AddedToGroup',
  //Поступило новое личное сообщение
  NewPrivateMessage = 'NewPrivateMessage',
  //Появился новый подписчик
  NewFollower = 'NewFollower',
  //Кто-то прокомментировал сообщение пользователя
  CommentToYourUserItem = 'CommentToYourUserItem',
  //Кто-то прокомментировал сообщение, которое я прокомментировал
  CommentAfterYouComment = 'CommentAfterYouComment',
  //Кто-то отметил комментарий пользователя как полезный
  MarkedCommentAsHelpful = 'MarkedCommentAsHelpful',
  //Кто-то отметил сообщение или комментарий как понравившиеся
  Liked = 'Liked',
  //Пользователем достигнут новый уровень.
  NewLevel = 'NewLevel',
  //Пользователя добавили в группу
  InvitationToGroup = 'InvitationToGroup',
  //Пользователь обновил файл
  NewFileVersion = 'NewFileVersion',
  //Пользователя упомянули в вики
  MentionedInWiki = 'MentionedInWiki',
  //Пользователь обновил вики
  NewWikiVersion = 'NewWikiVersion',
  //Новость в группе или в сети
  Announcement = 'Announcement',
  //Запрос на вступление в группу отклонен
  DenyRequestJoinToGroup = 'DenyRequestJoinToGroup',
  //Запрос на вступление в закрытую группу
  RequestJoinToGroup = 'RequestJoinToGroup',
  //Кто-то прокомментировал сообщение
  NewCommentToUserItem = 'NewCommentToUserItem',
  //Вам назначен новый руководитель
  NewManager = 'NewManager',
  //У вас появился новый подчиненный
  NewSubordinate = 'NewSubordinate',
  //Отложенный пост опубликован
  UserItemPublished = 'UserItemPublished',
  PlannedUserItemPublish = 'PlannedUserItemPublish',
  PlannedUserItemFailure = 'PlannedUserItemFailure',
  //Уведомление из внешнего приложения
  ExternalApplication = 'ExternalApplication',

  // Нотификации по таск менеджеру
  TaskCreated = 'TaskCreated',
  TaskClosed = 'TaskClosed',
  TaskReopened = 'TaskReopened',
  TaskArchived = 'TaskArchived',
  TaskDearchived = 'TaskDearchived',
  TaskTitleUpdated = 'TaskTitleUpdated',
  TaskDescriptionUpdated = 'TaskDescriptionUpdated',
  TaskAssigneeChanged = 'TaskAssigneeChanged',
  TaskMilestoneChanged = 'TaskMilestoneChanged',
  TaskDateStartUpdated = 'TaskDateStartUpdated',
  TaskDateDueUpdated = 'TaskDateDueUpdated',
  TaskTagsAdded = 'TaskTagsAdded',
  TaskTagsRemoved = 'TaskTagsRemoved',
  TaskFilesAdded = 'TaskFilesAdded',
  TaskFilesRemoved = 'TaskFilesRemoved',
  TaskWikiesAdded = 'TaskWikiesAdded',
  TaskWikiesRemoved = 'TaskWikiesRemoved',
  TaskLinksAdded = 'TaskLinksAdded',
  TaskLinksRemoved = 'TaskLinksRemoved',
  TaskParticipantsAdded = 'TaskParticipantsAdded',
  TaskParticipantsRemoved = 'TaskParticipantsRemoved',
  TaskCommentCreated = 'TaskCommentCreated',
  TaskCommentUpdated = 'TaskCommentUpdated',
  TaskCommentDeleted = 'TaskCommentDeleted',
  TaskMoved = 'TaskMoved',
  TaskReporterChanged = 'TaskReporterChanged',
}

export const enum NotificationsPushActionsEnum {
  None = 'None',
  Post = 'Post',
  PlannedPostCreated = 'PlannedPostCreated',
  Message = 'Message',
  AddedToGroup = 'AddedToGroup',
  InvitedToGroup = 'InvitedToGroup',
  NewFollower = 'NewFollower',
  ExternalUrl = 'ExternalUrl',
  Wiki = 'Wiki',
  File = 'File',
  Task = 'Task',
  TaskCreated = 'TaskCreated',
  TaskClosed = 'TaskClosed',
  TaskReopened = 'TaskReopened',
  TaskArchived = 'TaskArchived',
  TaskDearchived = 'TaskDearchived',
  TaskTitleUpdated = 'TaskTitleUpdated',
  TaskDescriptionUpdated = 'TaskDescriptionUpdated',
  TaskAssigneeChanged = 'TaskAssigneeChanged',
  TaskMilestoneChanged = 'TaskMilestoneChanged',
  TaskDateStartUpdated = 'TaskDateStartUpdated',
  TaskDateDueUpdated = 'TaskDateDueUpdated',
  TaskTagsAdded = 'TaskTagsAdded',
  TaskTagsRemoved = 'TaskTagsRemoved',
  TaskFilesAdded = 'TaskFilesAdded',
  TaskFilesRemoved = 'TaskFilesRemoved',
  TaskWikiesAdded = 'TaskWikiesAdded',
  TaskWikiesRemoved = 'TaskWikiesRemoved',
  TaskLinksAdded = 'TaskLinksAdded',
  TaskLinksRemoved = 'TaskLinksRemoved',
  TaskParticipantsAdded = 'TaskParticipantsAdded',
  TaskParticipantsRemoved = 'TaskParticipantsRemoved',
  TaskCommentCreated = 'TaskCommentCreated',
  TaskCommentUpdated = 'TaskCommentUpdated',
  TaskCommentDeleted = 'TaskCommentDeleted',
  TaskMoved = 'TaskMoved',
  TaskReporterChanged = 'TaskReporterChanged',
}

export const enum CoverImageTypeEnum {
  UserCover = 'userCover',
  GroupCover = 'groupCover',
}

export const enum AvatarTypeEnum {
  UserAvatar = 'userAvatar',
  GroupAvatar = 'groupAvatar',
  ChatAvatar = 'chatAvatar',
  UserAvatarOnRegistration = 'UserAvatarOnRegistration',
  NetworkLogo = 'NetworkLogo',
  NetworkFavicon = 'NetworkFavicon',
}

export const enum AppImageChangeMenuEnum {
  Upload = 0,
  Delete = 1,
  Open = 2,
}

export const enum DocumentTypeEnum {
  Wiki = 'Wiki',
  Folder = 'Folder',
  File = 'File',
  FileVersion = 'FileVersion',
  ExternalLink = 'ExternalLink',
}

export enum UserRoleEnum {
  ExternalGroupUserReadLike = 11,
  ExternalGroupUserLikeUpsert = 12,
  ExternalGroupUser = 19,
  UserReadLike = 21,
  UserLikeUpsert = 22,
  User = 30,
  Expert = 31,
  BadgesManager = 32,
  InnovationManager = 33,
  LightModerator = 79,
  Moderator = 85,
  FreeExtNetAdministrator = 93,
  Administrator = 95,
  SuperAdministrator = 100,
  SecurityManager = 101,
  System = 110,
  TenantInstanceManager = 120,
  InstanceManager = 130,
}

export enum FeedTypeEnum {
  Recommended = 'Recommended',
  AllPublic = 'AllPublic',
  Announcement = 'Announcement',
  Following = 'Following',
  MyBookmarks = 'MyBookmarks',
  Planned = 'Planned',
  AllMy = 'AllMy',
  Polls = 'Polls',
  Ideas = 'Ideas',
  ByGroup = 'ByGroup',
  ByTag = 'ByTag',
  ByWiki = 'ByWiki',
  ByFile = 'ByFile',
  ByUser = 'ByUser',
  Search = 'Search',
}

export enum IdeaTypeEnum {
  New = 'New',
  InProgress = 'InProgress',
  Realized = 'Realized',
  Archived = 'Archived',
  MostLiked = 'MostLiked',
  My = 'My',
}

export enum FeedContextMenuActionEnum {
  SelectType = 'selectType',
  MarkAllAsRead = 'markAllAsRead',
  SelectFilter = 'selectFilter',
  Reset = 'reset',
}

export enum WidgetFeedTypeEnum {
  AllPublic = 0,
  Recommended = 1,
  Following = 2,
  Polls = 4,
  MyBookmarks = 7,
  AllMy = 8,
  Ideas = 9,
  //AllPublicBadges =3,
  //AllPublicFiles = 5,
  //AllPublicWikis = 6,
  //AllCompany = ?,
  //AllPublicQuestions=?,
  //AllPublicEvents=?,
  //Incoming=?,
  //MyLikes=?,

  //Локальные варианты
  CustomGroup = 'CustomGroup',
  CustomTag = 'CustomTag',
}

export const enum PostMenuActionEnum {
  Open = 1,
  Delete = 2,
  Edit = 3,
  AddToBookmarks = 4,
  RemoveFromBookmarks = 5,
  Follow = 6,
  UnFollow = 7,
  Complain = 8,
  SendToEmail = 9,
  SendToExternalEmail = 10,
  Pin = 11,
  UnPin = 12,
  CopyText = 13,
  Share = 14,
  SendNow = 15,
  ReSchedule = 16,
  ShowViewers = 17,
  ShareArchiveLink = 18,
  DownloadAsPDF = 19,
  DownloadAsZIP = 20,
}

export const enum PostUploadFileEnum {
  UploadFromDevice = 1,
  UploadFromGroup = 2,
  CreateDocument = 3,
  CreateWiki = 4,
}

export const enum CommentActionEnum {
  Reply = 1,
  Delete = 2,
  CopyText = 3,
}

export const enum PostTextActionEnum {
  CopyText = 1,
}

export const enum PlannedPostActionEnum {
  SchedulePost = 1,
}

export const enum PlannedPostStatusEnum {
  Planned = 1,
}

export const enum PostTypeActionEnum {
  Text = 'text',
  Event = 'event',
  CalendarEvent = 'calendarEvent',
  Announcement = 'announcement',
  Poll = 'poll',
  Idea = 'idea',
  Task = 'task',
  Badge = 'badge',
  Comment = 'comment',
  TaskComment = 'taskComment',
  EditText = 'editText',
  EditEvent = 'editEvent',
  EditAnnouncement = 'editAnnouncement',
  EditPoll = 'editPoll',
  EditIdea = 'editIdea',
  EditBadge = 'editBadge',
}

export const enum PostComplainTypeEnum {
  RulesViolation = 1,
  SpamAndExperienceFarming = 2,
}

export const enum FileMenuActionEnum {
  Open = 1,
  Download = 2,
  Move = 3,
  Rename = 4,
  Delete = 5,
  Share = 6,
  NewVersion = 7,
  OpenInWindow = 8,
  ShareArchiveLink = 9,
  Edit = 10,
  ShowHistory = 11,
  Restore = 12,
  GoToCurrentVersion = 13,
  ShowRelations = 14,
  MarkOfficial = 15,
  Follow = 16,
  Unfollow = 17,
  ShowFollowers = 18,
}

export const enum WikiMenuActionEnum {
  Create = 'create',
  Open = 'open',
  Edit = 'edit',
  Move = 'move',
  MarkOfficial = 'markOfficial',
  ShowRelations = 'showRelations',
  ShowFollowers = 'showFollowers',
  ShowHistory = 'showHistory',
  RollbackVersion = 'rollbackVersion', //!
  CompareHistorical = 'compareHistorical',
  LockEdit = 'lockEdit', //!
  UnlockEdit = 'unlockEdit', //!
  Delete = 'delete',
  Follow = 'follow',
  Unfollow = 'unfollow',
}

export const enum WikiDownloadOptionsEnum {
  DownloadAsPDF = 'pdf',
  DownloadAsDOCX = 'docx',
}

export const enum WikiDeleteOptionsEnum {
  ReplaceFromDocBrowser = 'replaceFromDocBrowser',
  ReplaceWithNewWiki = 'replaceWithNewWiki',
  ReplaceWithNewFile = 'replaceWithNewFile',
}

export const enum WikiSaveModeEnum {
  Major = 'major',
  Minor = 'minor',
}

export const enum WikiEditOptionsEnum {
  None = 0,
  EditTitle = 1,
  ShowAuthor = 3,
  ShowLastEditor = 4,
  EditGroup = 5,
  EditFolder = 6,
  Outdated = 7,
}

export const enum WikiEditControlsEnum {
  GoToCurrentVersion = 'goToCurrentVersion',
  SaveAsTemplate = 'saveAsTemplate',
  UpdateTemplate = 'updateTemplate',
  UpdateDraft = 'updateDraft',
  DeleteDraft = 'deleteDraft',
  Delete = 'delete', // Same as WikiMenuActionEnum.Delete
}

export const enum DocsMenuActionEnum {
  UploadFile = 'uploadFile',
  UploadFolder = 'uploadFolder',
  CreateFile = 'createFile',
  CreateFolder = 'createFolder',
  CreateWiki = 'create',
}

export const enum DocsViewTypeEnum {
  Table = 'table',
  Grid = 'grid',
  Item = 'item',
}

export const enum DocBrowserModeEnum {
  All = 'All',
  Recent = 'Recent',
  Uploaded = 'Uploaded',
  Groups = 'Groups',
  Search = 'Search',
  Follow = 'Follow',
  QuickSearch = 'QuickSearch',
}

export const enum DocBrowserTypeEnum {
  Main = 'main',
  ByGroup = 'byGroup',
  ByFolder = 'byFolder',
}

export const enum DocsSortingTypeEnum {
  NameASC = 'nameASC',
  NameDESC = 'nameDESC',
  GroupASC = 'groupASC',
  GroupDESC = 'groupDESC',
  AuthorASC = 'authorASC',
  AuthorDESC = 'authorDESC',
  DateASC = 'dateASC',
  DateDESC = 'dateDESC',
  SizeASC = 'sizeASC',
  SizeDESC = 'sizeDESC',
}

export const enum DocsFilterTypeEnum {
  Author = 'author',
  Group = 'group',
  Date = 'date',
  SearchMode = 'searchMode',
  Type = 'type',
}

export const enum WikiVersionEnum {
  V1 = 1, //Simple
  V2 = 2, //Advanced
}

export const enum WikiJsonTextEnum {
  Head = 'head',
  Content = 'content',
  Body = 'body',
  Participants = 'participants',
}

export const enum ChatModalEnum {
  Home = 'home',
  Participants = 'participants',
  Administrators = 'administrators',
  AddParticipants = 'addParticipants',
  AddAdministrators = 'addAdministrators',
}

export const enum UserVerificationType {
  VerificationByCompanyEmailSuffix = 'VerificationByCompanyEmailSuffix',
  VerificationByUserParameter = 'VerificationByUserParameter',
  VerificationByUserSync = 'VerificationByUserSync',
  VerificationByOauth = 'VerificationByOauth',
}

export const enum ProfileEditEnum {
  None = 'None',
  Administrators = 'Administrators',
  All = 'All',
}

export const enum AllowMobileAppEnum {
  Off = 'Off',
  AdminOrHigher = 'AdminOrHigher',
  All = 'All',
}

export const enum DataViewMode {
  List = 'List',
  Grid = 'Grid',
}

export const enum GroupsFilterEnum {
  All = 'all',
  ByUser = 'byUser',
  Search = 'search',
  CanPost = 'canPost',
  Admin = 'admin',
}

export const enum UsersFilterEnum {
  UsersPage = 'usersPage',
  List = 'list',
  ByGroup = 'byGroup',
  Followers = 'followers',
  ChosenMentions = 'chosenMentions',
  ChosenOthers = 'chosenOthers',
  Search = 'search',
  Following = 'following',
  AllForAdmin = 'allForAdmin',
}

export const enum PagesFilterEnum {
  All = 'all',
  Search = 'search',
}

export const enum PostsModeEnum {
  Feed = 'Feed',
  ByGroup = 'ByGroup',
  ByUser = 'ByUser',
  Idea = 'Idea',
}

export const enum PostsFilterEnum {
  All = 'All',
  Read = 'Read',
  Unread = 'Unread',
}

export const enum MeetStatusEnum {
  Accept = 1,
  Reject = 2,
  Timeout = 3,
  BusyOnAnotherLine = 4,
  Connecting = 5,
}

export const enum CustomPageWidgetsEnum {
  WidgetHtml = 'WidgetHtml',
  WidgetSingleUserItem = 'WidgetSingleUserItem',
  WidgetPoll = 'WidgetPoll',
  WidgetBanner = 'WidgetBanner',
  WidgetPeople = 'WidgetPeople',
  WidgetTextBlock = 'WidgetTextBlock',
  WidgetFilesWikies = 'WidgetFilesWikies',
  WidgetGroups = 'WidgetGroups',
  WidgetBirthdays = 'WidgetBirthdays',
  WidgetFeed = 'WidgetFeed',
  WidgetCalendar = 'WidgetCalendar',
  WidgetInvite = 'WidgetInvite',
  WidgetGroupMembers = 'WidgetGroupMembers',
  WidgetGroupInformation = 'WidgetGroupInformation',
  WidgetGroupAdmins = 'WidgetGroupAdmins',
  WidgetSlider = 'WidgetSlider',
  WidgetPublisher = 'WidgetPublisher',
}

export const enum CustomPageWidgetsPositionEnum {
  LeftSidebarWidgets = 'leftSidebarWidgets',
  RightSidebarWidgets = 'rightSidebarWidgets',
  MainWidgets = 'mainWidgets',
}

export const enum HomePageEnum {
  Group = 'Group',
  CustomPage = 'CustomPage',
  DefaultNewsFeed = 'DefaultNewsFeed',
  Tag = 'Tag',
}

export const enum GroupInvitationStatusEnum {
  Join = 0,
  JoinedInGroup = 1,
  ReceiveInvitation = 2,
  SendInvite = 3,
  SendError = 4,
  NotAllowed = 5,
}

export const enum GroupInviteModeEnum {
  None = 0,
  AddById = 1,
  AddByEmail = 2,
  AddExternal = 3,
}

export const enum AllowExternalUsersToGroupEnum {
  Off = 'Off',
  NetworkAdmins = 'NetworkAdmins',
  GroupAdmins = 'GroupAdmins',
  All = 'All',
}

export const enum GroupAdministrationOptionsEnum {
  ShowMembers = 0,
  ShowInformation = 1,
  EnableLikes = 2,
  EnableComments = 3,
  EnableShared = 4,
}

export const enum ActionAccessEnum {
  NewVersion = 0,
  Rename = 1,
  Move = 2,
  Share = 3,
  Preview = 4,
  Delete = 5,
  Edit = 6,
  Like = 7,
  Comment = 8,
  Pin = 9,
  //? Who is 10
  ObserveViewers = 11,
}

export const enum NetworkChangeEnum {
  Messenger = 'messenger',
  Feed = 'feed',
  Menu = 'menu',
}

export const enum DefaultUserItemTypesEnum {
  Text = 4,
  Badge = 5,
  Poll = 6,
  Event = 7,
  Idea = 8,
  Announcement = 9,
  Task = 10,
}

export const enum EventCalendarSourceEnum {
  All = 1,
  My = 2,
  Groups = 3,
}

export const enum EventCalendarPeriodEnum {
  Month = 1,
  Period = 2,
}

export const enum WidgetCalendarPeriodEnum {
  Month = 1,
  Week = 2,
}

export const enum CalendarTypeEnum {
  CalendarPage = 'calendarPage',
  CustomPage = 'customPage',
}

export const enum CalendarViewModeEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export const enum CalendarCellActionEnum {
  CreateEvent = 1,
  ShowEvents = 2,
  ToDate = 3,
  Back = 4,
}

export const enum AppImageType {
  NetworkLogo = 0,
  ChatAvatar = 1,
}

export const enum ImageViewerMenuEnum {
  Download = 0,
  Share = 1,
  Delete = 2,
}

export const enum ForwardMessageTypeEnum {
  ToChain = 0,
  ToUser = 1,
}

export const enum PermissionTypes {
  Camera = 'camera',
  Files = 'files',
  Audio = 'audio',
}

export enum TopicsFilterEnum {
  Popular = 'Popular',
  Recommended = 'Recommended',
  Following = 'Following',
  Search = 'Search',
  SearchPage = 'SearchPage',
}

export enum TopicsSortTypeEnum {
  ByConversations = 'byConversations',
  ByTitle = 'byTitle',
}

export enum CallsEnum {
  Phone = 'phone',
  Mobile = 'mobile',
  Work = 'work',
  Application = 'application',
}

export enum SearchType {
  Posts = 'userItem',
  Users = 'people',
  Groups = 'group',
  Tags = 'tag',
  Wikis = 'wiki',
  Files = 'file',
  Pages = 'page',
}

export enum SearchUserItemTypes {
  Text = '4',
  Badge = '5',
  Poll = '6',
  PublisherEvent = '7',
  Idea = '8',
  Announcement = '9',
}

export const enum MessageDirectionTypeEnum {
  Outgoing = 'Outgoing',
  Incoming = 'Incoming',
}

export enum SavedContactResponseEnum {
  Saved = 'saved',
  Edited = 'edited',
  Exists = 'exists',
  Error = 'error',
}

export enum MessageDeleteEnum {
  ForAll = 'forAll',
  ForMe = 'forMe',
}

export enum PagesBackgroundEnum {
  Default = 'default',
  CustomImage = 'customImage',
}

export enum PageToPostCreateEnum {
  Feed = 'feed',
  Group = 'group',
  Tag = 'tag',
  Ideas = 'ideas',
  CustomPage = 'customPage',
  GroupDashboard = 'groupDashboard',
}

export enum IdeaStatusEnum {
  New = 'New',
  InProgress = 'InProgress',
  Realized = 'Realized',
  Archived = 'Archived',
}

export enum WikiLinkIdentifierEnum {
  User = 'u',
  Tag = 't',
  Group = 'g',
}

export enum OfficeDocumentCreateEnum {
  Text = 'text',
  Table = 'table',
  Presentation = 'presentation',
}

export enum TaskManagementColumnType {
  SingleTag = 'SingleTag',
  Opened = 'Opened',
  Closed = 'Closed',
  Plug = 'Plug',
  New = 'New',
}

export enum TaskManagementViewModeEnum {
  Board = 'board',
  List = 'list',
  Table = 'table',
}

export enum TaskManagementTaskModalMenuCategoryEnum {
  Features = 0,
  Actions = 1,
}

export enum TaskManagementTaskModalMenuItemActionEnum {
  Assigne = 0,
  Tag = 1,
  Date = 2,
  Attachments = 3,
  Milestone = 4,
  Move = 5,
  Copy = 6,
  Close = 7,
  Archive = 8,
  Share = 9,
  Delete = 10,
  Participants = 11,
  RemoveAssignee = 12,
  Notifications = 13,
}

export enum TaskManagementColumnActionEnum {
  Delete = 0,
  CreateNewTask = 1,
}

export enum TaskManagementMilestonesViewModeEnum {
  Closed = 'closed',
  Opened = 'opened',
}

export enum TaskManagementTasksPageTypeEnum {
  Author = 'author',
  Assignee = 'assignee',
}

export enum TaskManagementMainHeaderMenuEnum {
  SwitchProjects = 0,
  SwitchViewMode = 1,
  Expand = 2,
  CreateColumn = 3,
  CreateTask = 4,
  EditBoard = 5,
  SwitchMilestonesViewMode = 6,
  CreateMilestone = 7,
  OpenSortingMenu = 8,
  SetSort = 9,
}

export enum TaskManagementMainHeaderSearchModesEnum {
  Name = 'name',
  Assigne = 'assigne',
  Author = 'author',
  Tag = 'tag',
  Milestone = 'milestone',
}

export enum TaskManagementActiveSortTypeEnum {
  Milestones = 'milestones',
  Projects = 'projects',
  Tasks = 'tasks',
}

export enum TaskManagementTasksSortByEnum {
  Identifier = 'Identifier',
  Title = 'Title',
  DateCreated = 'DateCreated',
  DateLastUpdated = 'DateLastUpdated',
  DateStart = 'DateStart',
  DateDue = 'DateDue',
  MilestoneDateDue = 'MilestoneDateDue',
}

export enum TaskManagementMilestonesSortByEnum {
  Title = 'Title',
  DateStart = 'DateStart',
  DateDue = 'DateDue',
  DateCreated = 'DateCreated',
}

export enum TaskManagementProjectsSortByEnum {
  Title = 'Title',
  DateCreated = 'DateCreated',
  DateDue = 'DateDue',
  GroupTitle = 'GroupTitle',
}

export enum TaskManagementSortDirectionEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export enum TaskManagementTaskHistoryActionType {
  Created = 'Created',
  Closed = 'Closed',
  Reopened = 'Reopened',
  Archived = 'Archived',
  Dearchived = 'Dearchived',
  TitleUpdated = 'TitleUpdated',
  DescriptionUpdated = 'DescriptionUpdated',
  AssigneeChanged = 'AssigneeChanged',
  MilestoneChanged = 'MilestoneChanged',
  DateStartUpdated = 'DateStartUpdated',
  DateDueUpdated = 'DateDueUpdated',
  TagsAdded = 'TagsAdded',
  TagsRemoved = 'TagsRemoved',
  FilesAdded = 'FilesAdded',
  FilesRemoved = 'FilesRemoved',
  WikiesAdded = 'WikiesAdded',
  WikiesRemoved = 'WikiesRemoved',
  LinksAdded = 'LinksAdded',
  LinksRemoved = 'LinksRemoved',
  ParticipantsAdded = 'ParticipantsAdded',
  ParticipantsRemoved = 'ParticipantsRemoved',
  CommentCreated = 'CommentCreated',
  CommentUpdated = 'CommentUpdated',
  CommentDeleted = 'CommentDeleted',
  ReporterChanged = 'ReporterChanged',
}

export enum appInfoListEnum {
  Version = 'version',
  Build = 'build',
  CommitHash = 'commitHash',
  Core = 'core',
  Company = 'company',
  SignalR = 'signalR',
  Api = 'api',
  Vue = 'vue',
  Ionic = 'ionic',
  Capacitor = 'capacitor',
}

export enum taskManagementAccessLevelEnum {
  None = 'None',
  User = 'User',
  Administrator = 'Administrator',
}

export enum customPageInfoEditActionsEnum {
  None = 0,
  EditTitle = 1,
  EditMainAlias = 2,
  ShowAuthor = 3,
  ShowModifier = 4,
}

export enum customPageInfoEditControlsEnum {
  AccessEdit = 0,
  Delete = 1,
  ToDraft = 2,
  Publish = 3,
  Cancel = 4,
}

export enum customPageShowTypeEnum {
  NetworkUsersOnly = 0,
  ExternalUsersOnly = 1,
  AllRegisteredUsers = 2,
  Public = 3,
}

export enum aiAssistantAccessLevelEnum {
  None = 'None',
  User = 'User',
  Administrator = 'Administrator',
}

export const enum WidgetResourcesTypeItems {
  File = 'File',
  Wiki = 'Wiki',
  UserGroup = 'UserGroup',
  ExternalLink = 'ExternalLink',
  User = 'User',
  Folder = 'Folder',
}

export const enum AppFabButtonEnum {
  CreatePost = 'CreatePost',
  UploadDocument = 'UploadDocument',
  NewChat = 'NewChat',
  ScrollToBottom = 'ScrollToBottom',
  CreateEvent = 'CreateEvent',
  CreatePage = 'CreatePage',
  PageInfo = 'PageInfo',
  EditPage = 'EditPage',
  DashboardInfo = 'DashboardInfo',
  EditDashboard = 'EditDashboard',
  CreateMilestone = 'CreateMilestone',
  CreateProject = 'CreateProject',
  CreateTask = 'CreateTask',
  OpenAdminMenu = 'OpenAdminMenu',
}

export enum SectionTypeEnum {
  Favorites = 'Favorites',
  Helpful = 'Helpful',
  Groups = 'Groups',
}

export enum RegistrationStepsEnum {
  Email = 'Email',
  UsageRules = 'UsageRules',
  UserInfo = 'UserInfo',
  Phone = 'Phone',
  Avatar = 'Avatar',
  InviteUsers = 'InviteUsers',
  FollowToUsers = 'FollowToUsers',
  JoinToGroups = 'JoinToGroups',
}

export enum RegistrationActionEnum {
  NextStep = 'NextStep', // Cледующий шаг
  PreviousStep = 'PreviousStep', // Предыдущий шаг
  Skip = 'Skip', // Пропустить шаг
  SendCodeToEmail = 'SendCodeToEmail', // Отправить код на email
  ActivateRegistration = 'ActivateRegistration', // Активировать регистрацию
  SendCodeToPhone = 'SendCodeToPhone', // Отправить код на телефон
  ConfirmPhoneCode = 'ConfirmPhoneCode', // Подтвердить код с телефона
  UploadAvatar = 'UploadAvatar', // Загрузить аватар
  Registration = 'Registration', // Отправить основную форму и выполнить регистрацию
  FollowToUsers = 'FollowToUsers', // Подписаться на юзеров
  JoinToGroups = 'JoinToGroups', // Подать заявку на вступление в группу
  InviteUser = 'inviteUser', // Пригласить пользователя по email
  CompleteRegistration = 'CompleteRegistration', // Завершить регистрацию
}

export enum RegistrationActivateStatusEnum {
  UserIsExits = 'UserIsExits', // пользователь существует в текущей сети
  UserByForms = 'UserByForms', //домашний пользователь не найден
  UserInNetwork = 'UserInNetwork', //домашний пользователь существует - нужно авторизоваться
  UserByNetwork = 'UserByNetwork', //найдена домашняя сеть - регистрируем в ней
  UserByParameters = 'UserByParameters', //регистрация по спец параметрам
}

export enum CountriesEnum {
  Armenia = 'Armenia',
  Austria = 'Austria',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Canada = 'Canada',
  Czech = 'Czech',
  Germany = 'Germany',
  Kazakhstan = 'Kazakhstan',
  Russia = 'Russia',
  Uk = 'Uk',
  Usa = 'Usa',
}

export enum BreakpointsEnum {
  None = '',
  XS = 'Xs',
  '2XS' = '2Xs',
  SM = 'Sm',
  MD = 'Md',
  LG = 'Lg',
  XL = 'Xl',
  '2XL' = '2Xl',
  '3XL' = '3Xl',
  '4XL' = '4Xl',
}

export enum AppBarEnum {
  Refresh = 'Refresh',
  Search = 'Search',
  ViewMode = 'ViewMode',
  SortingType = 'SortingType',
  SortingDirection = 'SortingDirection',
  FilterType = 'FilterType',
  Create = 'Create',
  Menu = 'Menu',
}

export enum AppCardsActionEnum {
  None,
  GroupJoin,
  GroupLeave,
  GroupSendRequest,
  UserFollow,
  UserUnfollow,
  Open,
  ChangeNetwork,
  OpenRequested,
}

export enum UploadFileTypes {
  //NOTE: One any file
  SingleAnyFile = 0,
  //NOTE: A lot of different files
  ManyDifferentFiles = 1,
  //NOTE: Just one picture
  SingleImage = 2,
  //NOTE: Pictures only
  ManyImages = 3,
  //NOTE: Only one video
  SingleVideo = 4,
  //NOTE: Video only
  ManyVideos = 5,
  //NOTE: Ai supported files
  AiSupportedFiles = 6,
  //NOTE: Ai supported images
  AiSupportedImages = 7,
}

export enum ResponseErrorEnum {
  invalidAcceptPolicy = 'invalid_accept_policy',
  accessDenied = 'access_denied',
  accessDeniedForUser = 'access_denied_for_user',
}

export enum ShareArchiveLinkType {
  UserItem = 'UserItem',
  File = 'File',
  Folder = 'Folder',
  Wiki = 'Wiki',
}

export enum UserAdminAccessLevel {
  None,
  All,
  Administrator,
}

export enum BadgeDisplayType {
  Post,
  Profile,
  UserName,
  Modal,
}

export const enum CallStartStatusEnum {
  Start = 100,
  Connect = 200,
}

export enum SelfInviteAllowedEnum {
  BlackListEmail = -1,
  WhiteListEmail = 0,
  RegisterInHomeNetwork = 1,
}

export enum AiModeEnum {
  Default,
  ImageVariation,
  ImageEditing,
  ImageGeneration,
  // ImageRecognition,
}

export enum DocumentExtensionEnum {
  PDF = 'pdf',
  ZIP = 'zip',
  DOCX = 'docx',
}

export enum SettingsTypeEnum {
  Theme,
  Background,
  Sound,
  Search,
  Sending,
  Lang,
  Cache,
  NetworkRules,
  AboutApp,
  InterfaceSize,
  Notifications,
  PostsOnBehalf,
  AddToGroup,
}

export enum SettingsActionEnum {
  None,
  ClearCache,
  OpenUsageRules,
  Support,
  OpenNotifications,
}

export enum RequirementsEnum {
  All,
  UsageRules,
  Password,
  ForceToRead,
}

export enum DigestSubscribeEnum {
  Never,
  Daily,
  Weekly,
  Manual,
}

export enum RegistrationModeEnum {
  Domain = 'Domain',
  Sync = 'Sync',
  UserParameters = 'UserParameters',
  Oauth = 'Oauth',
  All = 'All',
}

export enum OrgChartTypeEnum {
  Off = 'Off',
  Social = 'Social',
  ActiveDirectory = 'ActiveDirectory',
}

export enum UserGroupRoleEnum {
  StandardUser = 'User',
  CanLikeAndComment = 'UserLikeUpsert',
  CanOnlyReadAndLike = 'UserReadLike',
  ExternalGroupUser = 'ExternalGroupUser',
  ExternalUserLikeAndComment = 'ExternalGroupUserLikeUpsert',
  ExternalUserReadAndLike = 'ExternalGroupUserReadLike',
}

export enum AccessByRoleEnum {
  Off = 'Off',
  AllUsers = 'AllUsers',
  ModeratorOrHigher = 'ModeratorOrHigher',
  AdminOrHigher = 'AdminOrHigher',
}

export enum UseritemLinkExpirationEnum {
  Never = 'Never',
  OneHour = 'OneHour',
  TwelveHours = 'TwelveHours',
  OneDay = 'OneDay',
  OneWeek = 'OneWeek',
  TwoWeeks = 'TwoWeeks',
  FourWeeks = 'FourWeeks',
}

export enum ColorPickerDisplayType {
  PaletteSelect,
  AdvancedColorSelect,
}

export enum DownloadPostAccessLevel {
  None,
  All,
  Administrator,
}

export enum AdminUserManagementMenuEnum {
  OpenProfile,
  EditRole,
  RestorePassword,
  SetPassword,
  EditAccess,
}

export enum UserAccessEnum {
  None,
  Activate,
  Deactivate,
  Block,
  Unblock,
  Delete,
  DeleteAnonim,
}

export enum ActivityTypeEnum {
  Never = 'Never',
  Daily = 'Daily',
  Weekly = 'Weekly',
}

export enum UserStatusEnum {
  Normal,
  Banned,
  Deleted,
}

export enum DeletionUserKindEnum {
  Basic,
  Anonymize,
  WipeData,
}

export enum AutoUpdateStatusEnum {
  Done = 0,
  Processing = 1,
  Fail = 2,
}

export enum BasicEditControlsEnum {
  Cancel = 0, // Cancel
  Default = 1, //NOTE: Default one - always available
  Optional = 2, //NOTE: Optional one - available only in certain cases
}

export enum EventBusEnum {
  ResetAppBarSearch = 'ResetAppBarSearch',
}

export enum ChangeTypeEnum {
  RollBack = 0,
  Publish = 1,
}
