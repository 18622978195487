import axios from '@/services/axios';

interface ToolbarGroup {
  name?: string;
  items: string[];
}

interface IRichText {
  _getHiddenElement(htmlString: string): HTMLElement;
  preprocessInternalLinks(element: HTMLElement): void;
  restoreInternalLinks(element: HTMLElement): void;
  preprocessVideo(element: HTMLElement): Promise<void>;
  restoreVideoProcessing(element: HTMLElement): void;
  preprocessBody(htmlString: string): Promise<string>;
  preSubmit(htmlString: string): string;
  getPlugins(isSimpleMode?: boolean): string[];
  getToolbar(isSimpleMode?: boolean): ToolbarGroup[];
}

export function useRichText(): IRichText {
  const _getHiddenElement = (htmlString: string): HTMLElement => {
    const parsedJson = JSON.parse(JSON.stringify(htmlString));
    const tempElement = document.createElement('div');
    tempElement.style.visibility = 'hidden';
    tempElement.innerHTML = parsedJson;

    return tempElement;
  };

  const preprocessInternalLinks = (element: HTMLElement): void => {
    const pattern = /^[ugt]:\d+$/;
    const internalLinks = Array.from(element.querySelectorAll('a')).filter(
      (link) => pattern.test(link.getAttribute('href') || '')
    );

    for (const link of internalLinks) {
      const href = link.getAttribute('href');
      const [identifier, id] = href?.split(':') as unknown as string;
      const routerNameMapper = {
        u: `/user/${id}`,
        g: `/group/${id}`,
        t: `/topic/${id}`,
      };
      link.setAttribute(
        'href',
        routerNameMapper[identifier as 'u' | 'g' | 't']
      );
    }
  };

  const restoreInternalLinks = (element: HTMLElement): void => {
    const routerPathMatcher = /^\/(user|group|topic)\/(\d+)$/;
    const internalLinks = Array.from(element.querySelectorAll('a')).filter(
      (link) => routerPathMatcher.test(link.getAttribute('href') || '')
    );

    for (const link of internalLinks) {
      const href = link.getAttribute('href');
      const matchResult = href?.match(routerPathMatcher);
      if (matchResult) {
        const [, routerName, id] = matchResult;
        const routerIdentifierMapper = {
          '/user': 'u',
          '/group': 'g',
          '/topic': 't',
        };
        const identifier =
          routerIdentifierMapper[
            `/${routerName}` as '/user' | '/group' | '/topic'
          ];
        if (identifier) {
          link.setAttribute('href', `${identifier}:${id}`);
        }
      }
    }
  };

  const preprocessVideo = async (element: HTMLElement): Promise<void> => {
    const medias = element.querySelectorAll('video');

    for (const media of medias) {
      const sourceElement = media.querySelector('source');

      if (sourceElement) {
        const videoUrl = sourceElement.getAttribute('src');

        const response = await axios.get(videoUrl as string, {
          responseType: 'blob',
        });

        sourceElement.setAttribute('data-original-src', videoUrl as string);
        const blobUrl = URL.createObjectURL(response as unknown as Blob);
        sourceElement.setAttribute('src', blobUrl);
      }
    }
  };

  const restoreVideoProcessing = (element: HTMLElement): void => {
    const medias = element.querySelectorAll('video');

    for (const media of medias) {
      const sourceElement = media.querySelector('source');

      if (sourceElement) {
        const originalVideoUrl =
          sourceElement.getAttribute('data-original-src') ||
          sourceElement.getAttribute('alt');

        if (originalVideoUrl) {
          const regex = /filename=([^&]+)/;
          const match = originalVideoUrl.match(regex);

          if (match?.[1]) {
            const filename = match[1];

            sourceElement.setAttribute('src', `i:${filename}`);
            sourceElement.removeAttribute('data-original-src');
          }
        }
      }
    }
  };

  const preprocessBody = async (htmlString: string): Promise<string> => {
    // console.log('≥≥≥preprocessBody - htmlString:', htmlString); //! DEBUG
    const tempElement = _getHiddenElement(htmlString);

    /*
    TODO: delete if images get without auth token
    const imageTags = tempElement.querySelectorAll('img');

    for (const image of imageTags) {
      try {
        const response = await axios.get(image.alt, {
          responseType: 'blob',
        });

        const blobUrl = URL.createObjectURL(response as unknown as Blob);
        image.setAttribute('data-remote-url', image.alt);
        image.setAttribute('src', blobUrl);
      } catch (error) {
        console.error('Image fetch error:', error);
      }
    }
    */

    preprocessInternalLinks(tempElement);
    await preprocessVideo(tempElement);
    // console.log('≥≥≥preprocessBody - tempElement:', tempElement); //! DEBUG
    return tempElement.innerHTML;
  };

  const preSubmit = (htmlString: string): string => {
    const tempElement = _getHiddenElement(htmlString);

    const imageTags = tempElement.querySelectorAll('img');

    for (const image of imageTags) {
      const remoteUrl =
        image.getAttribute('data-remote-url') || image.getAttribute('alt');

      const regex = /\/media\/image/;
      const match = regex.test(remoteUrl || '');

      if (match) {
        image.setAttribute('src', remoteUrl as string);
        image.removeAttribute('data-remote-url');
      }
    }

    restoreInternalLinks(tempElement);
    restoreVideoProcessing(tempElement);
    return tempElement.innerHTML;
  };

  // https://www.tiny.cloud/docs/tinymce/latest/available-toolbar-buttons/#the-core-toolbar-buttons
  const getPlugins = (isSimpleMode = false): string[] => {
    return [
      'accordion',
      'autolink',
      'charmap',
      'directionality',
      'insertdatetime',
      'searchreplace',
      'visualchars',
      'advlist',
      'autoresize',
      'code',
      'link',
      'nonbreaking',
      'table',
      'wordcount',
      'anchor',
      // 'autosave',
      'codesample',
      'fullscreen',
      'importcss',
      'lists',
      'pagebreak',
      'save',
      'visualblocks',
      ...(!isSimpleMode ? ['media', 'preview', 'image'] : []),
      // ...(!isSimpleMode ? ['media', 'preview', 'image', 'quickbars'] : []), // #472 - 17.07.24 - Jakov
    ];
  };

  // https://www.tiny.cloud/docs/tinymce/latest/available-toolbar-buttons/#the-core-toolbar-buttons
  const getToolbar = (isSimpleMode = false): ToolbarGroup[] => {
    return [
      { name: 'history', items: ['undo', 'redo'] },
      { name: 'styles', items: ['blocks'] },
      {
        name: 'formatting',
        items: ['bold', 'italic', 'removeformat'],
        // items: ['bold', 'italic', 'underline', 'strikethrough', 'removeformat'], // #472 - 17.07.24 - Jakov
      },
      // { name: 'colors', items: ['forecolor', 'backcolor'] }, // #472 - 17.07.24 - Jakov
      {
        name: 'alignment',
        items: [
          'alignleft',
          'aligncenter',
          'alignright',
          'alignjustify',
          // 'alignnone', // #472 - 17.07.24 - Jakov
        ],
      },
      { name: 'indentation', items: ['outdent', 'indent'] },
      { name: 'lists', items: ['bullist', 'numlist'] },
      // {
      //   name: 'fonts',
      //   items: ['fontfamily', 'fontsize', 'fontsizeinput'],
      // }, // #472 - 17.07.24 - Jakov
      // { name: 'language', items: ['language', 'lineheight'] }, // #472 - 17.07.24 - Jakov
      // { name: 'headings', items: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }, // #472 - 17.07.24 - Jakov
      // { name: 'misc', items: ['selectall', 'remove', 'visualaid'] }, // #472 - 17.07.24 - Jakov
      ...(!isSimpleMode
        ? [
            { name: 'media', items: ['link', 'image', 'media'] },
            { name: 'advanced', items: ['table', 'fullscreen'] },
            // { name: 'insert', items: ['hr', 'blockquote'] }, // #472 - 17.07.24 - Jakov
            // { name: 'document', items: ['newdocument', 'print'] }, // #472 - 17.07.24 - Jakov
            // { name: 'clipboard', items: ['cut', 'copy', 'paste', 'pastetext'] }, // #472 - 17.07.24 - Jakov
            // { name: 'advanced', items: ['table', 'codesample', 'fullscreen'] }, // #472 - 17.07.24 - Jakov
            // { name: 'scripts', items: ['subscript', 'superscript'] }, // #472 - 17.07.24 - Jakov
          ]
        : []),
    ];
  };

  return {
    _getHiddenElement,
    preprocessInternalLinks,
    restoreInternalLinks,
    preprocessVideo,
    restoreVideoProcessing,
    preprocessBody,
    preSubmit,
    getPlugins,
    getToolbar,
  };
}
