<template>
  <div class="user-created">
    <conversations-text
      :text-data="newUserData"
      :conversations-flag="conversationsFlag"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import type { ConversationsFlagEnum } from '@/@enums';
import { ConversationsText } from '@/components';

const props = defineProps({
  newUserData: {
    type: String,
    required: true,
  },
  conversationsFlag: {
    type: String as PropType<ConversationsFlagEnum>,
    required: true,
  },
});

const newUserData: ComputedRef<string> = computed(() => props.newUserData);
</script>
<style scoped lang="scss">
.user-created {
  margin-top: 1rem;
}
</style>
