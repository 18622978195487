<template>
  <div
    v-infinite-scroll="[
      onLoadMore,
      { distance: 50, canLoadMore: () => canLoadMore },
    ]"
    class="load-more"
  >
    <icons-provider
      v-if="isLoading"
      :icon-props="{ width: '24', height: '24' }"
      name="dotsAnim"
      fill="var(--ion-color-medium)"
    />
  </div>
</template>

<script lang="ts" setup>
import { vInfiniteScroll } from '@vueuse/components';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { IconsProvider } from '@/components';

const props = defineProps({
  //NOTE: Link to the next page
  loadMoreUrl: {
    type: null as unknown as PropType<null | string>,
    required: true,
  },
  //NOTE: Flag of loading
  isLoading: {
    type: Boolean,
    required: true,
  },
  //NOTE: Special parameter that disables the requirement for the link of the next page
  withoutUrl: {
    type: Boolean,
    default: () => false,
  },
});

const canLoadMore: ComputedRef<boolean> = computed(
  () => !props.isLoading && props.loadMoreUrl !== null
);

const emit = defineEmits(['onLoadMore']);
const onLoadMore = async () => {
  //NOTE: If the link is not passed to the component, check by a special flag whether to start the loader
  if (props.withoutUrl) {
    return emit('onLoadMore');
  } else {
    //NOTE: If there is a link, start the loader
    if (props.loadMoreUrl) {
      //TODO: Add a logic to check the url format itself
      return emit('onLoadMore');
    }
  }
};
</script>

<style scoped lang="scss">
.load-more {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: app-padding(lg);
  padding-top: app-padding(lg);
  background: transparent;
}
</style>
