import {
  cloneDeep,
  compact,
  filter,
  find,
  findIndex,
  get,
  includes,
  indexOf,
  orderBy,
  remove,
  unionBy,
  uniq,
  flatMap,
  some,
  difference,
  map,
} from 'lodash';
import { defineStore } from 'pinia';

import type { EntityState } from '.';

import {
  TaskManagementColumnType,
  TaskManagementMilestonesViewModeEnum,
  TaskManagementTasksPageTypeEnum,
  TaskManagementViewModeEnum,
} from '@/@enums';
import type {
  ErrorMessageModel,
  ProjectEntity,
  TopicEntity,
  TaskManagementTaskModel,
  TaskManagementDragCardModel,
  TaskManagementMilestoneModel,
  UserMessageModel,
  GroupEntity,
  TaskManagementMainHeaderTasksSearchModel,
  TasksIdsModel,
  ShortTasksModel,
  ResponseProjectModel,
  ResponseErrorModel,
  ResponseProjectsModel,
  ResponseMilestonesModel,
  ResponseMilestoneModel,
  TaskManagementTaskCommentDataModel,
  ResponseTasksModel,
  ShortMilestonesModel,
  MilestonesIdsModel,
  TaskManagementCreateMilestoneRequestModel,
  ProjectsIdsModel,
  ShortProjectsModel,
  TaskManagementCreateTaskRequestModel,
  TaskManagementUpdateMilestoneRequestModel,
  TaskManagementMainHeaderMilestonesSearchModel,
  ResponseTaskModel,
  ResponseTaskCreateDataModel,
  RequestTaskCommentCreateModel,
  ResponseTaskCommentCreateModel,
  ResponseTasksByColumnModel,
  ResponseTaskManagementBoardModel,
  TaskManagementBoardModel,
  TaskManagementColumnModel,
  TaskManagementSortingModel,
} from '@/@types';
import { isNativeMobile, isWebMobile } from '@/helpers';
import {
  defaultPost,
  defaultTaskManagementMilestonesIdsModel,
  defaultTaskManagementMilestonesSearchModel,
  defaultTaskManagementProject,
  defaultTaskManagementProjectsIdsModel,
  defaultTaskManagementTasksIdsModel,
  defaultTaskManagementTasksSearchModel,
  defaultTaskManagementDragCardModel,
  defaultTaskManagementSortingModel,
} from '@/models';
import { $api } from '@/services';
import { usePostStore, useTopicStore } from '@/store';

interface ProjectsState extends EntityState<ProjectEntity> {
  errors: ErrorMessageModel[];
  loading: boolean;

  projectsIds: ProjectsIdsModel;

  tasks: TaskManagementTaskModel[];
  tasksIds: TasksIdsModel;

  milestones: TaskManagementMilestoneModel[];
  milestonesIds: MilestonesIdsModel;

  colors: string[];
  viewMode: TaskManagementViewModeEnum;
  collapsedColumns: number[];
  milestonesViewMode: TaskManagementMilestonesViewModeEnum;
  selectedProjectToCreateTask: ProjectEntity | null;

  // Тип поиска в шапке
  tasksSearch: TaskManagementMainHeaderTasksSearchModel;
  milestonesSearch: TaskManagementMainHeaderMilestonesSearchModel;

  //Режим отображения на странице задач
  tasksListType: TaskManagementTasksPageTypeEnum;

  //Способы сортировки
  sorting: TaskManagementSortingModel;

  //Проверка на запрашиваемые майлстоуны, если их еще нет в сторе (чтобы не вызывать повторные запросы).
  //Может быть убрано, когда будет реализована отмена повторящихся запросов на уровне axios.
  activeMilestonesRequests: number[];
  activeProjectsRequests: number[];

  isCardDrag: boolean;
}

export const useProjectsStore = defineStore({
  id: 'projects',
  state: (): ProjectsState => ({
    errors: [],
    loading: false,
    data: [],
    projectsIds: cloneDeep(defaultTaskManagementProjectsIdsModel),
    tasks: [],
    tasksIds: cloneDeep(defaultTaskManagementTasksIdsModel),
    milestones: [],
    viewMode:
      isWebMobile || isNativeMobile
        ? TaskManagementViewModeEnum.List
        : TaskManagementViewModeEnum.Board,
    collapsedColumns: [],
    milestonesViewMode: TaskManagementMilestonesViewModeEnum.Opened,
    selectedProjectToCreateTask: null,
    tasksSearch: cloneDeep(defaultTaskManagementTasksSearchModel),
    milestonesSearch: cloneDeep(defaultTaskManagementMilestonesSearchModel),
    milestonesIds: cloneDeep(defaultTaskManagementMilestonesIdsModel),
    colors: import.meta.env.VITE_APP_SELECTABLE_COLORS.match(
      /rgb\(\d+,\s*\d+,\s*\d+\)/g
    ),
    tasksListType: TaskManagementTasksPageTypeEnum.Assignee,
    sorting: cloneDeep(defaultTaskManagementSortingModel),
    activeMilestonesRequests: [],
    activeProjectsRequests: [],
    isCardDrag: false,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },

    getCurrentProject: (state): ProjectEntity => {
      const index = state.data.findIndex(
        (n) => n.id === state.projectsIds.currentId
      );
      const result = cloneDeep(defaultTaskManagementProject) as ProjectEntity;
      if (~index) {
        return state.data[index];
      } else {
        return result;
      }
    },

    getAllProjects: (state): ShortProjectsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortProjectsModel;
      const data = orderBy(state.data, (obj) =>
        indexOf(state.projectsIds.all.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.projectsIds.all.ids, obj.id)
      );
      result.loadMoreUrl = state.projectsIds.all.loadMoreUrl;
      return result;
    },

    getSearchedProjects: (state): ShortProjectsModel => {
      const result = { data: [], loadMoreUrl: null } as ShortProjectsModel;
      const data = orderBy(state.data, (obj) =>
        indexOf(state.projectsIds.search.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.projectsIds.search.ids, obj.id)
      );
      result.loadMoreUrl = state.projectsIds.search.loadMoreUrl;
      return result;
    },

    getProjectById:
      (state) =>
      (projectId: number): ProjectEntity | undefined =>
        find(state.data, (project) => project.id === projectId),

    getProjectsByGroupId:
      (state) =>
      (groupId: number): ShortProjectsModel => {
        const index = state.projectsIds.groups.findIndex(
          (n) => n.groupId === groupId
        );
        const result = { data: [], loadMoreUrl: null } as ShortProjectsModel;
        if (~index) {
          const data = orderBy(state.data, (obj) =>
            indexOf(state.projectsIds.groups[index].ids, obj.id)
          );
          result.data = filter(data, (obj) =>
            includes(state.projectsIds.groups[index].ids, obj.id)
          );
          result.loadMoreUrl = state.projectsIds.groups[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },

    getProjectIdByTaskId:
      (state) =>
      (taskId: number): number | undefined => {
        const task = state.tasks.find((n) => n.id === taskId);
        if (task) {
          return task.projectId;
        } else {
          return undefined;
        }
      },

    getCollapsedColumnsIds: (state): number[] => state.collapsedColumns,

    getAllCards: (state): TaskManagementTaskModel[] => state.tasks,

    getCardsByIds:
      (state) =>
      (cardsIds: number[]): TaskManagementTaskModel[] | [] =>
        filter(state.tasks, (card) => cardsIds.includes(card.id)),

    getTaskById:
      (state) =>
      (taskId: number): TaskManagementTaskModel | undefined =>
        find(state.tasks, (task) => task.id === taskId),

    getTaskByIndex:
      (state) =>
      (index: number): TaskManagementTaskModel | undefined =>
        state.tasks[index],

    getTasksSearchQuery: (state): string => state.tasksSearch.searchQuery,
    getMilestonesSearchQuery: (state): string =>
      state.milestonesSearch.searchQuery,

    getSearchedTasks: (state): ShortTasksModel => {
      const result = { data: [], loadMoreUrl: null } as ShortTasksModel;
      const data = orderBy(state.tasks, (obj) =>
        indexOf(state.tasksIds.search.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.tasksIds.search.ids, obj.id)
      );
      result.loadMoreUrl = state.tasksIds.search.loadMoreUrl;
      return result;
    },

    getTasksByProjectId:
      (state) =>
      (projectId: number): ShortTasksModel => {
        const index = state.tasksIds.projects.findIndex(
          (n) => n.projectId === projectId
        );
        const result = { data: [], loadMoreUrl: null } as ShortTasksModel;
        if (~index) {
          const data = orderBy(state.tasks, (obj) =>
            indexOf(state.tasksIds.projects[index].ids, obj.id)
          );
          result.data = filter(data, (obj) =>
            includes(state.tasksIds.projects[index].ids, obj.id)
          );
          result.loadMoreUrl = state.tasksIds.projects[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },

    getTasksByAuthorId:
      (state) =>
      (authorId: number): ShortTasksModel => {
        const index = state.tasksIds.authors.findIndex(
          (n) => n.authorId === authorId
        );
        const result = { data: [], loadMoreUrl: null } as ShortTasksModel;
        if (~index) {
          const data = orderBy(state.tasks, (obj) =>
            indexOf(state.tasksIds.authors[index].ids, obj.id)
          );
          result.data = filter(data, (obj) =>
            includes(state.tasksIds.authors[index].ids, obj.id)
          );
          result.loadMoreUrl = state.tasksIds.authors[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },

    getTasksByAssigneeId:
      (state) =>
      (assigneeId: number): ShortTasksModel => {
        const index = state.tasksIds.assignees.findIndex(
          (n) => n.assigneeId === assigneeId
        );
        const result = { data: [], loadMoreUrl: null } as ShortTasksModel;
        if (~index) {
          const data = orderBy(state.tasks, (obj) =>
            indexOf(state.tasksIds.assignees[index].ids, obj.id)
          );
          result.data = filter(data, (obj) =>
            includes(state.tasksIds.assignees[index].ids, obj.id)
          );
          result.loadMoreUrl = state.tasksIds.assignees[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },

    getTasksByColumnId:
      (state) =>
      (columnId: number): ShortTasksModel => {
        const index = state.tasksIds.columns.findIndex(
          (n) => n.columnId === columnId
        );
        const result = {
          data: [],
          loadMoreUrl: null,
          totalCount: 0,
          isLoading: false,
        } as ShortTasksModel;
        if (~index) {
          const data = orderBy(state.tasks, (obj) =>
            indexOf(state.tasksIds.columns[index].ids, obj.id)
          );
          result.data = filter(data, (obj) =>
            includes(state.tasksIds.columns[index].ids, obj.id)
          );
          result.loadMoreUrl = state.tasksIds.columns[index].loadMoreUrl;
          result.totalCount = state.tasksIds.columns[index].totalCount;
          result.isLoading = state.tasksIds.columns[index].isLoading;
          return result;
        } else {
          return result;
        }
      },

    getAllMilestones: (state): TaskManagementMilestoneModel[] =>
      state.milestones,

    getMilestonesByIds:
      (state) =>
      (milestonesIds: number[]): TaskManagementMilestoneModel[] | [] =>
        filter(state.milestones, (milestone) =>
          milestonesIds.includes(milestone.id)
        ),

    getMilestoneById:
      (state) =>
      (milestoneId: number): TaskManagementMilestoneModel | undefined =>
        find(state.milestones, (milestone) => milestone.id === milestoneId),

    getAllMilestonesByCurrentProject: (state) => (): ShortMilestonesModel => {
      const index = state.milestonesIds.projects.findIndex(
        (n) => n.projectId === state.projectsIds.currentId
      );
      const result = { data: [], loadMoreUrl: null } as ShortMilestonesModel;
      if (~index) {
        const data = orderBy(state.milestones, (obj) =>
          indexOf(state.milestonesIds.projects[index].ids, obj.id)
        );
        result.data = filter(data, (obj) =>
          includes(state.milestonesIds.projects[index].ids, obj.id)
        );
        result.loadMoreUrl = state.milestonesIds.projects[index].loadMoreUrl;
        return result;
      } else {
        return result;
      }
    },

    getAllMilestonesByProjectId:
      (state) =>
      (projectId: number): ShortMilestonesModel => {
        const index = state.milestonesIds.projects.findIndex(
          (n) => n.projectId === projectId
        );
        const result = { data: [], loadMoreUrl: null } as ShortMilestonesModel;
        if (~index) {
          const data = orderBy(state.milestones, (obj) =>
            indexOf(state.milestonesIds.projects[index].ids, obj.id)
          );
          result.data = filter(data, (obj) =>
            includes(state.milestonesIds.projects[index].ids, obj.id)
          );
          result.loadMoreUrl = state.milestonesIds.projects[index].loadMoreUrl;
          return result;
        } else {
          return result;
        }
      },

    getClosedMilestonesByCurrentProject(): TaskManagementMilestoneModel[] | [] {
      return this.getAllMilestonesByCurrentProject().data.filter(
        (milestone) => milestone.isClosed
      );
    },

    getOpenedMilestonesByCurrentProject(): TaskManagementMilestoneModel[] | [] {
      return this.getAllMilestonesByCurrentProject().data.filter(
        (milestone) => !milestone.isClosed
      );
    },

    getSearchedMilestones: (state): ShortMilestonesModel => {
      const result = { data: [], loadMoreUrl: null } as ShortMilestonesModel;
      const data = orderBy(state.milestones, (obj) =>
        indexOf(state.milestonesIds.search.ids, obj.id)
      );
      result.data = filter(data, (obj) =>
        includes(state.milestonesIds.search.ids, obj.id)
      );
      result.loadMoreUrl = state.milestonesIds.search.loadMoreUrl;
      return result;
    },

    getUsedTagsIds(): number[] {
      return this.getCurrentProject?.board?.columns.map(
        (column) => column.tag?.id
      ) as number[];
    },

    getOnlyUnusedTags(): TopicEntity[] | [] {
      const topicStore = useTopicStore();
      return filter(
        topicStore.getTopicsAll().data,
        (tag) =>
          !this.getCurrentProject.board.columns
            .map((column) => column.tag?.id)
            .includes(tag.id)
      );
    },

    getOnlyUnusedTagsInSearch(): TopicEntity[] | [] {
      const topicStore = useTopicStore();
      return filter(
        topicStore.getTopicsSearch().data,
        (tag) =>
          !this.getCurrentProject.board.columns
            .map((column) => column.tag?.id)
            .includes(tag.id)
      );
    },

    getGroupIdByTaskId:
      (state) =>
      (taskId: number): number | null => {
        const task = state.tasks.find((n) => n.id === taskId);
        return task?.projectId
          ? state.data.find((n) => n.id === task.projectId)?.group.id || null
          : null;
      },
  },
  actions: {
    async switchProject(projectId: number): Promise<void> {
      this.projectsIds.currentId = projectId;
      const projectIndex = findIndex(
        this.data,
        (project) => project.id === projectId
      );
      if (~projectIndex) {
        if (!this.data[projectIndex].board) {
          await this.projectById(projectId);
        }
      }
      this.resetAllSearch();
    },

    resetCurrentProject() {
      this.projectsIds.currentId = 0;
    },

    dragOverCard(
      cardIndex: number,
      columnIndex: number,
      targetColumn: TaskManagementColumnModel
    ): void {
      const targetColumnCardsIds = find(
        this.tasksIds.columns,
        (n) => n.columnId === targetColumn.id
      )?.ids;
      if (targetColumnCardsIds) {
        const plugIndex = targetColumnCardsIds.indexOf(0);

        if (~plugIndex) {
          targetColumnCardsIds.splice(plugIndex, 1);
          targetColumnCardsIds.splice(cardIndex, 0, 0);
        }
      }

      return;
    },

    async moveCard(
      taskId: number,
      columnIndex: number,
      dragCardColumnId: number, // id колонки из которой перемещается карточка
      targetColumn: TaskManagementColumnModel,

      indexToDropCard: number
      /* afterTaskId: number | null,
    beforeTaskId: number | null */
    ): Promise<number> {
      //Берем колонку откуда перетаскиваем
      const dragColumnCardsIds = find(
        this.tasksIds.columns,
        (n) => n.columnId === dragCardColumnId
      )?.ids;

      //Берем колонку куда перетаскиваем
      const targetColumnCardsIds = find(
        this.tasksIds.columns,
        (n) => n.columnId === targetColumn.id
      )?.ids;

      if (dragColumnCardsIds && targetColumnCardsIds) {
        const findIdBeforeAfterCards = (
          cardId: number
        ): { beforeTaskId: number | null; afterTaskId: number | null } => {
          let beforeTaskId = null;
          let afterTaskId = null;

          if (targetColumnCardsIds) {
            const array = filter(targetColumnCardsIds, (id) => id !== 0);
            const cardIndex = findIndex(array, (n) => n === cardId);
            if (~cardIndex) {
              afterTaskId = cardIndex > 0 ? array[cardIndex - 1] : null;
              beforeTaskId =
                cardIndex < array.length - 1 ? array[cardIndex + 1] : null;
            }
          }
          return { beforeTaskId, afterTaskId };
        };

        //NOTE: save the previous card position
        const {
          beforeTaskId: previousBeforeTaskId,
          afterTaskId: previousAfterTaskId,
        } = findIdBeforeAfterCards(taskId);

        //Удаляем из колонки, откуда перетаскиваем
        remove(dragColumnCardsIds, (item) => item === taskId);

        //Добавляем в колонку, в которую перетаскиваем
        targetColumnCardsIds.splice(indexToDropCard, 0, taskId);

        const card = find(
          this.tasks,
          (task) => task.id === taskId
        ) as TaskManagementDragCardModel;
        // Какой лейбл стереть
        const fromTag = find(
          this.getCurrentProject.board.columns,
          (n) => n.id === dragCardColumnId
        )?.tag;
        // Какой лейбл назначить
        const toTag = find(
          this.getCurrentProject.board.columns,
          (n) => n.id === targetColumn.id
        )?.tag;

        if (fromTag) {
          remove(card.tags.data, (item) => item.id === fromTag.id);
        }
        if (toTag?.id && !card.tags.data.some((tag) => tag.id === toTag.id)) {
          card.tags.data.push(toTag);
        }

        const boardId = this.getCurrentProject.board.id;

        const { beforeTaskId, afterTaskId } = findIdBeforeAfterCards(taskId);

        //NOTE: Check the previous and new position of the card, if it has not changed - do not send the request
        if (
          previousBeforeTaskId === beforeTaskId &&
          previousAfterTaskId === afterTaskId &&
          dragCardColumnId === targetColumn.id
        ) {
          return 200;
        }

        this.errors = [];
        const response = await $api.projects.moveTask(
          taskId,
          boardId,
          dragCardColumnId,
          targetColumn.id,
          afterTaskId,
          beforeTaskId
        );

        if (response.statusCode === 200) {
          return response.statusCode;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
          return response.statusCode;
        }
      }

      return 404;
    },

    // Переиндексация колонок
    dragOverColumn(
      dragColumn: TaskManagementColumnModel,
      targetColumn: TaskManagementColumnModel
    ) {
      //targetColumn - куда колонка будет сброшена

      const dragColumnIndex = find(
        this.getCurrentProject.board.columns,
        (n) => n.id === dragColumn.id
      )?.index;

      const targetColumnIndex = find(
        this.getCurrentProject.board.columns,
        (n) => n.id === targetColumn.id
      )?.index;

      if (dragColumnIndex !== undefined && targetColumnIndex !== undefined) {
        if (dragColumnIndex === targetColumnIndex) {
          return;
        }

        // Если перетаскиваем колонку вправо
        if (dragColumnIndex < targetColumnIndex) {
          const reindexingColumnsData = [] as TaskManagementColumnModel[];

          const columns = cloneDeep(this.getCurrentProject.board.columns);

          for (let i = 0; i < columns.length; i++) {
            const element = columns[i];
            if (
              element.index > dragColumnIndex &&
              element.index <= targetColumnIndex
            ) {
              reindexingColumnsData[i] = element;
              reindexingColumnsData[i].index = element.index - 1;
            } else {
              reindexingColumnsData[i] = element;
            }
          }

          const reindexingDragColumn = find(
            reindexingColumnsData,
            (n) => n.id === dragColumn.id
          );
          if (reindexingDragColumn) {
            reindexingDragColumn.index = targetColumn.index;
          }

          this.getCurrentProject.board.columns = reindexingColumnsData;
        }

        // Если перетаскиваем колонку влево
        if (dragColumnIndex > targetColumnIndex) {
          const reindexingColumnsData = [] as TaskManagementColumnModel[];

          const columns = cloneDeep(this.getCurrentProject.board.columns);

          for (let i = 0; i < columns.length; i++) {
            const element = columns[i];
            if (
              element.index < dragColumnIndex &&
              element.index >= targetColumnIndex
            ) {
              reindexingColumnsData[i] = element;
              reindexingColumnsData[i].index = element.index + 1;
            } else {
              reindexingColumnsData[i] = element;
            }
          }

          const reindexingDragColumn = find(
            reindexingColumnsData,
            (n) => n.id === dragColumn.id
          );
          if (reindexingDragColumn) {
            reindexingDragColumn.index = targetColumn.index;
          }

          this.getCurrentProject.board.columns = reindexingColumnsData;
        }
      }
    },

    async moveColumn(columnId: number, columnIndex: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.moveColumn(columnId, columnIndex);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskManagementBoardModel;
        const projectIndex = findIndex(
          this.data,
          (project) => project.id === this.projectsIds.currentId
        );
        if (~projectIndex) {
          this.data[projectIndex].board = model.data;
          updateProjectBoard(model.data, model.data.projectId);
        }
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async deleteColumn(columnId: number): Promise<void> {
      this.errors = [];
      const response = await $api.projects.deleteColumn(columnId);

      if (response.statusCode === 200) {
        const projectIndex = findIndex(
          this.data,
          (project) => project.id === this.projectsIds.currentId
        );
        if (~projectIndex) {
          remove(
            this.data[projectIndex].board.columns,
            (column) => column.id === columnId
          );
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async deleteTask(taskId: number): Promise<void> {
      this.errors = [];
      const response = await $api.projects.deleteTask(taskId);

      if (response.statusCode === 200) {
        remove(this.tasks, (n) => n.id === taskId);
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async updateTaskDescription(taskId: number, description: string) {
      this.errors = [];
      const response = await $api.projects.taskUpdateDescription(
        taskId,
        description
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;

        const taskForEdit = find(this.tasks, (task) => task.id === taskId);
        if (taskForEdit) {
          taskForEdit.description = model.data.description;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async updateTaskTitle(taskId: number, title: string) {
      this.errors = [];
      const response = await $api.projects.taskUpdateTitle(taskId, title);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;

        const taskForEdit = find(this.tasks, (task) => task.id === taskId);
        if (taskForEdit) {
          taskForEdit.title = model.data.title;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async updateTaskStatus(taskId: number): Promise<void> {
      const taskForEdit = find(this.tasks, (task) => task.id === taskId);
      this.errors = [];
      const response = taskForEdit?.isClosed
        ? await $api.projects.taskOpen(taskId)
        : await $api.projects.taskClose(taskId);

      if (response.statusCode === 200) {
        if (taskForEdit) {
          taskForEdit.isClosed = !taskForEdit.isClosed;
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async taskArchive(taskId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskArchivate(taskId);

      if (response.statusCode === 200) {
        const taskForEdit = find(this.tasks, (task) => task.id === taskId);
        if (taskForEdit) {
          taskForEdit.isArchived = true;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskDearchive(taskId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskDearchivate(taskId);

      if (response.statusCode === 200) {
        const taskForEdit = find(this.tasks, (task) => task.id === taskId);
        if (taskForEdit) {
          taskForEdit.isArchived = false;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskEnableNotifications(taskId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskEnableNotifications(taskId);

      if (response.statusCode === 200) {
        const taskForEdit = find(this.tasks, (task) => task.id === taskId);
        if (taskForEdit) {
          taskForEdit.notify = true;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskDisableNotifications(taskId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskDisableNotifications(taskId);

      if (response.statusCode === 200) {
        const taskForEdit = find(this.tasks, (task) => task.id === taskId);
        if (taskForEdit) {
          taskForEdit.notify = false;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async updateTaskDueDate(taskId: number, date?: string): Promise<void> {
      this.errors = [];
      const response = await $api.projects.updateTaskDueDate(taskId, date);

      if (response.statusCode === 200) {
        const taskForEdit = find(this.tasks, (task) => task.id === taskId);
        if (taskForEdit) {
          taskForEdit.dateDue = date ? date : '';
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async taskSetAssignee(
      taskId: number,
      user: UserMessageModel
    ): Promise<void> {
      this.errors = [];
      const response = await $api.projects.taskSetAssignee(taskId, user.id);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async taskRemoveAssignee(taskId: number): Promise<void> {
      this.errors = [];
      const response = await $api.projects.taskRemoveAssignee(taskId);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async taskAddParticipant(
      taskId: number,
      user: UserMessageModel
    ): Promise<void> {
      this.errors = [];
      const response = await $api.projects.taskAddParticipant(taskId, [
        user.id,
      ]);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async taskRemoveParticipant(
      taskId: number,
      user: UserMessageModel
    ): Promise<void> {
      this.errors = [];
      const response = await $api.projects.taskRemoveParticipant(taskId, [
        user.id,
      ]);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async taskCommentCreate(
      comment: RequestTaskCommentCreateModel
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskCommentCreate(comment);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskCommentCreateModel;
        const index = findIndex(
          this.tasks,
          (task) => task.id === comment.taskId
        );
        if (~index) {
          this.tasks[index].comments.count += 1;
          this.tasks[index].comments.data.push(model.data);
        }

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskCommentDelete(
      comment: TaskManagementTaskCommentDataModel
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskCommentDelete(comment.id);

      if (response.statusCode === 200) {
        const taskIndex = findIndex(
          this.tasks,
          (task) => task.id === comment.taskId
        );
        if (~taskIndex) {
          const commentIndex = findIndex(
            this.tasks[taskIndex].comments.data,
            (n) => n.id === comment.id
          );
          if (~taskIndex && ~commentIndex) {
            remove(
              this.tasks[taskIndex].comments.data,
              (n) => n.id === comment.id
            );
          }
        }

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskCreate(
      taskData: TaskManagementCreateTaskRequestModel,
      columnId?: number
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskCreate(taskData);
      if (response.statusCode === 200) {
        const postStore = usePostStore();
        const model = response as ResponseTaskCreateDataModel;
        if (model.data.taskData) {
          this.tasks.push(model.data.taskData);

          const post = cloneDeep(defaultPost);
          postStore.addNewPost(Object.assign(post, model.data));

          if (columnId) {
            const columnIndex = this.tasksIds.columns.findIndex(
              (n) => n.columnId === columnId
            );
            if (~columnIndex) {
              this.tasksIds.columns[columnIndex].ids.unshift(
                model.data.taskData.id
              );
              this.tasksIds.columns[columnIndex].totalCount++;
            }
          }

          if (model.data.taskData?.author && model.data.taskData.author.id) {
            const authorIndex = this.tasksIds.authors.findIndex(
              (n) => n.authorId === model.data.taskData?.author?.id
            );
            if (~authorIndex) {
              this.tasksIds.authors[authorIndex].ids.unshift(
                model.data.taskData.id
              );
            }
          }

          if (
            model.data.taskData?.assignee &&
            model.data.taskData.assignee.id
          ) {
            const assigneeIndex = this.tasksIds.assignees.findIndex(
              (n) => n.assigneeId === model.data.taskData?.assignee?.id
            );
            if (~assigneeIndex) {
              this.tasksIds.assignees[assigneeIndex].ids.unshift(
                model.data.taskData.id
              );
            }
          }

          const index = this.tasksIds.projects.findIndex(
            (n) => n.projectId === taskData.projectId
          );
          if (~index) {
            this.tasksIds.projects[index].ids.unshift(model.data.taskData.id);
          }

          const projectIndex = this.data.findIndex(
            (n) => n.id === taskData.projectId
          );
          if (~projectIndex) {
            this.data[projectIndex].tasks.count++;
          }

          return true;
        }
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async addTaskToStore(taskData: TaskManagementTaskModel): Promise<void> {
      const taskIsExists = find(this.tasks, (task) => task.id === taskData.id);
      if (!taskIsExists) {
        this.tasks.push(taskData);
        checkExistsMilestonesInStore();
      } else {
        this.tasks = mergeTasksById(this.tasks, [taskData]);
      }
    },

    async addTagToTask(taskId: number, tag: TopicEntity): Promise<void> {
      this.errors = [];
      const response = await $api.projects.taskAddTags(taskId, [tag.id]);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async removeTagFromTask(taskId: number, tag: TopicEntity): Promise<void> {
      this.errors = [];
      const response = await $api.projects.taskRemoveTags(taskId, [tag.id]);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async taskAddFiles(
      taskId: number,
      fileExistIds: string[],
      fileTempIds: string[]
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskAddFiles(
        taskId,
        fileExistIds,
        fileTempIds
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskRemoveFiles(
      taskId: number,
      filesIds: number[]
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.taskRemoveFiles(taskId, filesIds);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskSetMilestone(taskId: number, milestoneId: number) {
      this.errors = [];
      const response = await $api.projects.taskSetMilestone(
        taskId,
        milestoneId
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async taskRemoveMilestone(taskId: number) {
      this.errors = [];
      const response = await $api.projects.taskRemoveMilestone(taskId);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        const index = findIndex(this.tasks, (task) => task.id === taskId);
        if (~index) {
          this.tasks[index] = model.data;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async deleteMilestone(milestoneId: number) {
      this.errors = [];
      const response = await $api.projects.deleteMilestone(milestoneId);

      if (response.statusCode === 200) {
        remove(this.milestones, (n) => n.id === milestoneId);
        this.tasks.forEach((task) => {
          if (task.milestone?.id === milestoneId) {
            task.milestone = null;
          }
        });
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async updateMilestoneById(
      milestoneData: TaskManagementUpdateMilestoneRequestModel
    ): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.updateMilestoneById(milestoneData);

      if (response.statusCode === 200) {
        const model = response as ResponseMilestoneModel;
        const milestone = this.milestones.find(
          (milestone) => milestone.id === milestoneData.id
        );
        if (milestone) {
          milestone.title = model.data.title;
          milestone.description = model.data.description;
          milestone.dateStart = model.data.dateStart;
          milestone.dateDue = model.data.dateDue;
        }
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },

    async createNewMilestone(
      milestoneData: TaskManagementCreateMilestoneRequestModel
    ): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.createNewMilestone(milestoneData);

      if (response.statusCode === 200) {
        const model = response as ResponseMilestoneModel;
        this.milestones.push(model.data);
        const index = this.milestonesIds.projects.findIndex(
          (n) => n.projectId === milestoneData.projectId
        );
        if (~index) {
          this.milestonesIds.projects[index].ids.unshift(model.data.id);
        }
        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },

    async createNewProject(
      title: string,
      group: GroupEntity
    ): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.createNewProject(title, group.id);

      if (response.statusCode === 200) {
        const model = response as ResponseProjectModel;
        this.data.push(model.data);
        this.projectsIds.all.ids.unshift(model.data.id);
        const index = this.projectsIds.groups.findIndex(
          (n) => n.groupId === group.id
        );
        if (~index) {
          this.projectsIds.groups[index].ids.unshift(model.data.id);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async setProjectTitle(projectId: number, title: string): Promise<boolean> {
      this.errors = [];
      const response = await $api.projects.setProjectTitle(projectId, title);

      if (response.statusCode === 200) {
        const project = this.data.find((n) => n.id === projectId);
        if (project) {
          project.title = title;
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async removeProject(projectId: number) {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.removeProject(projectId);

      if (response.statusCode === 200) {
        remove(this.data, (n) => n.id === projectId);
        // если текущий проект - роутить на страницу проектов
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    changeViewMode(mode: TaskManagementViewModeEnum) {
      this.viewMode = mode;
    },
    changeColumnView(columnId: number) {
      if (this.collapsedColumns.includes(columnId)) {
        remove(this.collapsedColumns, (column) => column === columnId);
      } else {
        this.collapsedColumns.push(columnId);
      }
    },

    async searchByTask(searchQuery: string, projectId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getTasksBySearch({
        search: searchQuery,
        projectId,
        sort: this.sorting.tasks.sortBy,
        sortDirection: this.sorting.tasks.direction,
        authorId: this.tasksSearch.author ? this.tasksSearch.author.id : '',
        assigneeId: this.tasksSearch.assignee
          ? this.tasksSearch.assignee.id
          : '',
        tagId: this.tasksSearch.tag ? this.tasksSearch.tag.id : '',
        milestoneId: this.tasksSearch.milestone
          ? this.tasksSearch.milestone.id
          : '',
      });

      if (response.statusCode === 200) {
        const model = response as ResponseTasksModel;
        this.tasks = mergeTasksById(this.tasks, model.data);

        this.tasksIds.search.ids = model.data.map((n) => n.id);
        this.tasksIds.search.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async searchByTaskByAuthorId(
      searchQuery: string,
      authorId: number
    ): Promise<void> {
      this.errors = [];
      this.loading = true;
      this.tasksIds.search.ids = [];
      this.tasksIds.search.loadMoreUrl = null;
      const response = await $api.projects.getTasksBySearchByAuthorId(
        searchQuery,
        authorId,
        this.sorting.tasks.direction,
        this.sorting.tasks.sortBy
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTasksModel;
        this.tasks = mergeTasksById(this.tasks, model.data);

        this.tasksIds.search.ids = model.data.map((n) => n.id);
        this.tasksIds.search.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async searchByTaskByAssigneeId(
      searchQuery: string,
      assigneeId: number
    ): Promise<void> {
      this.errors = [];
      this.loading = true;
      this.tasksIds.search.ids = [];
      this.tasksIds.search.loadMoreUrl = null;
      const response = await $api.projects.getTasksBySearchByAssigneeId(
        searchQuery,
        assigneeId,
        this.sorting.tasks.direction,
        this.sorting.tasks.sortBy
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTasksModel;
        this.tasks = mergeTasksById(this.tasks, model.data);

        this.tasksIds.search.ids = model.data.map((n) => n.id);
        this.tasksIds.search.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async tasksByProjectId(projectId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getTasksByProjectId(
        projectId,
        this.sorting.tasks.direction,
        this.sorting.tasks.sortBy
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTasksModel;
        this.tasks = mergeTasksById(this.tasks, model.data);

        const index = this.tasksIds.projects.findIndex(
          (n) => n.projectId === projectId
        );
        if (~index) {
          this.tasksIds.projects[index].ids = model.data.map((n) => n.id);
          this.tasksIds.projects[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.tasksIds.projects.push({
            projectId: projectId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: null,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async tasksByAuthorId(authorId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getTasksByAuthorId(
        authorId,
        this.sorting.tasks.direction,
        this.sorting.tasks.sortBy
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTasksModel;
        this.tasks = mergeTasksById(this.tasks, model.data);

        const index = this.tasksIds.authors.findIndex(
          (n) => n.authorId === authorId
        );
        if (~index) {
          this.tasksIds.authors[index].ids = model.data.map((n) => n.id);
          this.tasksIds.authors[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.tasksIds.authors.push({
            authorId: authorId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: null,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async tasksByAssigneeId(assigneeId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getTasksByAssigneeId(
        assigneeId,
        this.sorting.tasks.direction,
        this.sorting.tasks.sortBy
      );

      if (response.statusCode === 200) {
        const model = response as ResponseTasksModel;
        this.tasks = mergeTasksById(this.tasks, model.data);

        const index = this.tasksIds.assignees.findIndex(
          (n) => n.assigneeId === assigneeId
        );
        if (~index) {
          this.tasksIds.assignees[index].ids = model.data.map((n) => n.id);
          this.tasksIds.assignees[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.tasksIds.assignees.push({
            assigneeId: assigneeId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: null,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async tasksByColumnId(columnId: number): Promise<void> {
      this.errors = [];

      const index = this.tasksIds.columns.findIndex(
        (n) => n.columnId === columnId
      );

      if (~index) {
        this.tasksIds.columns[index].isLoading = true;
      }

      const response = await $api.projects.getTasksByColumnId(columnId);

      if (response.statusCode === 200) {
        const model = response as ResponseTasksByColumnModel;
        this.tasks = mergeTasksById(this.tasks, model.data);

        const plugTask = cloneDeep(defaultTaskManagementDragCardModel);
        if (!this.tasks.some((n) => n.type === 'Plug')) {
          this.tasks.unshift(plugTask);
        }

        if (~index) {
          this.tasksIds.columns[index].ids = model.data.map((n) => n.id);
          this.tasksIds.columns[index].loadMoreUrl = model.loadMoreUrl;
          this.tasksIds.columns[index].totalCount = model.totalCount;
          this.tasksIds.columns[index].isLoading = false;

          this.tasksIds.columns[index].ids.push(plugTask.id);
        } else {
          this.tasksIds.columns.push({
            columnId: columnId,
            ids: [...model.data.map((n) => n.id), plugTask.id],
            loadMoreUrl: null,
            totalCount: model.totalCount,
            isLoading: false,
          });
        }

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    async tasksByAllColumns(): Promise<void> {
      this.getCurrentProject.board?.columns.forEach(async (element) => {
        if (element.id > 0) {
          await this.tasksByColumnId(element.id);
        }
      });
    },

    async tasksSearchByAllColumns(searchQuery: string): Promise<void> {
      this.getCurrentProject.board.columns.forEach(async (element) => {
        if (element.id > 0) {
          await this.tasksSearchByColumnId(searchQuery, element.id);
        }
      });
    },

    async tasksSearchByColumnId(
      searchQuery: string,
      columnId: number
    ): Promise<void> {
      this.errors = [];

      const index = this.tasksIds.columns.findIndex(
        (n) => n.columnId === columnId
      );

      if (~index) {
        this.tasksIds.columns[index].isLoading = true;

        const response = await $api.projects.getTasksSearchByColumnId({
          search: searchQuery,
          boardColumnId: columnId,
          authorId: this.tasksSearch.author ? this.tasksSearch.author.id : '',
          assigneeId: this.tasksSearch.assignee
            ? this.tasksSearch.assignee.id
            : '',
          tagId: this.tasksSearch.tag ? this.tasksSearch.tag.id : '',
          milestoneId: this.tasksSearch.milestone
            ? this.tasksSearch.milestone.id
            : '',
        });

        if (response.statusCode === 200) {
          const model = response as ResponseTasksByColumnModel;
          this.tasks = mergeTasksById(this.tasks, model.data);

          const plugTask = cloneDeep(defaultTaskManagementDragCardModel);
          if (!this.tasks.some((n) => n.type === 'Plug')) {
            this.tasks.unshift(plugTask);
          }

          this.tasksIds.columns[index].ids = model.data.map((n) => n.id);
          this.tasksIds.columns[index].loadMoreUrl = model.loadMoreUrl;
          this.tasksIds.columns[index].totalCount = model.totalCount;
          this.tasksIds.columns[index].isLoading = false;
          this.tasksIds.columns[index].ids.push(plugTask.id);
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }

        return;
      } else {
        return;
      }
    },

    async tasksByColumnIdLoadMore(
      columnId: number,
      loadMoreUrl: string
    ): Promise<void> {
      this.errors = [];
      this.loading = true;

      if (loadMoreUrl) {
        const response =
          await $api.projects.getTasksByColumnIdLoadMoreURL(loadMoreUrl);

        if (response.statusCode === 200) {
          const model = response as ResponseTasksByColumnModel;
          this.tasks = mergeTasksById(this.tasks, model.data);
          updateColumnAfterLoadedMore(columnId, model);
          this.loading = false;
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      }

      this.loading = false;
      return;
    },

    async taskById(taskId: number): Promise<boolean> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getTaskById(taskId);

      if (response.statusCode === 200) {
        const model = response as ResponseTaskModel;
        this.tasks = mergeTasksById(this.tasks, [model.data]);

        checkExistProjectInStore(model.data.projectId);

        this.loading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return false;
    },

    async projectsByGroupId(groupId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getProjectsByGroupId(
        groupId,
        this.sorting.projects.sortBy,
        this.sorting.projects.direction
      );

      if (response.statusCode === 200) {
        const model = response as ResponseProjectsModel;
        this.data = mergeProjectsById(this.data, model.data);
        const index = this.projectsIds.groups.findIndex(
          (n) => n.groupId === groupId
        );
        if (~index) {
          this.projectsIds.groups[index].ids = model.data.map((n) => n.id);
          this.projectsIds.groups[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.projectsIds.groups.push({
            groupId: groupId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: model.loadMoreUrl,
          });
        }
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async projectById(projectId: number): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getProjectById(projectId);

      if (response.statusCode === 200) {
        const model = response as ResponseProjectModel;
        this.data = mergeProjectsById(this.data, [model.data]);
        updateProjectBoard(model.data.board, projectId);
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async projectsAll(): Promise<void> {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getProjectsAll(
        this.sorting.projects.sortBy,
        this.sorting.projects.direction
      );

      if (response.statusCode === 200) {
        const model = response as ResponseProjectsModel;
        this.data = mergeProjectsById(this.data, model.data);
        this.projectsIds.all.ids = model.data.map((n) => n.id);
        this.projectsIds.all.loadMoreUrl = model.loadMoreUrl;
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.loading = false;
      return;
    },

    async milestoneById(milestoneId: number) {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getMilestoneById(milestoneId);

      if (response.statusCode === 200) {
        const model = response as ResponseMilestoneModel;
        this.milestones = mergeMilestonesById(this.milestones, [model.data]);
        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.loading = false;
      return;
    },

    async milestonesByProjectId(projectId: number) {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getMilestonesByProjectId(
        projectId,
        this.sorting.milestones.sortBy,
        this.sorting.milestones.direction
      );

      if (response.statusCode === 200) {
        const model = response as ResponseMilestonesModel;
        this.milestones = mergeMilestonesById(this.milestones, model.data);

        const index = this.milestonesIds.projects.findIndex(
          (n) => n.projectId === projectId
        );
        if (~index) {
          this.milestonesIds.projects[index].ids = model.data.map((n) => n.id);
          this.milestonesIds.projects[index].loadMoreUrl = model.loadMoreUrl;
        } else {
          this.milestonesIds.projects.push({
            projectId: projectId,
            ids: model.data.map((n) => n.id),
            loadMoreUrl: null,
          });
        }

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async searchByMilestone(searchQuery: string, projectId: number) {
      this.errors = [];
      this.loading = true;
      const response = await $api.projects.getMilestonesBySearch(
        searchQuery,
        projectId,
        this.sorting.milestones.sortBy,
        this.sorting.milestones.direction
      );

      if (response.statusCode === 200) {
        const model = response as ResponseMilestonesModel;
        this.milestones = mergeMilestonesById(this.milestones, model.data);

        this.milestonesIds.search.ids = model.data.map((n) => n.id);
        this.milestonesIds.search.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async milestonesLoadMore(isSearch: boolean, projectId: number) {
      this.errors = [];
      this.loading = true;
      const loadMoreUrl = isSearch
        ? this.milestonesIds.search.loadMoreUrl
        : this.getAllMilestonesByProjectId(projectId).loadMoreUrl;

      if (loadMoreUrl) {
        const response =
          await $api.projects.getMilestonesByLoadMoreURL(loadMoreUrl);

        if (response.statusCode === 200) {
          const model = response as ResponseMilestonesModel;
          this.milestones = mergeMilestonesById(this.milestones, model.data);
          updateMilestonesAfterLoadedMore(isSearch, projectId, model);

          this.loading = false;
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      }

      this.loading = false;
      return;
    },

    async tasksLoadMore(
      loadMoreUrl: string,
      isSearch: boolean,
      projectId?: number,
      authorId?: number,
      assigneeId?: number
    ): Promise<void> {
      this.errors = [];
      this.loading = true;

      if (loadMoreUrl) {
        const response = await $api.projects.getTasksByLoadMoreURL(loadMoreUrl);

        if (response.statusCode === 200) {
          const model = response as ResponseTasksModel;
          this.tasks = mergeTasksById(this.tasks, model.data);
          updateTasksAfterLoadedMore(
            model,
            isSearch,
            projectId,
            authorId,
            assigneeId
          );

          this.loading = false;
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      }

      this.loading = false;
      return;
    },

    async searchByProject(
      searchQuery: string,
      groupId?: number | undefined
    ): Promise<void> {
      this.errors = [];
      this.loading = true;
      this.projectsIds.search.ids = [];
      this.projectsIds.search.loadMoreUrl = null;
      const response = await $api.projects.getProjectsBySearch(
        searchQuery,
        this.sorting.projects.sortBy,
        this.sorting.projects.direction,
        groupId
      );

      if (response.statusCode === 200) {
        const model = response as ResponseProjectsModel;
        this.data = mergeProjectsById(this.data, model.data);

        this.projectsIds.search.ids = model.data.map((n) => n.id);
        this.projectsIds.search.loadMoreUrl = model.loadMoreUrl;

        this.loading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.loading = false;
      return;
    },

    async projectsLoadMore(groupId: number, isSearch: boolean): Promise<void> {
      this.errors = [];
      const loadMoreUrl = isSearch
        ? this.projectsIds.search.loadMoreUrl
        : groupId > 0
          ? this.getProjectsByGroupId(groupId).loadMoreUrl
          : this.projectsIds.all.loadMoreUrl;

      if (loadMoreUrl) {
        const response =
          await $api.projects.getProjectsByLoadMoreURL(loadMoreUrl);

        if (response.statusCode === 200) {
          const model = response as ResponseProjectsModel;
          this.data = mergeProjectsById(this.data, model.data);
          updateProjectsAfterLoadedMore(groupId, isSearch, model);
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      }

      return;
    },

    async boardById(project: ProjectEntity): Promise<void> {
      //TODO: заменить запрос, обращаться за доской
      // project не всегда имеет board.id
      this.errors = [];

      const boardId = project?.board?.id ?? undefined;

      if (~boardId && boardId !== undefined) {
        const response = await $api.projects.getBoardById(boardId);
        if (response.statusCode === 200) {
          const model = response as ResponseTaskManagementBoardModel;
          const index = this.data.findIndex((n) => n.id === project.id);

          if (~index) {
            this.data[index].board = model.data;
            updateProjectBoard(model.data, project.id);
          }
          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      }

      return;
    },

    async createColumn(tagId: number, project: ProjectEntity): Promise<void> {
      this.errors = [];

      const response = await $api.projects.createColumn(
        project.board.id,
        tagId
      );
      if (response.statusCode === 200) {
        const model = response as ResponseTaskManagementBoardModel;
        const index = this.data.findIndex((n) => n.id === project.id);

        if (~index) {
          this.data[index].board = model.data;
          updateProjectBoard(model.data, project.board.id);
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return;
    },

    resetAllSearch() {
      this.milestonesSearch.searchQuery = '';
      this.tasksSearch.searchQuery = '';

      this.resetTasksSearchParams();
    },

    resetTasksSearchParams() {
      this.tasksSearch.assignee = null;
      this.tasksSearch.author = null;
      this.tasksSearch.tag = null;
      this.tasksSearch.milestone = null;
    },
  },

  persist: true,
});

const mergeById = <T extends { id: any }>(a: T[], b: T[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? { ...obj, ...match } : obj;
  });
};

const mergeProjectsById = (a: ProjectEntity[], b: ProjectEntity[]) =>
  mergeById(a, b);

const mergeMilestonesById = (
  a: TaskManagementMilestoneModel[],
  b: TaskManagementMilestoneModel[]
) => mergeById(a, b);

const mergeTasksById = (
  a: TaskManagementTaskModel[],
  b: TaskManagementTaskModel[]
) => {
  checkExistsTagsInStore();
  checkExistsMilestonesInStore();
  return mergeById(a, b);
};

const updateMilestonesAfterLoadedMore = (
  isSearch: boolean,
  projectId: number,
  data: ResponseMilestonesModel
) => {
  const projectsStore = useProjectsStore();

  if (isSearch) {
    projectsStore.milestonesIds.search.ids = [
      ...projectsStore.milestonesIds.search.ids,
      ...data.data.map((n) => n.id),
    ];
    projectsStore.milestonesIds.search.loadMoreUrl = data.loadMoreUrl;
  } else {
    const index = projectsStore.milestonesIds.projects.findIndex(
      (n) => n.projectId === projectId
    );
    if (~index) {
      projectsStore.milestonesIds.projects[index].ids = [
        ...projectsStore.milestonesIds.projects[index].ids,
        ...data.data.map((n) => n.id),
      ];
      projectsStore.milestonesIds.projects[index].loadMoreUrl =
        data.loadMoreUrl;
    }
  }
};

const updateTasksAfterLoadedMore = (
  data: ResponseTasksModel,
  isSearch: boolean,
  projectId?: number,
  authorId?: number,
  assigneeId?: number
) => {
  const projectsStore = useProjectsStore();

  if (isSearch) {
    projectsStore.tasksIds.search.ids = [
      ...projectsStore.tasksIds.search.ids,
      ...data.data.map((n) => n.id),
    ];
    projectsStore.tasksIds.search.loadMoreUrl = data.loadMoreUrl;
    return;
  }

  if (projectId) {
    const index = projectsStore.tasksIds.projects.findIndex(
      (n) => n.projectId === projectId
    );
    if (~index) {
      projectsStore.tasksIds.projects[index].ids = [
        ...projectsStore.tasksIds.projects[index].ids,
        ...data.data.map((n) => n.id),
      ];
      projectsStore.tasksIds.projects[index].loadMoreUrl = data.loadMoreUrl;
    }
    return;
  }

  if (authorId) {
    const index = projectsStore.tasksIds.authors.findIndex(
      (n) => n.authorId === projectId
    );
    if (~index) {
      projectsStore.tasksIds.authors[index].ids = [
        ...projectsStore.tasksIds.authors[index].ids,
        ...data.data.map((n) => n.id),
      ];
      projectsStore.tasksIds.authors[index].loadMoreUrl = data.loadMoreUrl;
    }
    return;
  }

  if (assigneeId) {
    const index = projectsStore.tasksIds.assignees.findIndex(
      (n) => n.assigneeId === projectId
    );
    if (~index) {
      projectsStore.tasksIds.assignees[index].ids = [
        ...projectsStore.tasksIds.assignees[index].ids,
        ...data.data.map((n) => n.id),
      ];
      projectsStore.tasksIds.assignees[index].loadMoreUrl = data.loadMoreUrl;
    }
    return;
  }
};

const updateColumnAfterLoadedMore = (
  columnId: number,
  data: ResponseTasksModel
) => {
  const projectsStore = useProjectsStore();
  const index = projectsStore.tasksIds.columns.findIndex(
    (n) => n.columnId === columnId
  );
  if (~index) {
    projectsStore.tasksIds.columns[index].ids = [
      ...projectsStore.tasksIds.columns[index].ids,
      ...data.data.map((n) => n.id),
    ];
    projectsStore.tasksIds.columns[index].loadMoreUrl = data.loadMoreUrl;
  }
};

const updateProjectsAfterLoadedMore = (
  groupId: number,
  isSearch: boolean,
  data: ResponseProjectsModel
) => {
  const projectsStore = useProjectsStore();

  if (isSearch) {
    projectsStore.projectsIds.search.ids = [
      ...projectsStore.projectsIds.search.ids,
      ...data.data.map((n) => n.id),
    ];
    projectsStore.projectsIds.search.loadMoreUrl = data.loadMoreUrl;
  } else if (groupId) {
    const index = projectsStore.projectsIds.groups.findIndex(
      (n) => n.groupId === groupId
    );
    if (~index) {
      projectsStore.projectsIds.groups[index].ids = [
        ...projectsStore.projectsIds.groups[index].ids,
        ...data.data.map((n) => n.id),
      ];
      projectsStore.projectsIds.groups[index].loadMoreUrl = data.loadMoreUrl;
    }
  } else {
    projectsStore.projectsIds.all.ids = [
      ...projectsStore.projectsIds.all.ids,
      ...data.data.map((n) => n.id),
    ];
    projectsStore.projectsIds.all.loadMoreUrl = data.loadMoreUrl;
  }
};

// Check if milestone task is available in the store, if not - try to get it
/* const activeMilestonesRequests = [] as number[]; */
const checkExistsMilestonesInStore = async () => {
  const projectsStore = useProjectsStore();

  const uniqueMilestoneIds = uniq(
    compact(projectsStore.tasks.map((task) => get(task, 'milestone.id')))
  );
  const missingMilestoneIds = difference(
    uniqueMilestoneIds,
    map(projectsStore.milestones, 'id')
  );

  missingMilestoneIds.forEach(async (id) => {
    if (id && projectsStore.activeMilestonesRequests.includes(id)) {
      return;
    }
    if (typeof id === 'number') {
      projectsStore.$patch((state) => {
        state.activeMilestonesRequests = [
          ...state.activeMilestonesRequests,
          id,
        ];
      });
      try {
        const milestone = projectsStore.getMilestoneById(id);
        if (!milestone) {
          // Выполняем запрос, только если milestone с заданным ID отсутствует
          await projectsStore.milestoneById(id);
        }
      } finally {
        projectsStore.$patch((state) => {
          state.activeMilestonesRequests = remove(
            state.activeMilestonesRequests,
            id
          );
        });
      }
    }
  });
};

const checkExistProjectInStore = async (projectId: number) => {
  const projectsStore = useProjectsStore();
  const isExist = find(projectsStore.data, (n) => n.id === projectId);
  if (isExist || projectsStore.activeProjectsRequests.includes(projectId)) {
    return;
  } else {
    projectsStore.$patch((state) => {
      state.activeProjectsRequests = [
        ...state.activeProjectsRequests,
        projectId,
      ];
    });
    try {
      await projectsStore.projectById(projectId);
    } finally {
      projectsStore.$patch((state) => {
        state.activeProjectsRequests = remove(
          state.activeProjectsRequests,
          projectId
        );
      });
    }
  }
};

/* const checkExistsTasksInStore = async (id: number) => {
  // Check if a task is available in the store, if not - try to get it
  const projectsStore = useProjectsStore();
  const task = projectsStore.getTaskById(id);
  if (task) {
    return;
  } else {
    projectsStore.taskById(id);
  }
}; */

const checkExistsTagsInStore = async () => {
  // Check if a tag is available in the store, if not - write him
  const projectsStore = useProjectsStore();
  if (projectsStore.tasks.length > 0) {
    const topicStore = useTopicStore();
    const tasksTags = flatMap(projectsStore.tasks, (task) =>
      task.tags.data.map((tag) => tag)
    );
    const columnsTags =
      projectsStore.getCurrentProject?.board?.columns
        .filter((column) => column.tag !== null)
        .map((column) => column.tag) ?? [];
    const allTags = unionBy([...tasksTags, ...columnsTags], 'id');
    if (allTags.length > 0) {
      allTags.forEach((tag) => {
        if (tag && !some(topicStore.data, (n) => n.id === tag.id)) {
          topicStore.data.push(tag);
        }
      });
    }
  }
};

const updateProjectBoard = (
  board: TaskManagementBoardModel,
  projectId: number
): void => {
  const projectsStore = useProjectsStore();

  const projectIndex = findIndex(projectsStore.data, (n) => n.id === projectId);
  if (~projectIndex) {
    const closedColumnIndex = findIndex(
      board.columns,
      (n) => n.columnType === TaskManagementColumnType.Closed
    );

    if (closedColumnIndex) {
      // Добавляем в конец столбец, который позволяет создавать новые столбцы
      projectsStore.data[projectIndex].board.columns.push({
        id: 0,
        columnType: TaskManagementColumnType.New,
        boardId: projectId,
        tag: null,
        index: board.columns[closedColumnIndex].index + 1,
      });
    }
  }
};
