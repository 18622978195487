<template>
  <div class="network" @click="networkChange">
    <app-image
      class="network-image"
      :text="networkTitle"
      :url="networkLogo"
      :type="0"
      :image-type="AppImageType.NetworkLogo"
      :notifications-count="notificationsCount"
    />
    <span v-if="isSMWidth" class="network-title">{{ networkTitle }}</span>
    <ion-icon :icon="icons.chevronDown" />
  </div>
</template>

<script lang="ts" setup>
import { IonIcon } from '@ionic/vue';
import { chevronDown, chevronUp } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import type { NetworkChangeEnum } from '@/@enums';
import { AppImageType } from '@/@enums';
import { AppImage } from '@/components';
import {
  useUserFlow,
  componentNetworkChangeModal,
  useNetworksHelper,
} from '@/helpers';
import { useNetworkStore, useAppStore } from '@/store';

defineProps({
  type: {
    type: String as PropType<NetworkChangeEnum>,
    required: true,
  },
});

const icons = {
  chevronDown,
  chevronUp,
};

const networkStore = useNetworkStore();
const appStore = useAppStore();

const isSMWidth: ComputedRef<boolean> = computed(() => appStore.isSMWidth);

const networkTitle: ComputedRef<string> = computed(
  () => networkStore.network?.title ?? ''
);
const networkLogo: ComputedRef<string | null> = computed(() =>
  useNetworksHelper().getNetworkLogoUrl(networkStore.network?.id)
);

const notificationsCount: ComputedRef<number | string | null> = computed(() => {
  const count = networkStore.data.reduce(
    (n, network) => n + network.notificationCount,
    0
  );

  if (count) {
    return count === 0 ? null : count > 999 ? '999+' : count;
  }

  return null;
});

const networkChange = async () => {
  const result = await componentNetworkChangeModal(true);
  if (result.data) {
    await useUserFlow().setNetwork(result.data, null);
  }
};
</script>

<style scoped lang="scss">
.network {
  height: 100%;
  max-width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-inline: app-padding(lg);

  .network-image {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
  }
  ion-icon {
    padding-left: app-padding(md);
    flex-shrink: 0;
    color: var(--ion-color-custom);
  }
  &:hover {
    cursor: pointer;
    background: rgba(var(--ion-color-light-rgb), 0.1);
  }
  &-title {
    margin-left: app-padding(md);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--ion-color-custom);
  }
}
</style>
