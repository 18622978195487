import { cloneDeep } from 'lodash';

import type {
  AdvancedWikiContentModel,
  AdvancedWikiBodyModel,
  WikiEditFormModel,
  WikisIdsModel,
} from '@/@types';

export const defaultAdvancedWikiModel: AdvancedWikiContentModel = {
  head: {
    name: '',
    text: '',
    settings: {
      isVisible: true,
      isDeleted: false,
    },
  },
  content: {
    name: '',
    text: '',
    settings: {
      isVisible: true,
      isDeleted: false,
    },
  },
  body: [
    {
      name: '',
      text: '',
      settings: {
        position: 0,
        isVisible: true,
        isDeleted: false,
      },
    },
  ],
  participants: {
    name: '',
    users: [],
    settings: {
      allUsers: false,
      isVisible: true,
      isDeleted: false,
    },
  },
};

export const defaultAdvancedWikiBodyModel: AdvancedWikiBodyModel = {
  name: '',
  text: '',
  settings: {
    position: 0,
    isVisible: true,
    isDeleted: false,
  },
};

export const defaultEditFormModel: WikiEditFormModel = {
  name: '',
  groupId: null,
  folderId: null,
  wikiText: '',
  wikiContent: cloneDeep(defaultAdvancedWikiModel),
  participantsIds: [],

  accessOnlyForGroupId: null,
  mentionedUserIds: [],
};

export const defaultWikisIds: WikisIdsModel = {
  search: {
    loadMoreUrl: null,
    ids: [],
  },
};
