<template>
  <ion-split-pane
    :key="pageFlag.type"
    content-id="main-content"
    :when="paneWidth"
  >
    <div id="main-content" class="ion-page">
      <app-page-header v-if="isShowAppHeader" />
      <ion-router-outlet
        :key="renderFlag"
        class="main-pages-content"
        :style="{
          'margin-top': `calc(${isShowAppHeader ? appHeaderHeight : 0}px)`,
          'margin-bottom': appBottomMenuIsShow
            ? `${appBottomMenuHeight}px`
            : '',
        }"
      />
      <app-footer-menu v-if="appBottomMenuIsShow" />
    </div>
  </ion-split-pane>
</template>

<script lang="ts" setup>
import { IonSplitPane, IonRouterOutlet } from '@ionic/vue';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { AppPageHeader, AppFooterMenu } from '@/components';
import { ROUTES_NAME } from '@/router';
import { useAppStore } from '@/store';

const route = useRoute();
const appStore = useAppStore();

const pageFlag: ComputedRef<{ isMessenger: boolean; type: string }> = computed(
  () =>
    route.meta.isMessenger
      ? { isMessenger: true, type: 'Messenger' }
      : { isMessenger: false, type: 'Page' }
);
const paneWidth: ComputedRef<boolean | string> = computed(() => {
  return route.meta.isMessenger || route.name === ROUTES_NAME.OFFICE
    ? '(min-width: 100%;)'
    : 'lg';
});
const routeName: ComputedRef<string> = computed(
  () => (route.name as string) || ''
);

const renderFlag: ComputedRef<string> = computed(() =>
  routeName.value ===
  (ROUTES_NAME.SEARCH ||
    ROUTES_NAME.WIKI_EDIT ||
    ROUTES_NAME.LOADING ||
    ROUTES_NAME.NOT_FOUND)
    ? Date.now().toString()
    : ''
);

const isLGWidth: ComputedRef<boolean> = computed(() => appStore.isLGWidth);

const appBottomMenuIsShow: ComputedRef<boolean> = computed(() => {
  const hideOnSingleMessenger =
    appStore.isPhone &&
    (route.name === ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN ||
      route.name === ROUTES_NAME.MESSENGER_CHAT_BY_USER);

  const hideOnRoutes: string[] = [];

  return (
    !isLGWidth.value &&
    !appStore.isKeyboardShown &&
    !hideOnRoutes.includes(route.name as string) &&
    !hideOnSingleMessenger
  );
});

const isShowAppHeader: ComputedRef<boolean> = computed(() => {
  const hideOnMessenger =
    appStore.isPhone &&
    (route.name === ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN ||
      route.name === ROUTES_NAME.MESSENGER_CHAT_BY_USER);

  const hideOnRoutes = [ROUTES_NAME.OFFICE];

  return !hideOnRoutes.includes(route.name as string) && !hideOnMessenger;
});

const appHeaderHeight: ComputedRef<number> = computed(
  () => appStore.appHeaderHeight
);
const appBottomMenuHeight: ComputedRef<number> = computed(
  () => appStore.appBottomMenuHeight
);
</script>

<style scoped lang="scss">
ion-split-pane {
  --side-width: clamp(280px, 100%, 340px);
  --border: none;
}

.main-pages-content {
  margin-bottom: 0;
}
</style>
