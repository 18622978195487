import { cloneDeep } from 'lodash';

import {
  ActivityTypeEnum,
  AppMenuLanguagesEnum,
  IdeaTypeEnum,
  SendKeyEnum,
  ThemeAppEnum,
} from '@/@enums';
import type {
  MediaModel,
  UserEntity,
  UserHomePageModel,
  UsersIdsModel,
  UsersIdsGroupModel,
  UsersIdsDataModel,
  UsersIdsUserModel,
  UsersIdsChainModel,
  UsersIdsChosenModel,
  UserMessageModel,
  UserSubscribeSettingsModel,
} from '@/@types';

export const defaultUserSubscribeSettings: UserSubscribeSettingsModel = {
  activityType: ActivityTypeEnum.Never,
  subscribeMentionMeInMessage: false,
  subscribeReceiveReplies: false,
  subscribeReceivePrivateMessage: false,
  subscribeInvitedToGroup: false,
  subscribeGetNewFollowers: false,
  subscribeNewMessageInMyFollowers: false,
  subscribeNewMessageInAllSite: false,
  subscribeLikedPostedMessage: false,
  subscribeComplainMessage: false,
  subscribeRequestRegistrationToNetwork: false,
  subscribeAnnouncementsNotification: false,
  groupsNotifications: [],
};

export const defaultUser: UserEntity = {
  aboutMe: '',
  badges: [],
  birthDate: null,
  canUploadVideo: false,
  contacts: undefined,
  createdAt: null,
  defaultFeedType: '',
  defaultIdeaType: IdeaTypeEnum.New,
  disallowToEditFields: [],
  education: '',
  expLevel: 0,
  expPoints: 0,
  externalId: '',
  firstName: '',
  fullName: '',
  fullNameShort: '',
  hobby: '',
  homePage: {
    groupIndex: 0 || null,
    groupType: '',
    isPin: false,
    type: '',
    unitId: 0 || null,
    url: '' || null,
    urlTitle: '' || null,
  } as UserHomePageModel,
  id: 0,
  installedChatApp: false,
  isActive: false,
  isFollowedByCurrentUser: false,
  lastName: '',
  login: '',
  mainAlias: '',
  avatar: {
    url: '' || null,
    width: 0 || null,
    height: 0 || null,
  } as MediaModel,
  cover: {
    url: '' || null,
    width: 0 || null,
    height: 0 || null,
  } as MediaModel,
  position: '',
  region: '',
  roomLocation: '',
  subDivisionTitle: '',
  url: '',
  webUrl: '',
  workExperience: '',
  email: '',
  phoneMobile: '',
  emailOrPhone: '',
  phoneMobileVisible: false,
  phoneWork: '',
  props: [],
  themeStyle: ThemeAppEnum.Light,
  sendMode: SendKeyEnum.Enter,
  pagesBackground: true,
  sound: true,
  language: AppMenuLanguagesEnum.DE,
  contributions: {
    wikiesCount: 0,
    filesCount: 0,
    postsCount: 0,
    commentsCount: 0,
    tasksCount: 0,
  },
  subscribeSettings: cloneDeep(defaultUserSubscribeSettings),
  enableEmailNotifications: false,
  enableMobileNotifications: false,
  enableSiteNotifications: false,
};

export const defaultMessageUser: UserMessageModel = {
  id: 0,
  fullName: '',
  mainAlias: '',
  isActive: true,
  image: {
    url: '' || null,
    width: 0 || null,
    height: 0 || null,
  },
  badges: [],
};

export const defaultUsersIdsChosen: UsersIdsChosenModel = {
  mentions: {
    loadMoreUrl: null,
    ids: [],
  } as UsersIdsDataModel,
  others: {
    loadMoreUrl: null,
    ids: [],
  } as UsersIdsDataModel,
};

export const defaultUsersIds: UsersIdsModel = {
  usersPage: {
    loadMoreUrl: null,
    ids: [],
  },
  list: {
    loadMoreUrl: null,
    ids: [],
  } as UsersIdsDataModel,
  groups: [] as UsersIdsGroupModel[],
  followers: [] as UsersIdsUserModel[],
  chats: [] as UsersIdsChainModel[],
  chosen: {
    mentions: {
      loadMoreUrl: null,
      ids: [],
    } as UsersIdsDataModel,
    others: {
      loadMoreUrl: null,
      ids: [],
    } as UsersIdsDataModel,
  },
  following: [] as UsersIdsUserModel[],
  allForAdmin: {
    loadMoreUrl: null,
    ids: [],
  },
};
