import { cloneDeep } from 'lodash';

import { defaultMessageUser } from './user';

import {
  CustomPageWidgetsEnum,
  EventCalendarSourceEnum,
  WidgetCalendarPeriodEnum,
  WidgetFeedTypeEnum,
} from '@/@enums';
import type {
  PagesIdsDataModel,
  PagesIdsGroupDashboardModel,
  PagesIdsModel,
  WidgetFilesSettingsModel,
  WidgetGroupAdminsSettingsModel,
  WidgetGroupInformationSettingsModel,
  WidgetGroupMembersSettingsModel,
  WidgetInviteSettingsModel,
  CustomPagesDataJsonRowModel,
  CustomPagesWidgetModel,
  PageEntity,
  WidgetTextBlockSettingsModel,
  WidgetPeopleSettingsModel,
  WidgetGroupsSettingsModel,
  WidgetBirthdaySettingsModel,
  WidgetHtmlSettingsModel,
  WidgetFeedSettingsModel,
  WidgetBannerSettingsModel,
  WidgetCalendarSettingsModel,
  WidgetPostSettingsModel,
  WidgetSliderSettingsModel,
  DragModel,
} from '@/@types';

export const defaultRow: CustomPagesDataJsonRowModel = {
  leftSidebarWidgets: [],
  mainWidgets: [],
  rightSidebarWidgets: [],
  rowId: '',
};

export const defaultPage: PageEntity = {
  createdAt: '',
  iconType: 0,
  isPublic: false,
  mainAlias: '',
  owner: defaultMessageUser,
  pageId: 0,
  showTitleInSubHeader: false,
  showType: 0,
  title: '',
  versionId: 0,
  customPageData: { rows: [cloneDeep(defaultRow)] },
  lastEditedAt: '',
  lastEditor: defaultMessageUser,
  canEditPage: false,
  canEditDashboard: false,
};

export const defaultWidgetTextBlock: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetTextBlock,
  settings: { text: '', title: '' } as WidgetTextBlockSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetPeople: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetPeople,
  settings: {
    widgetCompanyResourcesItems: [],
    widgetTitle: '',
  } as WidgetPeopleSettingsModel,
  pageId: 0,
  companyResourcesType: CustomPageWidgetsEnum.WidgetPeople,
  widgetId: '',
  widgetTitle: '',
};

export const defaultWidgetGroups: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetGroups,
  settings: {
    widgetCompanyResourcesItems: [],
    widgetTitle: '',
  } as WidgetGroupsSettingsModel,
  pageId: 0,
  companyResourcesType: CustomPageWidgetsEnum.WidgetGroups,
  widgetId: '',
  widgetTitle: '',
};

export const defaultWidgetBirthdays: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetBirthdays,
  settings: { users: [] } as WidgetBirthdaySettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetPublisher: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetPublisher,
  settings: {} as any,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetHtml: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetHtml,
  settings: { html: '' } as WidgetHtmlSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetFeed: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetFeed,
  settings: {
    feedType: WidgetFeedTypeEnum.AllPublic,
    useItemsCount: '3',
    groupTitle: null,
    tagTitle: null,
    customFeedType: null,
    tagId: null,
    groupId: null,
  } as WidgetFeedSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetBanner: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetBanner,
  settings: {
    imageFilename: '',
    /* tempImageFilename: '', */
    linkUrl: '',
    image: null,
  } as WidgetBannerSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetCalendar: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetCalendar,
  settings: {
    source: EventCalendarSourceEnum.All,
    period: WidgetCalendarPeriodEnum.Month,
    groupId: 0,
    height: null,
    dateStart: null,
    daysCount: 0,
    events: [],
  } as WidgetCalendarSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetSingleUserItem: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetSingleUserItem,
  settings: { userItemID: null } as WidgetPostSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetPoll: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetPoll,
  settings: { userItemID: null } as WidgetPostSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetFilesWikies: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetFilesWikies,
  settings: {
    widgetCompanyResourcesItems: [],
    widgetTitle: '',
  } as WidgetFilesSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetInvite: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetInvite,
  settings: {} as WidgetInviteSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetGroupAdmins: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetGroupAdmins,
  settings: { users: [] } as WidgetGroupAdminsSettingsModel,
  pageId: 0,
  companyResourcesType: CustomPageWidgetsEnum.WidgetGroupAdmins,
  widgetId: '',
  widgetTitle: '',
};

export const defaultWidgetGroupInformation: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetGroupInformation,
  settings: {
    groupInfo: null,
    groupId: 0,
  } as WidgetGroupInformationSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultWidgetGroupMembers: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetGroupMembers,
  settings: { users: [] } as WidgetGroupMembersSettingsModel,
  pageId: 0,
  companyResourcesType: CustomPageWidgetsEnum.WidgetGroupMembers,
  widgetId: '',
  widgetTitle: '',
};

export const defaultWidgetSlider: CustomPagesWidgetModel = {
  systemName: CustomPageWidgetsEnum.WidgetSlider,
  settings: { images: [], slidesPerView: 1 } as WidgetSliderSettingsModel,
  pageId: 0,
  widgetId: '',
};

export const defaultPagesIds: PagesIdsModel = {
  all: { loadMoreUrl: null, ids: [] } as PagesIdsDataModel,
  autocomplete: { loadMoreUrl: null, ids: [] } as PagesIdsDataModel,
  groupDashboard: [] as PagesIdsGroupDashboardModel[],
};

export const defaultDragModel: DragModel = {
  dashboard: { isDashboard: false, id: null },
  pointerY: 0,
  isDragged: false,
};
