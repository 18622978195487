import type { ResponseAuthUserTokenModel, ResponseErrorModel } from '@/@types';
import { useAppStore, useNetworkStore } from '@/store';

export type IToken = {
  isCodeExpired: () => boolean;
  getNewToken: (
    force: boolean
  ) => Promise<{ url: string; token: string | null; locale: string }>;
};

export function useTokenHelper(): IToken {
  const appStore = useAppStore();
  const networkStore = useNetworkStore();

  function _isTokenExpired(): boolean {
    return (
      Date.parse(appStore.validUntil) < Date.now() || !appStore.accessToken
    );
  }

  /**
   *
   * @returns boolean
   * @description Check if the code and token are expired
   * @private
   * @note not used
  function _isCodeAndTokenExpired(): boolean {
    const now = Date.now();
    return (
      Date.parse(appStore.validUntil) < now ||
      Date.parse(appStore.codeValid) < now
    );
  }
   */

  function isCodeExpired(): boolean {
    return Date.parse(appStore.codeValid) < Date.now() || !appStore.code;
  }

  async function getNewToken(
    force: boolean
  ): Promise<{ url: string; token: string | null; locale: string }> {
    if (isCodeExpired()) {
      return {
        url: appStore.url,
        token: null,
        locale: appStore.locale,
      };
    }

    if (force || _isTokenExpired()) {
      try {
        appStore.$patch({
          loading: true,
        });

        const response = await fetch(
          `${appStore.url}${import.meta.env.VITE_APP_URL_API}/oauth/token`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              grantType: appStore.grantType,
              clientSecret: appStore.clientSecret,
              code: appStore.code,
              uid: appStore.homeRowId,
            }),
          }
        );

        if (
          !response.ok &&
          (response.type === 'cors' || response.status === 401)
        ) {
          const error = (await response.json()) as ResponseErrorModel;
          appStore.$patch({
            errors: error.errorMessages,
          });
          throw error;
        }

        const responseToken = await response.json();

        if (responseToken.statusCode !== 200) {
          const error = responseToken as ResponseErrorModel;
          appStore.$patch({
            errors: error.errorMessages,
          });
          throw error;
        }

        const model = responseToken as ResponseAuthUserTokenModel;
        const { validUntil, accessToken, companyRowId } = model.data;
        appStore.$patch({
          validUntil,
          accessToken,
          companyRowId,
        });

        return {
          url: appStore.url,
          token: appStore.accessToken,
          locale: appStore.locale,
        };
      } catch (error) {
        console.error('Error while fetching token:', error);
        return { url: '', token: null, locale: '' };
      } finally {
        appStore.$patch({
          loading: false,
        });
        networkStore.$patch({
          loading: false,
        });
      }
    }

    return {
      url: appStore.url,
      token: appStore.accessToken,
      locale: appStore.locale,
    };
  }

  return {
    isCodeExpired,
    getNewToken,
  };
}
