<template>
  <div
    class="conversations"
    :class="{
      'feed-page': conversationsFlag === ConversationsFlagEnum.FeedPage,
      'conversation-page':
        conversationsFlag === ConversationsFlagEnum.ConversationPage,
      'ideas-page': conversationsFlag === ConversationsFlagEnum.IdeasPage,
    }"
  >
    <not-found-data
      v-if="
        posts.length === 0 &&
        !postsIsLoading &&
        !wikiId &&
        !announcementsIsLoading &&
        feedType !== FeedTypeEnum.Announcement
      "
      :text="
        conversationsFlag === ConversationsFlagEnum.CustomPage
          ? $t('customPage.noData')
          : $t('noData')
      "
    />

    <div
      v-if="
        conversationsFlag === ConversationsFlagEnum.FeedPage &&
        feedType === FeedTypeEnum.Announcement
      "
    >
      <swiper
        :mousewheel="false"
        :navigation="true"
        :modules="modules"
        :auto-height="true"
        :pagination="true"
        class="custom-swiper"
      >
        <template v-for="item in posts" :key="`announcement_${item.id}`">
          <swiper-slide @click.stop="openPost(item)">
            <conversations-item
              class="swiper-block"
              :post="item"
              :conversations-flag="conversationsFlag"
              :feed-type="feedType"
              @onCommentFocus="commentFocus"
            />
          </swiper-slide>
        </template>
        <!-- <swiper-slide v-if="isHasMoreItems">
          <announcements-load-more />
        </swiper-slide> -->
      </swiper>
    </div>
    <template
      v-if="
        postsIsLoading &&
        posts.length === 0 &&
        feedType !== FeedTypeEnum.Announcement
      "
    >
      <conversations-loader
        v-for="item in conversationsFlag === ConversationsFlagEnum.FeedPage
          ? 5
          : 1"
        :key="item"
    /></template>

    <template v-if="feedType !== FeedTypeEnum.Announcement">
      <template v-for="item in posts" :key="`post_${item.id}`">
        <ion-item-sliding
          v-if="
            showPost(item) &&
            isNativeMobile &&
            conversationsFlag === ConversationsFlagEnum.FeedPage
          "
          ref="slidingItemRef"
          class="conversations-sliding"
          :class="{
            'with-shadow':
              item.messageType === ConversationsTypeEnum.Announcement,
          }"
        >
          <ion-item-options
            side="start"
            @ionSwipe="onBookmark(item.id, item.isBookmarked)"
          >
            <ion-item-option
              class="conversations-sliding-option"
              expandable
              @click.stop="onBookmark(item.id, item.isBookmarked)"
            >
              <ion-icon
                slot="icon-only"
                color="medium"
                :icon="item.isBookmarked ? icons.closeCircle : icons.bookmark"
              />
            </ion-item-option>
          </ion-item-options>
          <ion-item lines="none" class="sliding-item">
            <conversations-item
              :post="item"
              :conversations-flag="conversationsFlag"
              @onCommentFocus="commentFocus"
            />
          </ion-item>
          <ion-item-options side="end" @ionSwipe="onPostHide(item.id)">
            <ion-item-option
              class="conversations-sliding-option"
              expandable
              @click.stop="onPostHide(item.id)"
            >
              <ion-icon slot="icon-only" color="medium" :icon="icons.eyeOff" />
            </ion-item-option>
          </ion-item-options>
        </ion-item-sliding>

        <div v-else-if="showPost(item)" class="conversations-item">
          <conversations-item
            :post="item"
            :conversations-flag="conversationsFlag"
            @onCommentFocus="commentFocus"
          />
        </div>
      </template>
      <app-infinite-scroll
        v-if="
          loadMoreUrl &&
          conversationsFlag !== ConversationsFlagEnum.CustomPage &&
          conversationsFlag !== ConversationsFlagEnum.ConversationPage
        "
        :load-more-url="loadMoreUrl"
        :is-loading="loadMoreIsLoading"
        @on-load-more="onLoadMore"
      />
      <div
        v-else-if="customLoadMoreUrl !== null && posts.length > 0"
        class="load-more-btn"
      >
        <ion-button
          :disabled="customLoadMoreIsLoading"
          shape="round"
          fill="outline"
          mode="md"
          @click="customLoadMore()"
        >
          <icons-provider
            v-if="customLoadMoreIsLoading"
            slot="icon-only"
            :icon-props="{
              width: '16',
              height: '16',
              fill: 'var(--ion-color-intra)',
            }"
            name="dotsAnim"
          />
          <ion-icon
            v-if="!customLoadMoreIsLoading"
            slot="icon-only"
            :icon="icons.ellipsisHorizontal"
          />
        </ion-button>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from '@ionic/vue';
import {
  bookmarkOutline,
  chevronForwardOutline,
  closeCircleOutline,
  ellipsisHorizontal,
  ellipsisVerticalOutline,
  eyeOffOutline,
} from 'ionicons/icons';
import { Mousewheel, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { ComponentPublicInstance, ComputedRef, PropType } from 'vue';
import { computed, ref, watch } from 'vue';
import 'swiper/css';
import { useRouter } from 'vue-router';

import type { PostsFilterEnum } from '@/@enums';
import {
  ConversationsFlagEnum,
  ConversationsTypeEnum,
  FeedTypeEnum,
  IdeaTypeEnum,
  PostsModeEnum,
} from '@/@enums';
import type { PostEntity } from '@/@types';
import {
  ConversationsItem,
  ConversationsLoader,
  NotFoundData,
  AppInfiniteScroll,
  IconsProvider,
} from '@/components';
import { isNativeMobile, showToast } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { usePostStore, useUserStore, useAppStore } from '@/store';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Props
const props = defineProps({
  userId: {
    type: Number,
    default: 0,
  },
  conversationsFlag: {
    type: String as PropType<ConversationsFlagEnum>,
    required: true,
  },
  groupId: {
    type: Number,
    default: 0,
  },
  feedType: {
    type: String as PropType<FeedTypeEnum | null>,
    default: FeedTypeEnum.Recommended,
  },
  ideaType: {
    type: String as PropType<IdeaTypeEnum | null>,
    default: IdeaTypeEnum.New,
  },
  postId: {
    type: Number || null,
    default: () => null,
  },
  tagId: {
    type: Number || null,
    default: () => null,
  },
  widgetPostsCount: {
    type: Number,
    default: () => 0,
  },
  wikiId: {
    type: Number || null,
    default: () => null,
  },
  fileId: {
    type: Number || null,
    default: () => null,
  },
});

// Icons
const icons = {
  chevron: chevronForwardOutline,
  ellipsis: ellipsisVerticalOutline,
  bookmark: bookmarkOutline,
  eyeOff: eyeOffOutline,
  closeCircle: closeCircleOutline,
  ellipsisHorizontal: ellipsisHorizontal,
};

// Other
const { t } = useI18n();
const modules = [Mousewheel, Pagination, Navigation];

// Router
const router = useRouter();

// Store
const postStore = usePostStore();
const userStore = useUserStore();
const appStore = useAppStore();

// Refs
const loadMoreIsLoading = ref<boolean>(false);
const widgetFeedPosts = ref<PostEntity[]>([]);
const customLoadMoreUrl = ref<string | null>(null);
const currentPostsCount = ref<number>(props.widgetPostsCount);
const customLoadMoreIsLoading = ref<boolean>(false);
const slidingItemRef = ref<ComponentPublicInstance[] | null>(null);

// Computed
const userId: ComputedRef<number> = computed(() => props.userId);
const groupId: ComputedRef<number> = computed(() => props.groupId);
/* TODO
const isHasMoreItems: ComputedRef<boolean> = computed(
  () => postStore.hasMoreItems
);
*/
const postsIsLoading: ComputedRef<boolean> = computed(() => {
  return postStore.loading;
});
const announcementsIsLoading: ComputedRef<boolean> = computed(() => {
  return postStore.loading;
});
const selectedFeedFilter: ComputedRef<PostsFilterEnum> = computed(
  () => appStore.feedFilter
);
const feedType: ComputedRef<FeedTypeEnum | null> = computed(
  () => props.feedType
);
const ideaType: ComputedRef<IdeaTypeEnum | null> = computed(
  () => props.ideaType
);
const currentUserId: ComputedRef<number> = computed(
  () => userStore.current?.id ?? 0
);
const loadMoreUrl: ComputedRef<string | null> = computed(
  () => postStore.getLoadMoreUrl().url
);
const isHasLoadedPosts: ComputedRef<boolean> = computed(
  () =>
    props.widgetPostsCount > 0 &&
    posts.value.length < widgetFeedPosts.value.length
);

const posts: ComputedRef<PostEntity[]> = computed(() => {
  let result = [] as any;
  if (feedType.value === FeedTypeEnum.Announcement) {
    result = postStore.getAnnouncements ?? [];
  } else if (
    props.conversationsFlag === ConversationsFlagEnum.ConversationPage ||
    props.conversationsFlag === ConversationsFlagEnum.CustomPage
  ) {
    if (props.postId === null) {
      switch (props.feedType) {
        case FeedTypeEnum.Recommended:
          {
            const result = postStore.getPostsRecommended();
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.AllPublic:
          {
            const result = postStore.getPostsAll();
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.Following:
          {
            const result = postStore.getPostsFollowing();
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.MyBookmarks:
          {
            const result = postStore.getPostsBookmarks();
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.Polls:
          {
            const result = postStore.getPostsPolls();
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.Ideas:
          {
            const result = postStore.getNewIdeas();
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.AllMy:
          {
            const result = postStore.getPostsFromUserId(currentUserId.value);
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.ByGroup:
          {
            const result = postStore.getPostsFromGroupId(props.groupId);
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.ByTag:
          {
            const result = postStore.getPostsFromTagId(props.tagId);
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.ByWiki:
          {
            const result = postStore.getPostsFromWikiId(props.wikiId);
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.ByFile:
          {
            const result = postStore.getPostsFromFileId(props.fileId);
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;

        case FeedTypeEnum.Planned:
          {
            const result = postStore.getPostsPlannedAll();
            updateWidgetFeedPosts(result.data, result.loadMoreUrl);
          }
          break;
      }

      if (props.widgetPostsCount > 0) {
        result = widgetFeedPosts.value.slice(0, currentPostsCount.value);
      } else {
        result = widgetFeedPosts.value;
      }
    } else {
      const post = postStore.getPostById(props.postId);
      if (post.id !== 0) result.push(post);
    }
  } else {
    result = postStore.getPosts ?? [];
  }
  return result;
});

// Actions
const showPost = (post: PostEntity) => {
  // Прочитано ли; Другие типы(не объявление) прочитано по умолчанию
  if (post.messageType !== ConversationsTypeEnum.Announcement) {
    return true;
  } else
    return !(
      (!post.isAnnouncementRead &&
        props.conversationsFlag === ConversationsFlagEnum.FeedPage) ||
      props.conversationsFlag === ConversationsFlagEnum.IdeasPage
    );
};

const updateWidgetFeedPosts = (data: PostEntity[], url: string | null) => {
  widgetFeedPosts.value = data;
  customLoadMoreUrl.value = url;
};

const getPosts = async () => {
  switch (props.conversationsFlag) {
    case ConversationsFlagEnum.UserProfile:
      if (userId.value !== 0) {
        postStore.$patch({
          postsMode: PostsModeEnum.ByUser,
          postsGroupId: null,
          postsUserId: userId.value,
        });
        await postStore.postsFromUserId(userId.value);
      }
      break;

    case ConversationsFlagEnum.GroupPage:
      if (groupId.value !== 0) {
        postStore.$patch({
          postsMode: PostsModeEnum.ByGroup,
          postsGroupId: groupId.value,
          postsUserId: null,
        });
        await postStore.postsFromGroupId(
          groupId.value,
          selectedFeedFilter.value
        );
      }
      break;

    case ConversationsFlagEnum.ConversationPage:
    case ConversationsFlagEnum.CustomPage:
      if (props.postId !== null) {
        postStore.$patch({
          postsMode: null,
          postsGroupId: null,
          postsUserId: null,
        });
        //Bitoobit. Check do we have loaded posts.
        //Commented out the Bitoobit changes
        //if (!posts.value?.find((i) => i?.id === props.postId))
        await postStore.postFromId(props.postId);
      } else if (props.postId === null) {
        // Лента на кастомной странице
        switch (props.feedType) {
          case FeedTypeEnum.Recommended:
            {
              await postStore.postsRecommended();
            }
            break;

          case FeedTypeEnum.AllPublic:
            {
              await postStore.postsAll(selectedFeedFilter.value);
            }
            break;

          case FeedTypeEnum.Following:
            {
              await postStore.postsFollowing(selectedFeedFilter.value);
            }
            break;

          case FeedTypeEnum.MyBookmarks:
            {
              await postStore.postsBookmarks();
            }
            break;

          case FeedTypeEnum.Polls:
            {
              await postStore.postsPolls();
            }
            break;

          case FeedTypeEnum.Ideas:
            {
              await postStore.postsNewIdeas();
            }
            break;

          case FeedTypeEnum.AllMy:
            {
              await postStore.postsFromUserId(currentUserId.value);
            }
            break;

          case FeedTypeEnum.ByGroup:
            {
              await postStore.postsFromGroupId(props.groupId);
            }
            break;

          case FeedTypeEnum.ByTag:
            {
              await postStore.postsFromTagId(props.tagId);
            }
            break;

          case FeedTypeEnum.ByWiki:
            {
              await postStore.postsFromWikiId(props.wikiId);
            }
            break;
          case FeedTypeEnum.ByFile:
            {
              await postStore.postsFromFileId(props.fileId);
            }
            break;
          case FeedTypeEnum.Planned:
            {
              await postStore.postsPlannedAll();
            }
            break;
        }
      }
      break;
  }
};

const onLoadMore = async () => {
  loadMoreIsLoading.value = true;
  await postStore.loadMore();
  loadMoreIsLoading.value = false;
};

const addPostsCount = () => {
  currentPostsCount.value = currentPostsCount.value + props.widgetPostsCount;
};

const customLoadMore = async () => {
  if (customLoadMoreUrl.value !== null) {
    if (isHasLoadedPosts.value) {
      addPostsCount();
      return;
    }

    customLoadMoreIsLoading.value = true;

    const nextUrl = await postStore.customLoadMore(
      customLoadMoreUrl.value,
      props.feedType,
      props.groupId,
      props.tagId,
      props.wikiId,
      props.userId
    );
    customLoadMoreUrl.value = nextUrl;
    if (isHasLoadedPosts.value) {
      addPostsCount();
    }

    customLoadMoreIsLoading.value = false;
  }
};

const addToBookmarks = async (id: number) => {
  if (await postStore.addBookmark(id)) {
    await showToast(
      t('conversations.conversationPostMenu.bookmark.bookmarkAdded'),
      true
    );
  } else {
    await showToast(
      t('conversations.conversationPostMenu.bookmark.bookmarkNotAdd'),
      false
    );
  }
};

const removeFromBookmarks = async (id: number) => {
  if (await postStore.removeBookmark(id)) {
    await showToast(
      t('conversations.conversationPostMenu.bookmark.bookmarkRemoved'),
      true
    );
  } else {
    await showToast(
      t('conversations.conversationPostMenu.bookmark.bookmarkNotRemoved'),
      false
    );
  }
};

const onBookmark = async (id: number, isBookmarked: boolean) => {
  if (isBookmarked) {
    await removeFromBookmarks(id);
  } else {
    await addToBookmarks(id);
  }
  if (slidingItemRef.value !== null) {
    await slidingItemRef.value[0].$el.closeOpened();
  }
};

const onPostHide = async (id: number) => {
  if (await postStore.postHide(id)) {
    await showToast(
      t('conversations.conversationPostMenu.hide.postHidden'),
      true
    );
  } else {
    await showToast(
      t('conversations.conversationPostMenu.hide.postNotHidden'),
      false
    );
  }
  if (slidingItemRef.value !== null) {
    await slidingItemRef.value[0].$el.closeOpened();
  }
};

const openPost = async (post: PostEntity) => {
  await router.push({
    name: ROUTES_NAME.POST_BY_ID,
    params: { id: post.id },
  });
  if (
    post.messageType === ConversationsTypeEnum.Announcement &&
    !post.isAnnouncementRead
  ) {
    await postStore.announcementRead(post.id);
  }
};

// Watchers
watch(feedType, () => {
  if (props.conversationsFlag !== ConversationsFlagEnum.CustomPage) {
    getPosts();
  }
});

watch(ideaType, () => {
  if (props.conversationsFlag !== ConversationsFlagEnum.CustomPage) {
    getPosts();
  }
});

watch(selectedFeedFilter, () => {
  getPosts();
});

// Lifecycle
getPosts();

// Emits
const emit = defineEmits(['onCommentFocus']);
const commentFocus = (focus: boolean) => {
  emit('onCommentFocus', focus);
};
</script>

<style scoped lang="scss">
.conversations {
  margin-top: app-padding(lg);
  position: relative;
}
.with-shadow {
  -webkit-box-shadow: 0 0 8px 3px rgba(var(--ion-color-intra-rgb), 0.1);
  -moz-box-shadow: 0 0 8px 3px rgba(var(--ion-color-intra-rgb), 0.1);
  box-shadow: 0 0 8px 3px rgba(var(--ion-color-intra-rgb), 0.1);
}
.conversations.feed-page,
.conversations.conversation-page,
.conversations.ideas-page {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}
.conversations .conversations-sliding {
  margin-bottom: app-padding(lg);
  border-radius: app-radius(md);
}
.conversations .conversations-item {
  margin-bottom: app-padding(lg);
}

ion-item-sliding {
  overflow: visible;
}

.conversations ion-item.sliding-item {
  width: 100%;
  position: relative;
  --padding-end: 0;
  --padding-start: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  --background: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  overflow: visible;
}
.conversations .conversations-sliding-option {
  --background: var(--ion-color-custom-element-lighter);
}
.swiper {
  border-radius: 0 0 app-radius(md) app-radius(md);
}
.swiper-slide {
  padding: 0;
  text-align: left;
  font-size: inherit;
  border-radius: app-radius(md);
  background-color: var(--ion-color-light-background-contrast);
}
.swiper .swiper-block {
  margin-bottom: 0;
}
.load-more {
  height: 340px;
}
.load-more-btn {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.load-more-btn ion-button {
  --box-shadow: none;
  --border-color: var(--ion-color-light-background);
  --border-color: var(--ion-color-intra);
  --border-style: solid;
  --border-width: 1px;
  margin-inline: 0;
  white-space: normal;
  color: var(--ion-color-intra);
}
.load-more-btn ion-icon {
  color: var(--ion-color-intra);
  font-size: 2rem;
}
@include respond-to-min-width(xl) {
  .conversations {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
