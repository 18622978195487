<template>
  <div v-if="sharedMessagesData.data" class="shared-message">
    <conversations-item
      :post="sharedMessagesData.data[0]"
      :parent-post-id="parentPostId"
      :conversations-flag="ConversationsFlagEnum.SharedPost"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { ConversationsFlagEnum } from '@/@enums';
import type { PostSharedMessagesModel } from '@/@types';
import { ConversationsItem } from '@/components';

const props = defineProps({
  parentPostId: {
    type: Number,
    required: true,
  },
  sharedMessagesData: {
    type: Object as PropType<PostSharedMessagesModel>,
    required: true,
  },
  conversationsFlag: {
    type: String as PropType<ConversationsFlagEnum>,
    required: true,
  },
});

const sharedMessagesData: ComputedRef<PostSharedMessagesModel> = computed(
  () => props.sharedMessagesData
);
</script>
<style scoped lang="scss">
.shared-message {
  position: relative;
  margin-top: 1rem;
  border-radius: 4px;
  border: 1px solid var(--ion-color-light-custom);
}
</style>
