import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';

import { CallStartStatusEnum, MeetStatusEnum } from '@/@enums';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  ResponseTokenModel,
  MeetChainInfo,
  UserViewModel,
  UserMeetModel,
  ResponseCallToUserModel,
  ResponseParticipantsModel,
  UserShortModel,
  MessageChainEntity,
  ResponseAnswerModel,
} from '@/@types';
import { $api } from '@/services';

type MeetRoom = {
  callToken: string;
  withVideo: boolean;
  isRoomActive: boolean;
  chain: MeetChainInfo | null;
  isGroupCall: boolean;
  callUserId: number | null;
  callIsActive: boolean;
  isAudioAllowed: boolean; // myAudioAllowed
  isVideoAllowed: boolean; // myVideoAllowed
};

interface MeetState {
  errors: ErrorMessageModel[];
  rooms: Record<string, MeetRoom>;
  currentRoomId: string;
  connectionId: string | null;
  withVideo: boolean;
  isCallFromUserPage: boolean;
}

export const useMeetStore = defineStore({
  id: 'meet',
  state: (): MeetState => ({
    errors: [],
    rooms: {},
    currentRoomId: '',
    connectionId: '',
    withVideo: false,
    isCallFromUserPage: false,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getChain:
      (state) =>
      (roomId?: string): MeetChainInfo | null =>
        roomId
          ? state.rooms?.[roomId]?.chain || null
          : state.rooms?.[state.currentRoomId]?.chain || null,
    getCurrentRoomId: (state): string => state.currentRoomId,
    callToken:
      (state) =>
      (roomId?: string): string =>
        roomId
          ? state.rooms?.[roomId]?.callToken
          : state.rooms?.[state.currentRoomId]?.callToken,
    isGroupCall:
      (state) =>
      (roomId?: string): boolean =>
        roomId
          ? state.rooms?.[roomId]?.isGroupCall
          : state.rooms?.[state.currentRoomId]?.isGroupCall,
    isRoomActive:
      (state) =>
      (roomId?: string): boolean =>
        roomId
          ? state.rooms?.[roomId]?.isRoomActive
          : state.rooms?.[state.currentRoomId]?.isRoomActive,
    isAudioAllowed:
      (state) =>
      (roomId?: string): boolean =>
        roomId
          ? state.rooms?.[roomId]?.isAudioAllowed
          : state.rooms?.[state.currentRoomId]?.isAudioAllowed,
    isVideoAllowed:
      (state) =>
      (roomId?: string): boolean =>
        roomId
          ? state.rooms?.[roomId]?.isVideoAllowed
          : state.rooms?.[state.currentRoomId]?.isVideoAllowed,
    devicesNotAllowed:
      (state) =>
      (roomId: string): boolean =>
        !state.rooms?.[roomId]?.isAudioAllowed &&
        !state.rooms?.[roomId]?.isVideoAllowed,
    getUser:
      (state) =>
      (userId: number | null, roomId?: string): UserMeetModel | undefined => {
        const currentRoomId = roomId || state.currentRoomId;
        if (state.rooms[currentRoomId]) {
          const users = state.rooms[currentRoomId].chain?.users ?? [];
          const index = users.findIndex((n: UserMeetModel) => n.id === userId);
          return index >= 0 ? users[index] : undefined;
        }
        return undefined;
      },
    getRoomIdByChainId:
      (state) =>
      (chainId: number): string | undefined => {
        for (const roomId of Object.keys(state.rooms)) {
          if (state.rooms[roomId].chain?.chainId === chainId) {
            return roomId;
          }
        }
        return undefined;
      },
    getCallInfo:
      (state) =>
      (roomId?: string): { title: string; image: string } | undefined => {
        const currentRoomId = roomId || state.currentRoomId;
        if (
          !state.rooms?.[currentRoomId]?.chain ||
          !state.rooms?.[currentRoomId]?.callUserId
        ) {
          return undefined;
        }

        if (state.rooms?.[currentRoomId]?.chain?.isGroupChain) {
          return {
            title: state.rooms?.[currentRoomId]?.chain?.title || '',
            image: state.rooms?.[currentRoomId]?.chain?.chainAvatar?.url || '',
          };
        } else {
          const users = state.rooms?.[currentRoomId]?.chain?.users ?? [];
          const index = users.findIndex(
            (n: UserViewModel) =>
              n.id === state.rooms?.[currentRoomId]?.callUserId
          );
          return index >= 0
            ? {
                title: users[index].fullName,
                image: users[index].image?.url || '',
              }
            : undefined;
        }
      },
  },
  actions: {
    initRoom(roomId: string) {
      this.rooms[roomId] = {
        callToken: '',
        withVideo: false,
        isRoomActive: false,
        isAudioAllowed: false,
        isVideoAllowed: false,
        chain: null,
        callUserId: null,
        isGroupCall: false,
        callIsActive: false,
      };
    },
    deleteRoom(roomId: string) {
      if (this.rooms[roomId]) {
        const newRooms = Object.keys(this.rooms)
          .filter((key) => key !== roomId)
          .reduce(
            (newRooms, key) => {
              newRooms[key] = this.rooms[key];
              return newRooms;
            },
            {} as { [key: string]: MeetRoom }
          );
        this.rooms = newRooms;
      }
    },
    updateRoom(roomId: string, updates: Partial<MeetRoom>) {
      if (this.rooms[roomId]) {
        this.$patch({
          rooms: {
            [roomId]: { ...this.rooms[roomId], ...updates },
          },
        });
      }
    },
    setChain(chain: MessageChainEntity | null, roomId: string) {
      if (!chain) {
        return;
      }
      const { users, ...rest } = chain;
      this.rooms[roomId].chain = {
        users: users.map((user) => {
          const newUser = cloneDeep(user) as unknown as UserMeetModel;
          newUser.isVideoEnabled = false;
          newUser.isVoiceEnabled = false;
          return newUser;
        }),
        ...rest,
      };
    },
    async getActiveParticipants(chainId: number): Promise<UserShortModel[]> {
      const response = await $api.meet.getActiveParticipants(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseParticipantsModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
    async callUser(
      chainId: number,
      toUserId: number | null,
      withVideo: boolean,
      isGroupCall: boolean
    ): Promise<string | undefined> {
      if (!chainId) {
        this.$patch({
          isCallFromUserPage: false,
          withVideo: false,
        });
        return undefined;
      }

      const response = await $api.meet.callUser({
        chainId,
      });

      if (response.statusCode === 200) {
        const model = response as ResponseCallToUserModel;
        if (model.data.status === CallStartStatusEnum.Connect) {
          await this.reject(chainId, model.data.roomName);
          return undefined;
        }
        if (this.getCurrentRoomId === '') {
          this.$patch({
            currentRoomId: model.data.roomName,
          });
        }
        this.initRoom(model.data.roomName);
        this.rooms[model.data.roomName] = Object.assign(
          this.rooms[model.data.roomName],
          {
            isRoomActive: false,
            withVideo,
            isGroupCall: isGroupCall,
            callUserId: toUserId,
            callToken: model.data.participantJwtToken,
          }
        );
        return model.data.roomName;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    async answerUser(
      chainId: number | null,
      roomId: string,
      result: MeetStatusEnum
    ): Promise<boolean | undefined> {
      if (!chainId) {
        return;
      }
      try {
        const response = await $api.meet.answerUser({
          chainId,
          result,
        });

        if (response.statusCode === 200) {
          const model = response as ResponseAnswerModel;
          this.rooms[roomId].callToken = model.data.participantJwtToken;
          return result === MeetStatusEnum.Accept;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
      } catch (e) {
        return undefined;
      }
    },
    async reject(chainId: number | null, roomId: string): Promise<void> {
      if (!chainId) {
        return;
      }
      const response = await $api.meet.reject(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseTokenModel;
        if (this.rooms[roomId]) {
          this.rooms[roomId].callToken = model.data;
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    userVoiceMute(userId: number, isEnabled: boolean, roomId?: string) {
      const incomingRoomId = roomId || this.currentRoomId;
      const users = this.rooms[incomingRoomId].chain?.users ?? [];
      const index = users.findIndex((n: UserMeetModel) => n.id === userId);

      if (index >= 0) {
        users[index].isVoiceEnabled = isEnabled;
      }
    },
    userVideoMute(userId: number, isEnabled: boolean, roomId?: string) {
      const incomingRoomId = roomId || this.currentRoomId;
      const users = this.rooms[incomingRoomId].chain?.users ?? [];
      const index = users.findIndex((n: UserMeetModel) => n.id === userId);

      if (index >= 0) {
        users[index].isVideoEnabled = isEnabled;
      }
    },
    addError(key: string, error: string) {
      const index = this.errors.findIndex((n) => n.key === key);
      if (index >= 0) {
        this.errors[index].errors = [error];
      } else {
        this.errors.push({
          key: key,
          errors: [error],
        });
      }
    },
    clearErrors() {
      this.errors = [];
    },
  },
  persist: true,
});
